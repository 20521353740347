* {
    box-sizing: border-box;
  }
  
  body {
    background-color: #f1f1f1;
    padding: 20px;
    font-family: Arial;
  }
  
  /* Center website */
  .main {
    max-width: 1000px;
    margin: auto;
  }
  
  h1 {
    font-size: 50px;
    word-break: break-all;
  }
  
  .row {
    margin: 8px -16px;
  }
  
  /* Add padding BETWEEN each column (if you want) */
  .row,
  .row > .column {
    padding: 8px;
  }
  
  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    width: 33.33%;
    display: none; /* Hide columns by default */
  }
  
  /* Clear floats after rows */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Content */
  .content {
    background-color: white;
    padding: 10px;
  }
  
  /* The "show" class is added to the filtered elements */
  .show {
    display: block;
  }
  
  /* Style the buttons */
  .btn {
    border: none;
    outline: none;
    padding: 12px 16px;
    background-color: white;
    cursor: pointer;
  }
  
  /* Add a grey background color on mouse-over */
  .btn:hover {
    background-color: #ddd;
  }
  
  /* Add a dark background color to the active button */
  .btn.active {
    background-color: #666;
     color: white;
  }