
/* CSS Document */

/**
* Importing necessary  Styles.
**/
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,900,700|Playfair+Display:700');
@import url('./assets/fonts/circular-std/css/circular-std.css');
/*----bootstrap css ----- */
/*------- menu ------*/
@import url('./assets/vendor/mega-menu/assets/css/menu.css');
/*----font awesome -------*/
@import url('./assets/fonts/font-awesome/css/font-awesome.min.css');
/*----owl-carousel css----*/
/* @import url('./assets/vendor/owl-carousel/owl.carousel.css'); */
@import url('./assets/vendor/owl-carousel/owl.theme.css');
@import url('./assets/vendor/owl-carousel/animate.css');
/*-------- AOS css ------*/
@import url('./assets/vendor/aos-next/dist/aos.css');
/*----------- Fancybox css -------*/
@import url('./assets/vendor/fancybox/dist/jquery.fancybox.min.css');
/*------------- Map -------------*/
@import url('./assets/vendor/sanzzy-map/dist/snazzy-info-window.min.css');
/*----Flaticon-------*/
@import url('./assets/fonts/icon/font/flaticon.css');
/*---------------- Custom Animation -------------*/
@import url('/assets/css/customAnimation.css');
/*---------------- Animated Headline -------------*/
@import url('./assets/vendor/animated-headline-master/style.css');
/*---------------- Modal -------------*/
@import url('./assets/vendor/iziModal-master/iziModal.css');
/*--------- Jquery ui ---------*/
/* @import url('./assets/vendor/jquery-ui/jquery-ui.min.css'); */
/*--------- Select js ---------*/
@import url('./assets/vendor/selectize.js/selectize.css');
/*--------- Tabs ---------*/
@import url('./assets/vendor/tabs/tabs.css');


/*custom Style */

.ourClient.our-feature-app .feature-offer-box.support-feature, .ourClient.our-feature-app .feature-offer-box.price-feature {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important;
}

.ourClient.our-feature-app.p0.mt-200 {
  margin: 40px 0;
}

.MapVew {
  height: 550px;
  overflow: hidden;
}

.MapVew div .mapView {
  height: 500px !important;
}

.portFolioSection {
  display: inline-block;
  margin: 160px 0 0 0;
}

#myBtnContainer {
  margin: 0 auto;
}

.btn.active {
  background-color: #357694 !important;
}

.btn:hover {
  background-color: #357694 !important;
  color: #fff !important;
}

a{
  cursor: pointer;
}

#navbarSupportedContent .navbar-nav li:last-child {
  width: 250px;
}

.navbar-light .navbar-nav .nav-link
{
  font-size: 20px;
  padding: 0 15px;
}

.navbar-nav .nav-item .nav-link
{
  padding: 0;
  margin: 0;
}

#theme-banner-one .bannerHaderImg {
  width: 67%;
  float: right;
}

.WhatWedo .contact-us.white-shdw-button {
  margin: 30px 0 0 0;
}

.productsbox.single-block ul {
  margin: 0 0 0 14px;
}

.productsbox.single-block ul li{
  list-style: circle;
}

.static-map {
  width: 100%;
  height: 420px;
}

.marker-map {
  height: 20px;
  width: 20px;
}

.aboutP {
  margin: 0 0 12px 0;
  line-height: 30px;
}

.Footerlogo {
  width: 150px;
}

.agn-what-we-do .single-block
{
  padding: 0;
}

.productsbox.single-block {
  transition: all 0.1s;
  padding: 15px 10px 10px 15px !important;
  height: 350px;
  background: #fff;
  transition: all 0.4s ease-in-out;
}

.productsbox.single-block:hover {
  box-shadow: 0px 0px 5px 
  rgba(0,0,0,.20);
}

.leadership.agn-about-us
{
background: #33617d; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #33617d 0%, #161615 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #33617d 0%,#161615 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #33617d 0%,#161615 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33617d', endColorstr='#161615',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
padding: 50px 0;
}

.leadership.agn-about-us .theme-title-one {
  padding-bottom: 10px;
  
}

.leadership.agn-about-us .theme-title-one .upper-title
{
  color: #fff;
}

.leadership.agn-about-us p{
  color: #fff;;
}

.leadership.agn-about-us  .theme-title-one .main-title
{
  color: #fff;;
}

.footer-information .officelocations p {
  padding: 0 !important;
}

.footer-information .officelocations p .mappin {
  width: 20px;
  margin: 5px 5px 0 0px;
  float: left;
}

.footer-information .officelocations p span
{
float: left;
width: 90%;
}

.FooterIcons {
  display: inline-block;
  margin: 20px 0 0 0;
}



.navbar .dropdown {
  position: relative !important;
}

.navbar .dropdown::after {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  right: 0;
}

.dropdown.nav-item:hover .dropdown-menu.show {
  display: block !important
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
border-top: .3em solid;
border-right: .3em solid transparent;
border-bottom: 0;
border-left: .3em solid transparent;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #357694;
}

.navbar .dropdown-menu .dropdown-item:hover {
  color: #357694;
}

.servicesNav .dropdown-menu
{
min-width: 370px !important;
}


.our-feature-app .feature-img-box
{
  padding-right: 0px !important;
}

.our-feature-app .feature-img-box .feature-offer-box
{
  min-height: 270px;
}

.dropdown-item.active, .dropdown-item:active
{
  background: #fff !important;
}

.careerAbout
{
font-size: 20px;
line-height: 35px;
margin: 0 0 20px 0;
}

.contactUs {
  margin: 0 0 45px 0;
}

.btn.contact-form-btn {
  border: none;
  outline: none;
  padding: 12px 16px;
  background-color: #357694;
}

.mappin {
  width: 25px;
  float: left;
}

.contactTitle.col-md-12 {
  padding: 35px 0;
}

.contactMap {
  margin: 0 0 20px 0;
}

#EGovernment p {
  margin: 0 0 25px 0;
}

#EGovernment h2 {
  margin: 20px 0 8px 0;
  font-size: 25px;
}


.service-boxes {
  flex: 0 0 33.333333%;
  max-width: 19.333333%;
  align-items: center;
  justify-content: center;
}

.row .service-box-row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}

/* end custom style */
/*** 

====================================================================
  Loading Transition
====================================================================

 ***/
/* Preloader */
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}
/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(255, 57, 70, 0.15);
  border-top-color: #ff3946; 
  height: 170px;
  margin: 0 auto 45px auto;
  width: 170px;
}
/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #ff3946;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top:0;
  line-height: 70px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 15px;
  display: inline-block;
  color: rgba(255, 57, 70, 0.15);
  position: relative;
  font-size: 70px;
  line-height: 70px;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {animation-delay: 0.2s;}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {animation-delay: 0.4s;}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {animation-delay: 0.6s;}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {animation-delay: 0.8s;}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before { animation-delay: 1s;}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before { animation-delay: 1.2s;}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before { animation-delay: 1.4s;}
.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
/* Animación del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {font-size: 40px; letter-spacing: 10px;}
}

/*==================== Click Top ====================*/
.scroll-top {
  width:25px;
  height:25px;
  position: fixed;
  bottom: 10px;
  right: 5px;
  z-index: 99;
  text-align: center;
  color: #fff;
  font-size: 18px;
  display: none;
  cursor: pointer;
  border-radius: 3px;
}
.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
/*______________________ Global style ___________________*/

 @font-face {
  font-family: 'font-awesome';
  src: url('./assets/fonts/font-awesome/fonts/fontawesome-webfont.ttf');
  src: url('./assets/fonts/font-awesome/fonts/fontawesome-webfont.eot'), /* IE9 Compat Modes */
       url('./assets/fonts/font-awesome/fonts/fontawesome-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/font-awesome/fonts/fontawesome-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/font-awesome/fonts/fontawesome-webfont.svg') format('svg'); /* Legacy iOS */
 }
 body {
  font-family: 'CircularStd-book';
  font-weight: normal;
  color:#57667E;
  font-size: 17px;
  /* position: relative; */
  /* top:0 !important; */
  padding: 0px !important;;
  background: #fff !important;
 }
 .font-lato {font-family: 'Lato', sans-serif; font-weight: normal;}
 .font-k2d {font-family: 'K2D', sans-serif !important;}
 .main-page-wrapper {overflow-x:hidden;}
 h1,h2,h3,h4,h5,h6,p,ul { margin:0;padding: 0;}
 .h1,h1,.h2,h2,.h3,h3,.h4,h4,.h5,h5,.h6,h6 {color: #233D63; font-weight: normal;}
 .h1,h1 {font-size: 60px;}
 .h2,h2 {font-size: 48px;}
 .h3,h3 {font-size: 40px;}
 .h4,h4 {font-size: 30px;}
 .h5,h5 {font-size: 22px;}
 .h6,h6 {font-size: 18px;}
 p {line-height: 28px;}
 ul {list-style-type: none;}
 body a {text-decoration: none; display: inline-block;}
 a:hover,a:focus,a:visited {text-decoration: none; outline: none;}
 img {max-width: 100%; display: block;}
 button {border:none;outline: none;box-shadow: none;display: block; padding: 0;cursor: pointer;background: transparent;}
 button:focus {outline: none;}
 input,textarea {outline: none; box-shadow: none;transition: all 0.3s ease-in-out;}

 /*----------- Prefix -----------*/
.full-width-container {padding-left: 60px;padding-right: 60px;}
.demo-container-900 {max-width: 900px;margin: 0 auto;}
.demo-container-1100 {max-width: 1100px;margin: 0 auto;}
.p0 {padding: 0 !important;}
.pt-15 {padding-top: 15px;}
.pt-50 {padding-top: 50px;}
.pt-90 {padding-top: 90px;}
.pt-150 {padding-top: 150px;}
.pb-30 {padding-bottom: 30px;}
.pb-70 {padding-bottom: 70px;}
.pb-100 {padding-bottom: 100px;}
.pb-150 {padding-bottom: 150px;}
.pb-200 {padding-bottom: 200px;}
.mt-70 {margin-top: 70px;}
.mt-95 {margin-top: 95px;}
.mt-150 {margin-top: 150px;}
.mt-200 {margin-top: 200px;}
.mb-80 {margin-bottom: 80px;}
.mb-150 {margin-bottom: 150px;}
.mb-170 {margin-bottom: 170px;}
.mb-200 {margin-bottom: 200px;}
.m0 {margin: 0 !important;}
.row.gutter-80 {margin: 0 -40px;}
.row.gutter-80 [class*="col-"] {padding: 0 40px;}
.row.gutter-50 {margin: 0 -25px;}
.row.gutter-50 [class*="col-"] {padding: 0 25px;}
/*----------------- Theme button ------------------*/
body .solid-button-one {
  min-width: 188px;
  line-height: 48px;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  padding: 0 35px;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  box-shadow: 0px 13px 27px 0px rgba(163, 48, 53, 0.25);
}
body .solid-button-one:hover {background: #fff;box-shadow: none;}
body .solid-button-one .icon-right {vertical-align: -1px;margin-left: 5px;}
body .solid-button-one .icon-left {vertical-align: -1px;margin-right: 5px;}
body .video-button-one {font-size: 18px;line-height: 55px;color: #57667E;}
body .video-button-one i {font-size: 50px;color: #D8D8D8;vertical-align: middle;}
body .video-button-one .icon-right {margin-left: 10px;}
body .video-button-one .icon-left {margin-right: 10px;}
body .theme-button-one {
  font-family: 'CircularStdmed';
  width: 220px;
  line-height: 56px;
  text-align: center;
  font-size:19px;
  border-width: 2px;
  border-style: solid;
  border-radius: 30px;
  position: relative;
}
body .theme-button-one:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  border-radius: 30px;
  z-index: -1;
  transform: scale(0,1);
}
body .theme-button-one:hover {color: #fff; border-color: #fff;}
body .theme-button-one:hover:before {transform: scale(1,1);}
body .theme-button-two {
  text-transform: capitalize;
  text-align: center;
  font-size: 18px;
  color: #fff;
  line-height: 50px;
  padding: 0 40px;
  position: relative;
  z-index: 1;
}
body .theme-button-two:before {
  content: '';
  position: absolute;
  top:4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  border: 1px solid #fff;
  opacity: 0;
}
body .theme-button-two:hover:before {opacity: 1;}
body .theme-button-two .icon-right {vertical-align: -1px;margin-left: 5px;}
body .theme-button-two .icon-left {vertical-align: -1px;margin-right: 5px;}
body .line-button-one {
  text-transform: capitalize;
  line-height: 48px;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  text-align: center;
  padding: 0 35px;
  min-width: 180px;
}
body .line-button-one:hover {box-shadow: 0px 13px 27px 0px rgba(163, 48, 53, 0.25); color: #fff;}
body .line-button-two {
  text-transform: capitalize;
  line-height: 48px;
  border: 1px solid #E0E9EF;
  border-radius: 25px;
  padding: 0 35px;
  min-width: 180px;
  text-align: center;
  font-size: 18px;
  color: #233D63;
}
body .line-button-two:hover,.agn-our-pricing .table-wrapper .pr-column:hover .line-button-two {
  color: #fff;
  box-shadow: 0px 13px 28px 0px rgba(163, 48, 53, 0.25);
}
body .line-button-three {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 48px;
  border: 1px solid #171717;
  min-width: 180px;
  text-align: center;
  color: #141414;
}
body .line-button-three:hover {color: #fff;}
body .white-shdw-button {
  text-transform: capitalize;
  line-height: 55px;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  box-shadow: 0px 10px 25px 0px rgba(123,147,171,0.15);
  border-radius: 3px;
  padding: 0 33px;
  background: #fff;
}
body .white-shdw-button .icon {
  font-size: 25px;
  vertical-align: middle;
  margin-left: 10px;
}
body .white-shdw-button:hover {color: #fff;box-shadow: 0px 13px 28px 0px rgba(163, 48, 53, 0.25);}
body .dark-button-one {
  font-family: 'CircularStdmed';
  line-height: 50px;
  padding: 0 40px;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  background: #3b3b3b;
}
/*--------------------- Transition --------------------*/
.tran3s,h2 a,h3 a,h4 a,h5 a,h6 a,.svg path,body .video-button-one,body .video-button-one i,body .line-button-two,body .line-button-three,
.agn-what-we-do .single-block .more-button,.agn-our-gallery .main-wrapper .img-box .hover-content,.owl-theme .owl-nav [class*=owl-],.theme-footer-three .social-icon li a,
.theme-menu-one .logo,.theme-main-menu .header-right-widget,.agn-our-gallery .main-wrapper .view-gallery,.sass-contact-banner .subscribe-button,.language-switcher .dropdown-menu ul li a,
.theme-footer-one .top-footer .footer-list ul li a,.theme-footer-one .top-footer .footer-information ul li a,.theme-footer-one .bottom-footer ul li a,.panel .panel-heading .panel-title a:before,
.theme-menu-two .quote-button,.theme-menu-two .logo,.seo-what-we-do .single-block .wrapper,.seo-our-goal .text-wrapper .request-button,.our-service-seo .single-block .more-button,
.our-service-seo .single-block p,.seo-our-pricing .single-pr-table .pr-footer .plan-button:before,.seo-our-pricing .single-pr-table .pr-footer .plan-button,.navbar-nav .nav-item .nav-link,
.seo-our-pricing .single-pr-table .pr-footer .trial-button,.seo-contact-banner .contact-button,.theme-menu-three .header-right-widget .social-icon li a,.nav-tabs .nav-link,.theme-footer-four .social-icon li a,
.about-me-portfo .inner-content .button-group li a,.our-service-portfo .service-block .read-more,.isotop-menu-wrapper li,.our-project-portfo .text-content .gallery-button,.btn,
.portfo-footer form .input-group label,.portfo-footer form button,.portfo-footer .copyright-text .social-icon li a,.theme-menu-one .header-right-widget .call-us a,.theme-menu-one .navbar-nav .login-button a,
.our-service-sass .service-block .icon-s,.our-service-sass .service-block p,#theme-banner-four .main-wrapper .button-group .more-button,.checkout-toggle-area p button,.theme-footer-four .footer-list ul li a,
.our-service-sass .service-block .detail-button,.our-feature-sass .down-arrow,.our-feature-sass .single-feature-block  .text-box .read-more,.sass-our-pricing .single-pr-table .upgrade-button:before,
.sass-our-pricing .single-pr-table .upgrade-button,.sass-our-pricing .single-pr-table .trial-button,.sass-faq-section .sub-heading a,.sass-faq-section .down-button,.eCommerce-side-menu .main-menu-list>ul>li>a,
.theme-menu-one.d-align-item .navbar-nav .login-button-two a,.our-service-app .main-content .more-button,.our-service-app .main-content .down-arrow,.our-feature-app .text-wrapper .explore-button,
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a,.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a,#eCommerce-carousel .color-tab ul li a,
.eCommerce-side-menu .main-menu-list>ul .sub-menu a,#eCommerce-carousel .inner-item-wrapper .button-group .shop-now,#eCommerce-carousel .inner-item-wrapper .button-group .details-info-button,
.eCommerce-hero-section .social-share li a,.eCommerce-hero-section .carousel-indicators li,.product-details-modal .main-bg-wrapper .close-button,.product-details-modal .main-bg-wrapper .next,
.product-details-modal .main-bg-wrapper .prev,.theme-sidebar-widget .list-item li a,.theme-sidebar-widget .size-filter li a,.theme-sidebar-widget .color-filter li a,.shop-demo-filter .selectize-dropdown .option,
.product-showcase .single-product-case,.product-showcase .single-product-case .info .name,.product-showcase .single-product-case .info .cart-button:before,.theme-sidebar-widget .sidebar-search button,
.product-showcase .single-product-case .info .cart-button:after,.product-showcase .single-product-case .info .cart-button span,.theme-pagination-one ul li a,.theme-sidebar-widget .list-item li .sub-menu a,
.cart-list-form .table .product-info .product-name,.cart-list-form .table .remove-product,.dark-button-one,.checkout-form .checkbox-list li label:before,.page-breadcrumbs li a,.theme-footer-four .bottom-footer ul li a,
.theme-sidebar-widget .sidebar-popular-product .info a,.shop-details .procuct-details .product-info .cart-button,.shop-details .procuct-details .product-info .wishlist-button,.navbar .mega-dropdown-list li a .icon,
.shop-details .product-review-tab .nav-tabs li a:before,.faq-page .faq-search-form button,.faq-page .submit-faq button,.our-team .single-team-member .info-meta,.our-blog .post-data .date,
.our-team .single-team-member .hover-content ul li a,.our-team .single-team-member .hover-content,body .theme-button-one,body .theme-button-one:before,.our-service .service-block .read-more,
.service-standard .service-block:before,.theme-action-banner-two .banner-button,#isotop-gallery-wrapper .isotop-item .hover-jojo ul li,.gallery-sidebar .sidebar-list li a,.our-blog .post-data .read-more,
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a,#isotop-gallery-wrapper .isotop-item .hover-jojo,.our-project .project-item .hover-valina,.gallery-sidebar .sidebar-icon ul li a,.theme-footer-two ul li a,
.project-with-sidebar .project-item .img-box a,.project-minimal-style .share-icon li a,.project-details .share-icon li a,.pd-footer .theme-pager,.pd-footer .theme-pager span,body .theme-button-two:before,
.our-blog .img-holder .video-button,.theme-sidebar-widget .keywords-tag li a,.blog-details .post-tag-area .tags li a,.blog-details .post-tag-area .share-icon li a,.blog-details .user-comment-area .single-comment .reply,
.blog-details-fg .blog-fg-data .video-banner-blog .video-button,#contact-form .form-group .help-block,.contact-us-section .contact-info ul li a,.form-style-three .send-button,.faq-tab-wrapper-four .faq-panel .panel .panel-heading .panel-title a i,
.contact-address-two .address-block a,body .line-button-one,body .solid-button-one,body .white-shdw-button,#theme-banner-two .main-wrapper .button-group .contact-button,#theme-banner-two .main-wrapper .button-group .more-button,
.shrt-menu .top-header .infoList li a,.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li a,.shrt-menu .menu-social-icon li a,.shrt-menu .main-header .cart-action-wrapper .button-group a,#arch-carousel .indicators-one li i,
#arch-carousel .indicators-one li span,.arch-footer .top-footer .footer-list ul li a,.theme-tab .tabs-menu li a,.studio-feature-area .feature-block .title,.studio-feature-area .feature-block,.studio-project-holder,.studio-client-slider .customer-block p,
.studio-client-slider .customer-block .name,.studio-client-slider .customer-block .customer-info span,.studio-client-slider .customer-block:before,.studio-client-slider .customer-block,.studio-footer-one .top-footer .footer-list ul li a,
.studio-footer-one .bottom-footer ul li a   {
  transition: all .3s ease-in-out;
}
.tran4s,.theme-main-menu,.our-service-portfo .service-block:before,.our-service-portfo .service-block:after,.our-project .project-item  p,
.our-project-portfo #isotop-gallery-wrapper .isotop-item .effect-zoe img,.our-team .img-box img,.service-standard .service-block .hover-content p,
.our-project .project-item .hover-valina .zoom,.our-project .project-item .hover-valina:before,.our-project .project-item .hover-valina:after,
#mega-menu-holder .img-box .img img {
  transition: all .4s ease-in-out;
}
/*----------------------- Theme Color File -------------------*/
.theme-footer-one .top-footer .footer-list ul li a:hover,.theme-footer-one .bottom-footer ul li a:hover,.seo-our-goal .text-wrapper .request-button:hover,body .video-button-one:hover,body .video-button-one:hover i,
.seo-our-pricing .single-pr-table .pr-header .price,.seo-our-pricing .single-pr-table .pr-footer .trial-button:hover,.theme-menu-one .navbar-nav .nav-item:hover .nav-link,body .white-shdw-button,
.theme-menu-one .navbar-nav .nav-item.active .nav-link,.sass-faq-section .sub-heading a:hover,.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a:hover,
.eCommerce-side-menu .main-menu-list>ul>li:hover>a,.eCommerce-side-menu .main-menu-list>ul .sub-menu a:hover,.eCommerce-hero-section .social-share li a:hover,.theme-counter-three .single-counter-box .number,
.product-details-modal .main-bg-wrapper .close-button:hover,.theme-sidebar-widget .list-item li:hover a,.product-showcase .single-product-case:hover .info .name,.theme-title-one .upper-title,
.theme-pagination-one ul li a:hover,.theme-pagination-one ul li.active a,.theme-sidebar-widget .sidebar-search button:hover,.theme-sidebar-widget .list-item li .sub-menu a:hover,.agn-what-we-do .single-block .more-button,
.cart-list-form .table .product-info .product-name:hover,.cart-list-form .table .remove-product:hover,.checkout-toggle-area p button:hover,.checkout-toggle-area form .lost-passw:hover,
.solid-inner-banner .page-breadcrumbs li a:hover,.theme-sidebar-widget .sidebar-popular-product li:hover .info a,.shop-details .procuct-details .product-info .price,.faq-page .faq-search-form button:hover,
.shop-details .procuct-details .product-info .cart-button:hover,.realated-product .owl-theme .owl-nav [class*=owl-]:hover,.faq-tab-wrapper.faq-page .faq-panel .panel .panel-heading.active-panel .panel-title a:before,
.faq-page .submit-faq button:hover,.team-standard .single-team-member .hover-content ul li a:hover,body .theme-button-one,.team-minimal .single-team-member .hover-content ul li a:hover,
.team-classic .wrapper .hover-content li a:hover,.service-minimal .service-block:hover .read-more,.service-modren .service-block .service-info:hover .read-more,.gallery-sidebar .sidebar-icon ul li a:hover,
.service-standard .service-block .hover-content .read-more:hover,.our-project .isotop-menu-wrapper li.is-checked,.gallery-sidebar .sidebar-list li a:hover,.gallery-sidebar .sidebar-list li.active a,
.project-with-sidebar .project-item .hover-coco .title a:hover,.project-minimal-style .owl-theme .owl-nav [class*=owl-]:hover,.pd-footer .theme-pager:hover,.pd-footer .theme-pager:hover span,
.related-project .owl-theme .owl-nav [class*=owl-]:hover,.our-blog .single-blog-post:hover .post-data .title a,.theme-sidebar-widget .recent-news-item li:hover .rn-title a,body .line-button-one,
.blog-details .post-tag-area .share-icon li a:hover,#contact-form .form-group .help-block li:before,.contact-address-two .address-block p a:hover,.contact-address-two .address-block ul li a:hover,
body .solid-button-one:hover,.navbar .navbar-toggler,.faq-tab-wrapper .faq-panel .panel .panel-heading.active-panel .panel-title a:before,.faq-tab-wrapper-two .faq-panel .panel .panel-heading.active-panel .panel-title a:before,
.faq-tab-wrapper-four .faq-panel .panel .panel-heading .panel-title a i,.breadcrumbs-two .page-breadcrumbs li a:hover,.b-wh-text .page-breadcrumbs li a:hover,.agn-counter-section .counter-wrapper .single-counter-box .icon,
.agn-counter-section .counter-wrapper .single-counter-box .number,.theme-counter-three .single-counter-box .icon,.theme-list-item li .color-style,.theme-menu-one .header-right-widget .call-us a,.theme-footer-two .social-icon li a:hover,
.agn-our-pricing .table-wrapper .pr-column .pr-header .price,.theme-footer-one .about-widget .phone,.navbar .mega-dropdown-list li a:hover,.theme-menu-one .header-right-widget .language-switcher .dropdown-menu ul li a:hover,
.theme-footer-three .phone,.theme-footer-four .footer-list ul li a:hover,.theme-footer-four .phone,.theme-footer-four .bottom-footer ul li a:hover,.theme-footer-four.light-v .footer-list ul li a:hover,
.theme-footer-four.light-v .bottom-footer ul li a:hover,.shrt-menu .main-header .call-us a,.shrt-menu .top-header .infoList li a:hover,.shrt-menu .top-header .right-widget .language-switcher .dropdown-menu ul li a:hover,
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li a:hover,.shrt-menu.text-light .navbar .dropdown-menu .dropdown-item:hover, .shrt-menu.text-light .navbar .mega-dropdown-list li a:hover,
.shrt-menu.text-light .top-header .infoList li a:hover,.shrt-menu.dark-bg .top-header .right-widget .language-switcher .dropdown-menu ul li a:hover,.shrt-menu.dark-bg .top-header .right-widget .user-profile-action .dropdown-menu ul li a:hover,
.navbar .mega-dropdown-list li a:hover .icon,.pricing-tab-menu .nav-tabs .nav-item .nav-link,.signin-form-wrapper .signUp-text a,#arch-carousel .inner-item-wrapper .upper-title,#arch-carousel .indicators-one li i,
.arch-project-gallery .isotop-menu-wrapper li.is-checked,.arch-service .single-block:hover .title a,.arch-blog .blog-post:hover .title a,.arch-contact .form-wrapper .contact-text .call-us,.arch-footer .top-footer h2 a,
.arch-footer .top-footer .phone,.theme-footer-one.arch-footer .top-footer .footer-list ul li a:hover,.theme-tab-basic.theme-tab .tabs-menu li.z-active a,.theme-tab .z-content-inner ol li a:hover,.studio-feature-area .feature-block:hover .title,
.z-tabs.mobile > ul.z-tabs-mobile > li > a > span.drp-icon,#studio-banner-two .main-text-wrapper .button-link,.studio-feature-area .feature-block .icon-s,.studio-feature-area .feature-block .detail-button,.studio-client-slider .customer-block:before,
.studio-contact-area .contact-text .call-to-dial,.studio-footer-one .top-footer h2 a,.studio-footer-one .top-footer .footer-list ul li a:hover  {
  color: #357694;
}
.scroll-top,.theme-footer-one .top-footer .footer-information ul li a:hover,.theme-menu-two .quote-button:hover,.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a:hover,
#eCommerce-carousel .inner-item-wrapper .button-group .shop-now:hover,#eCommerce-carousel .inner-item-wrapper .button-group .details-info-button:hover,.product-details-modal .main-bg-wrapper .cart-button,
.theme-sidebar-widget .price-ranger .ui-slider .ui-slider-range,.theme-sidebar-widget .size-filter li a:hover,.shop-demo-filter .selectize-dropdown .option:hover,.shop-demo-filter .selectize-dropdown .active,
.product-showcase .single-product-case .info .cart-button span,.dark-button-one:hover,.shop-details .procuct-details .product-info .cart-button,.shop-details .procuct-details .product-info .wishlist-button:hover,
.shop-details .product-review-tab .tab-content .list-item li:before,.faq-page .submit-faq button,body .theme-button-one:before,.newsletter-section.agn-theme .main-wrapper form button,
.newsletter-section.agn-theme .theme-title-one.upper-bar:before,.video-action-banner-one .video-button,.video-action-banner-one .video-button:before,.theme-action-banner-two .banner-button:hover,
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a:hover,.gallery-sidebar .sidebar-title:before,.project-details .side-block .block-title:before,.our-blog .single-blog-post:hover .img-holder .video-button,
.theme-sidebar-widget .keywords-tag li a:hover,.blog-details .user-comment-area .single-comment .reply:hover,body .theme-button-two,.blog-details-fg .blog-fg-data .video-banner-blog .video-button:hover,
#contact-form .form-group .help-block,.contact-us-section .contact-info ul li a:hover,.form-style-three .send-button:hover,body .line-button-one:hover,body .solid-button-one,body .line-button-two:hover,
.agn-our-pricing .table-wrapper .pr-column:hover .line-button-two,body .white-shdw-button:hover,#theme-banner-two .main-wrapper .button-group .contact-button:hover,.seo-what-we-do .single-block:nth-child(1) .wrapper .icon-box,
.faq-tab-wrapper-three .faq-panel .panel .panel-heading.active-panel .panel-title a,.faq-tab-wrapper-four .faq-panel .panel .panel-heading.active-panel .panel-title a,.agn-our-gallery .main-wrapper .view-gallery:hover,
.agn-home-blog .single-blog-post .flip-box-back,.seo-contact-banner .contact-button:hover,.navbar .dropdown-menu .dropdown-item span,.ln-inner-page-demo .inner-wrapper .single-page a .new,.theme-footer-three .social-icon li a:hover,
.theme-footer-four .social-icon li a:hover,.navbar .mega-dropdown-list li a span,.btn-white:hover,.ln-home-demo .single-page-demo .new,.shrt-menu .main-header .cart-action-wrapper .button-group a:hover,
.pricing-tab-menu .nav-tabs .nav-item .nav-link.active,#arch-carousel .indicators-one li.active span,.about-arch .text-wrapper .mark-text:before,.about-arch .img-box .sq-box,body .line-button-three:hover,
.arch-testimonial .owl-theme .owl-dots .owl-dot.active span,.arch-testimonial .owl-theme .owl-dots .owl-dot span:hover,#arch-carousel .details-info-button,.theme-tab .tabs-menu li a:before,
.theme-tab .z-content-inner .list-item li:before,.theme-tab-solid.theme-tab .tabs-menu li.z-active a,.theme-tab .nested-tab-menu li.z-active a:before,.action-banner-three,.studio-client-slider .center .customer-block  {
  background: #357694;
}
.theme-footer-one .top-footer .footer-information ul li a:hover,.seo-contact-banner .contact-button:hover,.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a:hover,body .theme-button-one,
.theme-sidebar-widget .size-filter li a:hover,.shop-details .procuct-details .product-info .cart-button,.shop-details .procuct-details .product-info .wishlist-button:hover,.faq-page .submit-faq button,
.theme-action-banner-two .banner-button:hover,.our-project .isotop-menu-wrapper li.is-checked,#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a:hover,.theme-sidebar-widget .keywords-tag li a:hover,
.blog-details .user-comment-area .single-comment .reply:hover,.contact-us-section .contact-info ul li a:hover,.form-style-three .send-button:hover,body .line-button-one,body .solid-button-one,body .line-button-two:hover,
.agn-our-pricing .table-wrapper .pr-column:hover .line-button-two,#theme-banner-two .main-wrapper .button-group .contact-button:hover,.agn-our-gallery .main-wrapper .view-gallery:hover,.theme-footer-three .social-icon li a:hover,
.theme-footer-four .social-icon li a:hover,.pricing-tab-menu .nav-wrapper,body .line-button-three:hover,.arch-testimonial .owl-theme .owl-dots .owl-dot span,.studio-feature-area .feature-block:hover {
  border-color: #357694;
}

/*------------------- Theme Title ------------------*/
.arch-title .main-title {font-family: 'CircularStdbld';}
.arch-title .line {display: inline-block;position: relative;}
.arch-title .line:before {
  content: '';
  width: 0;
  height: 3px;
  background: #233D63;
  border-radius: 5px;
  position: absolute;
  right: -175px;
  bottom: 10px;
  transition: all 0.8s ease-out;
}
.arch-title.show-pr .line:before {width: 170px;}
.theme-title-one,.theme-title-two {position: relative;}
.theme-title-one .upper-title {
  font-size: 24px;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.theme-title-one .main-title {
  font-size: 48px;
  line-height: 60px;
  color: #233D63;
}
.theme-title-one.studio-title .main-title {
  font-size: 72px;
  font-weight: 600;
  line-height: 75px;
  color: #202020;
}
.theme-title-one.title-underline .main-title {text-decoration: underline;}
.theme-title-one .bottom-title {
  font-size: 20px;
  color: #546886;
  padding-top: 20px;
}
.theme-title-one.upper-bar {padding-top: 22px;}
.theme-title-one.upper-bar:before {
  content: '';
  width: 50px;
  height: 4px;
  border-radius: 5px;
  background: #ff7300;
  position: absolute;
  top:0;
  left: 0;
}
.theme-title-one.upper-bar.text-center:before {left:50%;  margin-left: -25px;}
.theme-title-one .icon-box {display: inline-block;margin-bottom: 25px; position: relative;}
.theme-title-one .icon-box .bg-shape {
  transform: rotate(-90deg);
  transition: all 1s ease-out;
}
.theme-title-one .icon-box.show-pr .bg-shape {transform: rotate(0deg);}
.theme-title-one .icon-box .icon {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50% , -50%);
}
.theme-title-one.arch-title .upper-title {color: #aeaeae;}
.theme-title-two {
  position: relative;
  font-size: 144px;
  color: #fff;
}
.theme-title-two:first-letter {
  text-transform: uppercase;
  font-size: 300px;
  text-shadow: 2px -0.9px 0px rgba(225, 163, 76, 1);
  color: #1d2046;
  padding-right: 15px;
}
.theme-title-two span {color: #e1a34c;}
.theme-title-three {padding: 0 145px;}
.theme-title-three .title {
  font-size: 48px;
  line-height: 60px;
  color: #3e3e3e;
}
.theme-title-three p {
  font-size: 24px;
  line-height: 40px;
  color: #798598;
  padding-top: 45px;
}
.theme-title-four {
  font-size: 72px;
  line-height: 85px;
  color: #202020;
  font-weight: 600;
}
.theme-title-four span {position: relative; display: inline-block;}
.theme-title-four span:before {
  content: '';
  width: 100%;
  height: 76%;
  position: absolute;
  top:20px;
  left:0;
  background: rgba(255,221,79,0.3);
  z-index: -1;
}
.theme-title-four span:after {
  content: '';
  width: 120px;
  height: 3px;
  position: absolute;
  background: #202020;
  top:55%;
  right: -150px;
}
/*------------------- Theme Pagination ------------------*/
.theme-pagination-one ul {
  display: inline-block;
  margin: 0 -10px;
}
.theme-pagination-one ul li {float: left;padding: 0 10px;}
.theme-pagination-one ul li a {
  font-size: 24px;
  color: #4f4f4f;
  line-height: 40px;
}
.theme-pagination-one ul li a .icon {font-size: 30px;}
.theme-pagination-one ul li.active a {cursor: default;}
/*----------------------- Theme Main Menu --------------------*/
#mega-menu-holder {position: static;}
#mega-menu-holder .img-box {display: block; text-align: center;}
#mega-menu-holder .img-box .img {
  display: block;
  overflow: hidden;
  background: #000;
  box-shadow: 0px 30px 70px 0px rgba(223, 227, 234, 0.5);
}
#mega-menu-holder .img-box .img img {width: 100%;}
#mega-menu-holder .img-box:hover .img img {opacity: 0.7;transform: scale3d(1.1,1.1,1);}
#mega-menu-holder .img-box .text {
  font-size: 15px;
  letter-spacing: 1px;
  display: block;
  color: #212121; 
  padding-top: 10px;
}
.theme-main-menu {
  position: absolute;
  top:0;
  width: 100%;
  left:0;
  z-index: 99;
  padding: 30px 0 0;
}
.theme-menu-one .logo {
 float: left;
}
.theme-menu-one .logo a {display: block;}
.theme-menu-one .navbar-nav .nav-item .nav-link {color: #64697A;}
.theme-menu-one .navbar-nav .nav-item:first-child .nav-link {margin-left: 0;}
.theme-menu-one .header-right-widget {
  position: absolute;
  top:52px;
  right: 60px;
  z-index: 1;
}
.theme-menu-one .header-right-widget>ul>li {display: inline-block;vertical-align: middle;}

.theme-menu-one .header-right-widget .language-switcher button {
  background: transparent;
  height: 55px;
  text-transform: uppercase;
  color: #233D63;
  font-size: 16px;
  margin:0 45px 0 40px;
}
.theme-menu-one .header-right-widget .language-switcher .dropdown-menu {
  min-width: 170px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
  border: 1px solid #f8f8f8;
  border-radius: 0;
}
.theme-menu-one .header-right-widget .language-switcher .dropdown-menu ul li a {
  display: block;
  padding: 0 15px;
  font-size: 15px;
  color: #767a89;
  line-height: 35px;
  text-transform: uppercase;
}
.skiptranslate {display: none !important;}
.theme-menu-one .header-right-widget .language-switcher .dropdown-menu ul li a:hover {padding-left: 20px;}
.theme-menu-one .header-right-widget .language-switcher .dropdown-toggle::after {color: #233D63;}
.theme-menu-one .header-right-widget .call-us {font-size: 20px;color: #A2ADBD;}
.theme-menu-one .header-right-widget .call-us a {font-size: 24px;margin-left: 15px;}
/*----------------- SASS MENU ---------------------*/
.theme-menu-one.d-align-item .navbar-nav .nav-item:hover .nav-link,
.theme-menu-one.d-align-item .navbar-nav .nav-item.active .nav-link,
.theme-menu-one.d-align-item .navbar .dropdown-item:focus,.theme-menu-one.d-align-item .navbar .dropdown-item:hover,
.theme-menu-one.d-align-item .navbar .dropdown-submenu.dropdown:hover>.dropdown-item,.theme-menu-one.d-align-item .navbar .navbar-toggler,
.theme-menu-one.d-align-item .navbar .mega-dropdown-list li a:hover,
.theme-menu-one.d-align-item .navbar .mega-dropdown-list li a:hover .icon {color: #f569f8;}
.theme-menu-one.d-align-item .navbar .mega-dropdown-list li a:hover .svg path {fill: #f569f8;}
.theme-menu-one.d-align-item .navbar-nav .nav-item .nav-link {padding-bottom: 0;}
.theme-menu-one.d-align-item .navbar-nav .nav-item .nav-link:before {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  left:1px;
  bottom: 5px;
  z-index: -1;
  background: #f569f8;
  transition: width 0.3s ease-out;
}
.theme-menu-one.d-align-item .header-right-widget .call-us a {color: #bebebe; margin-left: 0;}
.theme-menu-one.d-align-item .header-right-widget .call-us a:hover {color: #f569f8;}
.theme-menu-one.d-align-item .navbar-nav .nav-item .nav-link:hover:before,
.theme-menu-one.d-align-item .navbar-nav .nav-item.active .nav-link:before {width: 100%;}
.theme-menu-one.d-align-item #mega-menu-holder {margin-right: 125px;}
.theme-menu-one.d-align-item .header-right-widget {right: auto; left: 15%;}
.theme-menu-one.d-align-item .logo {top:45px;left:75px;width: 63px;}
.theme-menu-one.d-align-item .navbar-nav .login-button a {
  width: 150px;
  line-height: 50px;
  background: #fef9da;
  border-radius: 3px;
  font-size: 18px;
  color: #ead24c;
  text-align: center;
  margin-top: -12px;
}
.theme-menu-one.d-align-item .navbar-nav .login-button a:hover {background: #f569f8;color: #fff;}
.theme-menu-one.d-align-item .navbar-nav .login-button-two a {
  width: 150px;
  line-height: 48px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-top: -12px;
}
.theme-menu-one.d-align-item .navbar-nav .login-button-two a:hover {
  background: #fff;
  color: #614ef9;
}
/*------------------ Menu One Color Fix -------------------*/
.theme-menu-one.d-align-item.color-white .header-right-widget .call-us a,
.theme-menu-one.d-align-item.color-white .header-right-widget .language-switcher button,
.theme-menu-one.d-align-item.color-white .header-right-widget .language-switcher .dropdown-toggle::after,
.theme-menu-one.d-align-item.color-white .navbar-nav .nav-item .nav-link {
  color: #fff;
}
.theme-menu-one.d-align-item.color-white .navbar-nav .nav-item .nav-link:before {background: #fff;}
/*----------------------- Theme Main Menu Two  --------------------*/
.theme-menu-two {padding-left: 90px;padding-right: 90px;}
.theme-menu-two .logo {
  position: absolute;
  top:50px;
  left: 90px;
  width: 63px;
  z-index: 2;
}
.theme-menu-two .quote-button {
  width: 160px;
  line-height: 50px;
  border-radius: 3px;
  background:#fff;
  font-size: 18px;
  color: #3d3d3d;
  text-align: center;
  position: absolute;
  right: 90px;
  top:55px;
  z-index: 1;
}
.theme-menu-two .quote-button:hover {color: #fff;}
.theme-menu-two #mega-menu-holder {margin-right: 265px;}
/*---------------------- Theme Main Menu Three --------------------*/
.theme-menu-three .logo {
  position: absolute;
  top:-1px;
  left:0;
  z-index: 3;
}
.theme-menu-three .logo a {display: block;}
.theme-menu-three .navbar-nav .nav-item {padding-bottom: 15px;}
.theme-menu-three .navbar-nav .nav-item .nav-link {color: #fff; position: relative;padding: 0;}
.theme-menu-three .navbar-nav .nav-item .nav-link:before {
  content: '';
  width: 0;
  height: 5px;
  position: absolute;
  left:1px;
  bottom: 7px;
  z-index: -1;
  background: #e1a34c;
  transition: width 0.3s ease-out;
}
.theme-menu-three .navbar-nav .nav-item .nav-link:hover:before,
.theme-menu-three .navbar-nav .nav-item.active .nav-link:before {width: 91%;}
.theme-menu-three #mega-menu-holder {margin-right: 255px;}
.theme-menu-three .header-right-widget {
  position: absolute;
  top:57px;
  right: 65px;
  z-index: 1;
}
.theme-menu-three .header-right-widget .social-icon li {display: inline-block;}
.theme-menu-three .header-right-widget .social-icon li a {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  border: 2px solid rgba(255,255,255,0.8);
  font-size: 20px;
  color: rgba(255,255,255,0.8);
  border-radius: 50%;
  margin-left: 5px;
}
.theme-menu-three .header-right-widget .social-icon li a:hover {
  color: #fff;
  background: #cd9a53;
  border-color: #cd9a53;
}
.theme-menu-three .navbar .dropdown-item:focus,.theme-menu-three .navbar .dropdown-item:hover,
.theme-menu-three .navbar .dropdown-submenu.dropdown:hover>.dropdown-item,
.theme-menu-three .navbar .mega-dropdown-list li a:hover,.theme-menu-three .navbar .mega-dropdown-list li a:hover .icon {color: #e1a34c;}
.theme-menu-three .navbar .mega-dropdown-list li a:hover .svg path {fill: #e1a34c;}
/*---------------------- APP Menu ----------------------*/
.theme-menu-one.d-align-item.app-menu .navbar .dropdown-item:focus,.theme-menu-one.d-align-item.app-menu .navbar .dropdown-item:hover,
.theme-menu-one.d-align-item.app-menu .navbar .dropdown-submenu.dropdown:hover>.dropdown-item,
.theme-menu-one.d-align-item.app-menu .navbar .mega-dropdown-list li a:hover,
.theme-menu-one.d-align-item.app-menu .navbar .mega-dropdown-list li a:hover .icon {color: #614ef9;}
.theme-menu-one.d-align-item.app-menu .navbar .mega-dropdown-list li a:hover .svg path {fill: #614ef9;}
/*================= Sticky Menu ===============*/
@-webkit-keyframes menu_sticky {
  0%   {margin-top:-120px;opacity: 0;}
  50%  {margin-top: -64px;opacity: 0;}
  100% {margin-top: 0;opacity: 1;}
}
@keyframes menu_sticky {
  0%   {margin-top:-120px;opacity: 0;}
  50%  {margin-top: -64px;opacity: 0;}
  100% {margin-top: 0;opacity: 1;}
}
.theme-main-menu.fixed {
  position: fixed !important;
  background: #fff;
  box-shadow: 0px 13px 35px -12px rgba(35,35,35,0.15);
  animation: menu_sticky  0.60s ease-in-out;
}
.theme-menu-one.d-align-item.fixed .logo {width: 63px;}
.theme-menu-one.fixed.d-align-item .logo {top:5px;}
.theme-menu-one.fixed .navbar-nav .nav-item .nav-link,
.theme-menu-two.fixed .navbar-nav .nav-item .nav-link {padding-bottom: 20px;}

.theme-menu-one.fixed,.theme-menu-two.fixed {padding: 20px 0 0;}
.theme-main-menu.theme-menu-three.fixed {padding: 20px 0 5px;}
.theme-menu-one.fixed .logo,.theme-menu-three.fixed .logo {width: 70px;}
.theme-menu-one.fixed .header-right-widget {top:7px;}
.theme-menu-two.fixed .logo {top:7px;}
.theme-menu-two.fixed .quote-button {top:9px;}
.theme-main-menu.theme-menu-two.fixed,.theme-menu-one.d-align-item.color-white.fixed {background: #1b1d3d;}
.theme-main-menu.theme-menu-three.fixed {background: rgba(29,31,66,0.98); border-bottom: 1px solid rgba(255,255,255,0.03);}
.theme-menu-three.fixed .header-right-widget {top:14px;}
.theme-menu-one.d-align-item.fixed .navbar-nav .nav-item .nav-link {padding-bottom: 0;}
.theme-menu-one.d-align-item.fixed  {padding-bottom: 12px;}
/*---------------------------- Theme Main Banner One -------------------------*/
#theme-banner-one {
  background: url(./assets/images/shape/1.png) no-repeat top right;
  position: relative;
}
#theme-banner-one .main-wrapper {padding: 235px 0 155px;}
#theme-banner-one .main-wrapper .slogan {
  display: inline-block;
  background: #FBFBFF;
  border: 1px solid #E9E9E9;
  line-height: 32px;
  padding: 0 20px;
  font-size: 15px;
  color: #677180;
  border-radius: 3px;
}
#theme-banner-one .main-wrapper .slogan span {color: #FF605E;}
#theme-banner-one .main-wrapper .main-title {
  font-family: 'CircularStdmed';
  font-size: 70px;
  line-height: 80px;
  padding: 25px 0 35px;
}
#theme-banner-one .main-wrapper .sub-title {
  font-size: 20px;
  line-height: 32px;
  color: #8b8e92;
  padding-bottom: 28px;
}
#theme-banner-one .main-wrapper .button-group li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  line-height: 55px;
}
#theme-banner-one .main-wrapper .button-group .more-button {margin-right: 30px;}
#theme-banner-one .illustration {
  position: absolute;
  right: 12%;
  top:27%;
}
#theme-banner-one .illustration .shape-one {
  position: absolute;
  top: -28px;
  right: -63px;
}
#theme-banner-one .illustration .shape-two {
  position: absolute;
  bottom: 0;
  right: -25px;
}
#theme-banner-one .oval-one {
  position: absolute;
  right: -3%;
  top:26%;
  animation: jump 8s infinite linear;
}
#theme-banner-one .shape-three {
  position: absolute;
  top:21%;
  right: 22%;
  animation: animationFramesTwo 13s infinite linear;
}
#theme-banner-one .shape-four {
  position: absolute;
  top:27%;
  left: 7%;
  animation: animationFramesFour 25s alternate infinite linear;
}
#theme-banner-one .shape-five {
  position: absolute;
  top:50%;
  left: 50%;
  animation: animationFramesThree 35s alternate infinite linear;
}
#theme-banner-one .shape-six {
  position: absolute;
  top:70%;
  right: 3%;
  animation: animationFramesFour 20s alternate infinite linear;
}
#theme-banner-one .shape-seven {
  position: absolute;
  bottom: 2%;
  right: 28%;
  animation: animationFramesOne 15s infinite linear;
}
#theme-banner-one .shape-eight {
  position: absolute;
  bottom: 3%;
  left: 38%;
  animation: animationFramesFour 20s alternate infinite linear;
}
#theme-banner-one .shape-nine {
  position: absolute;
  bottom: 4%;
  left: 4%;
  animation: animationFramesOne 17s infinite linear;
}
#theme-banner-one .shape-ten {
  position: absolute;
  top:60%;
  left: 4%;
  animation: animationFramesOne 20s alternate infinite linear;
}
#theme-banner-one .shape-eleven {
  position: absolute;
  top:22%;
  left: 41%;
  animation: animationFramesOne 15s infinite linear;
}
/*---------------------------- Theme Main Banner Two -------------------------*/
#theme-banner-two {
  background: linear-gradient( -120deg, rgb(135,88,255) 0%, rgb(97,78,249) 100%);
  position: relative;
  overflow:hidden;
}
#theme-banner-two:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 753px;
  background: url(assets/images/shape/bg4.png) no-repeat left top;
  bottom:-1px;
  left:0;
  z-index: 2;
}
#theme-banner-two .main-wrapper {
  padding: 285px 0 300px;
  position: relative;
  z-index: 9;
}
#theme-banner-two .bg-round-one {
  width: 690px;
  height: 690px;
  background:rgba(255,255,255,0.04);
  border-radius: 50%;
  position: absolute;
  top: -170px;
  left: -281px;
  z-index: 1;
}
#theme-banner-two .bg-round-two {
  width: 690px;
  height: 690px;
  background:rgba(255,255,255,0.02);
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: -464px;
  z-index: 1;
}
#theme-banner-two .bg-round-three {
  width: 400px;
  height: 400px;
  background:rgba(255,255,255,0.02);
  border-radius: 0 0 0 85px;
  position: absolute;
  top:-87px;
  right: -98px;
  z-index: 1;
  transform: rotate(-9deg);
}

#theme-banner-two .bg-round-four {
  width: 250px;
  height: 250px;
  background:rgba(255,255,255,0.02);
  border-radius: 0 0 0 85px;
  position: absolute;
  top:-87px;
  right: -98px;
  z-index: 1;
  transform: rotate(-9deg);
}
#theme-banner-two .bg-round-five {
  width: 650px;
  height: 650px;
  background: linear-gradient( -90deg, rgb(133,87,255) 0%, rgb(128,92,255) 100%);
  border-radius: 50%;
  position: absolute;
  bottom: -90px;
  right: -205px;
  z-index: 1;
}
#theme-banner-two .illustration {
  position: absolute;
  right: 10%;
  top: 28%;
  z-index: 1;
  max-width: 47%;
}
#theme-banner-two .main-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  line-height: 72px;
  color: #fff;
  text-decoration: underline;
}
#theme-banner-two .sub-title {
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  padding: 40px 0 35px;
}
#theme-banner-two .main-wrapper .button-group li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  line-height: 50px;
}
#theme-banner-two .main-wrapper .button-group .more-button {
  width: 165px;
  line-height: 50px;
  border:1px solid transparent;
  text-align: center;
  border-radius: 3px;
  font-size: 18px;
  color: #fff;
  background: linear-gradient( -180deg, rgb(0,235,172) 0%, rgb(0,209,244) 100%);
  margin-right: 18px;
}
#theme-banner-two .main-wrapper .button-group .more-button i {vertical-align: -1px;}
#theme-banner-two .main-wrapper .button-group .more-button:hover {background: transparent;border-color: #fff;}
#theme-banner-two .main-wrapper .button-group .contact-button {
  width: 170px;
  line-height: 48px;
  border: 1px solid #fff;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
/*----------------- Theme Main Banner Three ---------------*/
#theme-banner-three {
  position: relative;
  padding: 320px 0 320px;
}
.partical-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 0;
}
#particles {width: 100%;height: 100%;}
#theme-banner-three .main-title {
  font-family: 'CircularStdbld';
  font-size: 140px;
  color: #fff;
  text-decoration: underline;
}
#theme-banner-three .main-title span,
#theme-banner-three .main-title span b {text-decoration: underline;line-height: 160px;}
#theme-banner-three .main-title i {
  color: #e1a34c;
  font-style: normal;
}
#theme-banner-three:before {
  content: '';
  background: -webkit-radial-gradient( 50% 50%, circle closest-side, rgb(35,38,91) 0%, rgb(27,29,61) 100%);
  position: absolute;
  width: 2285px;
  height: 2285px;
  border-radius: 50%;
  left:50%;
  top:-500px;
  transform: translateX(-50%);
  z-index: -1;
}
/*---------------------------- Theme Main Banner Four -------------------------*/
#theme-banner-four {position: relative;}
#theme-banner-four .main-wrapper {padding: 290px 0 260px;position: relative;z-index: 5;}
#theme-banner-four .screen-one {
  position: absolute;
  right: 0;
  top:12%;
  z-index: 1;
  width: 35%;
}
#theme-banner-four .screen-two {
  position: absolute;
  right: 24%;
  top:27%;
  z-index: 1;
  width: 22%;
}
#theme-banner-four .shape-one {
  position: absolute;
  right: 0;
  top:145px;
  z-index: 0;
  opacity: 0.65;
}
#theme-banner-four .shape-two {
  position: absolute;
  bottom: 12%;
  left:31%;
  animation: rotated 20s infinite linear;
}
#theme-banner-four .shape-three {
  position: absolute;
  bottom: 19%;
  right: 4%;
  z-index: -1;
  animation: rotated 50s infinite linear;
}
#theme-banner-four .shape-four {
  position: absolute;
  top: 17%;
  right: 37%;
  z-index: -1;
  animation: rotatedHalf 30s infinite linear;
}
#theme-banner-four .round-shape-one {
  width: 20px;
  height: 20px;
  border: 3px solid #fe8f8f;
  border-radius: 50%;
  position: absolute;
  top:15%;
  left:36%;
  animation: animationFramesOne 15s infinite linear;
}
#theme-banner-four .round-shape-two {
  width: 7px;
  height: 7px;
  background: #d5eeff;
  border-radius: 50%;
  position: absolute;
  top:23%;
  left:8%;
  animation: animationFramesTwo 13s infinite linear;
}
#theme-banner-four .round-shape-three {
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid #a3eead;
  border-radius: 50%;
  bottom: 42%;
  left: 6%;
  animation: animationFramesTwo 15s infinite linear;
}
#theme-banner-four .round-shape-four {
  position: absolute;
  right: 26%;
  bottom: 20%;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border:1px solid #fbfbfb;
  animation: rotated 10s infinite linear;
}
#theme-banner-four .round-shape-four:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top:50%;
  left: -5px;
  background: #ddabf6;
  border-radius: 50%;
}
#theme-banner-four .main-wrapper .slogan {
  display: inline-block;
  background: #FBFBFF;
  border: 1px solid #E9E9E9;
  line-height: 32px;
  padding: 0 20px;
  font-size: 15px;
  color: #677180;
  border-radius: 18px;
}
#theme-banner-four .main-wrapper .slogan span {color: #FF605E;}
#theme-banner-four .main-wrapper .main-title {
  font-family: 'CircularStdmed';
  font-size: 70px;
  line-height: 80px;
  padding: 25px 0 35px;
}
#theme-banner-four .main-wrapper .sub-title {
  font-size: 20px;
  line-height: 32px;
  color: #8b8e92;
  padding-bottom: 28px;
}
#theme-banner-four .main-wrapper .button-group li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  line-height: 55px;
}
#theme-banner-four .main-wrapper .button-group .more-button {
  width: 185px;
  line-height: 50px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  box-shadow: 0px 10px 40px 0px rgba(150,57,57,0.25);
  background: linear-gradient( -135deg, rgb(191,108,255) 0%, rgb(144,65,255) 100%);
  margin-right: 30px;
}
#theme-banner-four .main-wrapper .button-group .more-button:hover {box-shadow: none;}
#theme-banner-four .main-wrapper .button-group .more-button i {vertical-align: -1px;margin-left: 5px;}
#theme-banner-four .main-wrapper .button-group .video-button:hover,
#theme-banner-four .main-wrapper .button-group .video-button:hover i {color: #f569f8;}
/*---------------------- Theme Main Banne Five -----------------------*/
#theme-banner-five {
  background: linear-gradient( 145deg, rgb(132,0,252) 0%, rgb(17,222,244) 100%);
  position: relative;
  z-index: 5;
}
#theme-banner-five .bg-shape-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: -1;
  overflow: hidden;
}
#theme-banner-five .bg-shape-holder .big-round-one {
  position: absolute;
  width: 662px;
  height: 662px;
  background: rgba(255,255,255,0.04);
  top: -346px;
  left: -75px;
  border-radius: 50%;
}
#theme-banner-five .bg-shape-holder .big-round-two {
  position: absolute;
  width: 1372px;
  height: 1372px;
  background: rgba(255,255,255,0.04);
  top: -689px;
  left: -368px;
  border-radius: 50%;
  z-index: -1;
}
#theme-banner-five .bg-shape-holder .big-round-three {
  position: absolute;
  width: 2242px;
  height: 2242px;
  background: rgba(255,255,255,0.04);
  top: -1161px;
  left: -779px;
  border-radius: 50%;
  z-index: -3;
}
#theme-banner-five .bg-shape-holder .shape-one {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgba(213,238,255,0.29);
  position: absolute;
  top:18%;
  left:14%;
  animation: animationFramesTwo 13s infinite linear;
}
#theme-banner-five .bg-shape-holder .shape-two {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgba(255,255,255,0.16);
  position: absolute;
  top:16%;
  left:41%;
  animation: animationFramesOne 15s infinite linear;
}
#theme-banner-five .bg-shape-holder .shape-three {
  position: absolute;
  bottom: 15%;
  left:35%;
  animation: animationFramesFour 15s infinite linear alternate;
}
#theme-banner-five .bg-shape-holder .shape-four {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(213,238,255,0.29);
  position: absolute;
  bottom: 12%;
  right: 30%;
  animation: animationFramesFive 15s infinite linear alternate;
}
#theme-banner-five .mobile-screen-one {
  position: absolute;
  right: -3%;
  top: 145px;
  z-index: -1;
  width: 29%;
}
#theme-banner-five .mobile-screen-two {
  position: absolute;
  right: 20%;
  top: 260px;
  z-index: -1;
  width: 29%;
}
#theme-banner-five .mobile-screen-two .search-box {
  position: absolute;
  top: 31%;
  right: 28%;
  width: 68%;
}
#theme-banner-five .main-wrapper {padding: 300px 0 215px 15%;}
#theme-banner-five .main-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  line-height: 72px;
  color: #fff;
}
#theme-banner-five .sub-title {
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  padding: 40px 0 35px;
}
#theme-banner-five .main-wrapper .button-group li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
}
#theme-banner-five .main-wrapper .button-group li a {
  width: 213px;
  border: 2px solid #fff;
  border-radius: 3px;
  padding: 9px 0 9px 70px;
  position: relative;
  font-size: 20px;
  color: #fff;
}
#theme-banner-five .main-wrapper .button-group li a span {
  display: block;
  font-size: 13px;
  margin-bottom: -5px;
}
#theme-banner-five .main-wrapper .button-group li a.google-button {margin-right: 22px;}
#theme-banner-five .main-wrapper .button-group li a img {
  position: absolute;
  left:30px;
  top:50%;
  transform: translateY(-50%);
  width: 30px;
}
/*---------------------- What We Do/Agency --------------------*/
.agn-what-we-do {position: relative;}
.agn-what-we-do .shape-one {
  position: absolute;
  top:19%;
  left:0;
}
.agn-what-we-do .shape-two {
  position: absolute;
  top:25%;
  right:11%;
}
.agn-what-we-do .theme-title-one {padding-bottom: 50px;}
.agn-what-we-do .single-block .icon {position: relative; height: 98px;}
.agn-what-we-do .single-block .icon .bg {animation: rotated 25s infinite linear;}
.agn-what-we-do .single-block .icon .shape {
  position: absolute;
  top:35px;
  left: 10px;
  z-index: 1;
}
.agn-what-we-do .single-block .title {
  font-size: 22px;
  color: #244574;
  padding: 35px 0 35px;
}
.agn-what-we-do .single-block p {padding-right: 90px;}
.agn-what-we-do .single-block .more-button {
  font-size: 15px;
  text-decoration: underline;
  margin-top: 20px;
}
.agn-what-we-do .single-block .more-button:hover {text-decoration: overline;}
.agn-what-we-do .single-block {padding-top: 60px;}
/*---------------------- What We Do/SEO --------------------*/
.seo-what-we-do {position: relative;padding: 150px 0 100px;}
.seo-what-we-do .theme-title-one {padding-bottom: 60px;}
.seo-what-we-do .single-block {margin-top: 40px;}
.seo-what-we-do .single-block .wrapper {
  background: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 50px 10px 60px;
}
.seo-what-we-do .single-block.active .wrapper,
.seo-what-we-do .single-block .wrapper:hover {box-shadow: 0px 20px 70px 0px rgba(4, 7, 18, 0.06);}
.seo-what-we-do .single-block .wrapper .title {
  display: block;
  font-size: 20px;
  color: #233d63;
  margin-top: 30px;
}
.seo-what-we-do .single-block .wrapper .icon-box {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  z-index: 5;
}
.seo-what-we-do .single-block .wrapper .icon-box img {
  position: relative;
  margin: 0 auto;
  top:50%;
  transform: translateY(-50%);
}
.seo-what-we-do .single-block:nth-child(2) .wrapper .icon-box {background: #00ebac;}
.seo-what-we-do .single-block:nth-child(3) .wrapper .icon-box {background: #9657f6;}
.seo-what-we-do .single-block:nth-child(4) .wrapper .icon-box {background: #ff930e;}
.seo-what-we-do .single-block .wrapper .icon-box:before,
.seo-what-we-do .single-block .wrapper .icon-box:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}
.seo-what-we-do .single-block .wrapper .icon-box:before{
  top:0px;
  right: 0px;
  bottom: 0px;
  left:0px;
}
.seo-what-we-do .single-block .wrapper .icon-box:after{
  top:0px;
  right: 0px;
  bottom: 0px;
  left:0px;
}
.seo-what-we-do .single-block:nth-child(1) .wrapper .icon-box:before,
.seo-what-we-do .single-block:nth-child(1) .wrapper .icon-box:after {
 background: rgba(255,58,70,0.5);
}
.seo-what-we-do .single-block:nth-child(2) .wrapper .icon-box:before,
.seo-what-we-do .single-block:nth-child(2) .wrapper .icon-box:after {
 background: rgba(0,235,172,0.5);
}
.seo-what-we-do .single-block:nth-child(3) .wrapper .icon-box:before,
.seo-what-we-do .single-block:nth-child(3) .wrapper .icon-box:after {
 background: rgba(150,87,246,0.5);
}
.seo-what-we-do .single-block:nth-child(4) .wrapper .icon-box:before,
.seo-what-we-do .single-block:nth-child(4) .wrapper .icon-box:after {
 background: rgba(255,147,14,0.5);
}

.seo-what-we-do .single-block.active .wrapper .icon-box:after,
.seo-what-we-do .single-block .wrapper:hover .icon-box:after {
  -webkit-animation: hvr-ripple-out 1.5s linear infinite;
  animation: hvr-ripple-out 1.5s linear infinite;
}
.seo-what-we-do .single-block.active .wrapper .icon-box:before,
.seo-what-we-do .single-block .wrapper:hover .icon-box:before {
  -webkit-animation: hvr-ripple-out-two 1.5s linear infinite;
  animation: hvr-ripple-out-two 1.5s linear infinite;
}
/*----------------------- About Us -------------------*/
.agn-about-us {position: relative;padding: 135px 0 0;}
.agn-about-us .shape-one {
  position: absolute;
  left: 52%;
  top:2%;
  animation: animationFramesOne 35s alternate infinite linear;
}
.agn-about-us .shape-two {
  position: absolute;
  right: 0;
  top:-15%;
}
.agn-about-us .shape-three {
  position: absolute;
  left: 0;
  top:20%;
  z-index: -1;
}
.agn-about-us .shape-four {
  position: absolute;
  left: 50%;
  bottom: -16%;
  animation: animationFramesTwo 25s alternate infinite linear;
}
.agn-about-us .theme-title-one {padding-bottom: 35px;}
.agn-about-us .img-box {
  position: relative;
  padding-left: 85px;
}
.agn-about-us .img-box .main-img {border-radius: 5px;}
.agn-about-us .img-box:before {
  content: '';
  width: 188px;
  height: 196px;
  background: #614ef9;
  border-radius: 5px;
  position: absolute;
  top:-100px;
  left:0;
  z-index: -1;
}
.agn-about-us .text-wrapper .quote {
  font-size: 20px;
  line-height: 34px;
  color: #233D63;
  padding: 45px 0 35px;
}
.agn-about-us .text-wrapper .author {
  position: relative;
  padding-left: 35px;
  margin-bottom: 35px;
  font-size: 16px;
  color: #808C9F;
}
.agn-about-us .text-wrapper .author .name {
  font-family: 'CircularStdmed';
  font-size: 20px;
  color: #233D63;
}
.agn-about-us .text-wrapper .author:before {
  content: '';
  width: 20px;
  height: 2px;
  position: absolute;
  left:0;
  top:14px;
  background: #233D63;
}
.agn-about-us .learn-more {
  font-size: 20px;
  width: 230px;
  line-height: 70px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.agn-about-us:before {
  content: url(assets/images/shape/2.png);
  position: absolute;
  top:0;
  left:0;
  z-index: -1;
}
/*------------------------ Theme Counter -------------------*/
.agn-counter-section {
  position: relative;
  margin: 250px 0 185px;
}
.agn-counter-section .shape-one {
  position: absolute;
  left: 9%;
  top:28%;
  animation: animationFramesFour 25s alternate infinite linear;
}
.agn-counter-section .shape-two {
  position: absolute;
  right: 14%;
  top:13%;
  animation: animationFramesFour 35s alternate infinite linear;
}
.agn-counter-section .shape-three {
  position: absolute;
  bottom: 4%;
  right: 8%;
  animation: rotated 20s infinite linear;
}
.agn-counter-section .main-wrapper {
  background:url(assets/images/shape/map.png) no-repeat bottom center;
  padding-bottom: 115px;
  position: relative;
}
.agn-counter-section .main-wrapper .theme-title-one {padding-bottom: 80px;}
.agn-counter-section .counter-wrapper .single-counter-box {text-align: center; padding-top: 35px;}
.agn-counter-section .counter-wrapper .single-counter-box .icon {
  font-size: 60px;
  margin-bottom: 10px;
}
.agn-counter-section .counter-wrapper .single-counter-box .number {font-size: 84px;}
.agn-counter-section .counter-wrapper .single-counter-box p {font-size: 20px;}
.agn-counter-section .bottom-banner {
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  margin: 50px 100px 0;
  padding: 45px 60px 28px;
}
.agn-counter-section .bottom-banner .text {float: left;}
.agn-counter-section .bottom-banner .text .title {
  font-size: 27px;
  padding-bottom: 2px;
}
.agn-counter-section .bottom-banner .text p {font-size: 18px;}
.agn-counter-section .bottom-banner .contact {float: right;line-height: 46px;}
.agn-counter-section.fix-bg-position .main-wrapper {padding: 95px 0 155px;}
/*-------------------- Our Gallery ---------------------*/
.agn-our-gallery {position: relative; padding: 100px 0 195px;}
.agn-our-gallery .shape-one {
  position: absolute;
  top:0;
  left:45%;
  animation: animationFramesOne 25s infinite linear;
}
.agn-our-gallery .shape-two {
  position: absolute;
  top:15%;
  right: 10%;
  animation: animationFramesFour 25s alternate infinite linear;
}
.agn-our-gallery .shape-three {
  position: absolute;
  bottom: 12%;
  right: 40%;
  animation: rotated 20s infinite linear;
}
.agn-our-gallery:before {
  content: url(assets/images/shape/3.png);
  position: absolute;
  top:0;
  right: 0;
  z-index: -1;
}
.agn-our-gallery .main-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 50px 35px 0;
}
.agn-our-gallery .main-wrapper .item {margin: 0 30px;}
.agn-our-gallery .main-wrapper .img-box {
  position: relative;
  margin: 50px 0;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 20px 50px 0px rgba(46, 65, 90, 0.1);
}
.agn-our-gallery .main-wrapper .img-box img {
  width: 100%;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}
.agn-our-gallery .main-wrapper .img-box .hover-content {
  position: absolute;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  background: rgba(26,26,26,0.5);
  text-align: center;
  border-radius: 5px;
  transform: scale(0.5);
  opacity: 0;
}
.agn-our-gallery .main-wrapper .img-box .hover-content .icon {
  position: relative;
  top:50%;
  transform: translateY(-50%);
  font-size: 60px;
  color: #fff;
}
.agn-our-gallery .main-wrapper .img-box:hover .hover-content {
  transform: scale(1);
  opacity: 1;
}
.agn-our-gallery .main-wrapper .view-gallery {
  width: 190px;
  line-height: 55px;
  text-align: center;
  border-radius: 4px;
  border:2px solid rgba(151,151,151,0.3);
  font-size: 18px;
  color: #233D63;
  position: absolute;
  top:0;
  right: 150px;
}
.agn-our-gallery .main-wrapper .view-gallery:hover {
  color: #fff;
  box-shadow: 0px 13px 28px 0px rgba(163, 48, 53, 0.25);
}
.agn-our-gallery .owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  margin: 0 10px;
  padding: 0;
  font-size: 30px;
  color: rgba(0,0,0,0.4);
}
.agn-our-gallery .owl-theme .owl-nav {
  position: absolute;
  top:-50px;
  right: 365px;
}
.agn-our-gallery .owl-theme .owl-nav [class*=owl-]:hover {color: #233D63;}
/*-------------------- Our Pricing/ Agency -----------------*/
.pricing-tab-menu {text-align: center;}
.pricing-tab-menu .nav-wrapper {
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}
.pricing-tab-menu .nav {border:none;}
.pricing-tab-menu .nav-tabs .nav-item {margin: 0;}
.pricing-tab-menu .nav-tabs .nav-item .nav-link {
  border:none;
  width: 160px;
  padding: 0;
  line-height: 48px;
  font-size: 20px;
  border-radius: 0;
  display: block;
}
.pricing-tab-menu .nav-tabs .nav-item .nav-link.active {color: #fff;}
.pricing-tab-menu p {font-size: 20px;padding-top: 30px;}
.agn-our-pricing {position: relative;}
.agn-our-pricing .shape-one {
  position: absolute;
  top:20%;
  left:10%;
}
.agn-our-pricing .shape-two {
  animation: animationFramesOne 15s infinite linear;
  position: absolute;
  top:18%;
  right:14%;
}
.agn-our-pricing .shape-three {
  position: absolute;
  bottom: 10%;
  left:10%;
  animation: animationFramesOne 15s infinite linear;
}
.agn-our-pricing .shape-four {
  position: absolute;
  bottom: 5%;
  left:50%;
  animation: animationFramesOne 25s infinite linear;
}
.agn-our-pricing .shape-five {
  position: absolute;
  bottom: 5%;
  right: 5%;
}
.agn-our-pricing .table-wrapper {
  max-width: 1245px;
  margin: 100px auto 0;
  padding: 0 15px;
}
.agn-our-pricing .table-wrapper .inner-table {
  position: relative;
  padding-left: 215px;
}
.agn-our-pricing .table-wrapper .inner-table .pr-list-text {
  width: 215px;
  position: absolute;
  left:0;
  top:230px;
}
.agn-our-pricing .table-wrapper .inner-table .pr-list-text li {
  font-size: 17px;
  padding: 25px 0 25px 25px;
  color: #233D63;
  border-top: 1px solid #E0E9EF;
  border-left: 1px solid #E0E9EF;
}
.agn-our-pricing .table-wrapper .inner-table .pr-list-text li:last-child {border-bottom: 1px solid #E0E9EF;}
.agn-our-pricing .table-wrapper .inner-table .pr-column {
  float: left;
  width: 33.333333%;
  border: 1px solid #E0E9EF;
  border-right:none;
  text-align: center;
}
.agn-our-pricing .table-wrapper .inner-table .pr-column:last-child {border-right: 1px solid #E0E9EF;}
.agn-our-pricing .table-wrapper .pr-column .pr-header {
  padding: 40px 0 41px;
  border-bottom: 1px solid #E0E9EF;
}
.agn-our-pricing .table-wrapper .pr-column .pr-header .price {font-size: 44px;}
.agn-our-pricing .table-wrapper .pr-column .pr-header .price span {font-size: 18px;}
.agn-our-pricing .table-wrapper .pr-column .pr-header .title {
  font-size: 32px;
  color: #233D63;
  padding: 5px 0 12px;
}
.agn-our-pricing .table-wrapper .pr-column .pr-header .package {
  font-size: 18px;
  text-transform: uppercase;
  color: rgba(35,61,99,0.35);
}
.agn-our-pricing .table-wrapper .pr-column .pr-body li {
  border-bottom: 1px solid #E0E9EF;
  padding: 25px 0;
}
.agn-our-pricing .table-wrapper .pr-column .pr-body li .pr-text {
  font-size: 16px;
  color: rgba(35,61,99,0.45);
  padding-bottom: 3px;
  display: none;
}
.agn-our-pricing .table-wrapper .pr-column .pr-body li .icon {font-size: 16px; }
.agn-our-pricing .table-wrapper .pr-column .pr-body li .none {color: rgba(151,151,151,0.75);}
.agn-our-pricing .table-wrapper .pr-column .pr-body li .available {color: #00e953;}
.agn-our-pricing .table-wrapper .pr-column .pr-footer {padding: 48px 0 45px;}
.agn-our-pricing .table-wrapper .pr-column .pr-footer p {
  font-size: 17px;
  color: rgba(35,61,99,0.5);
  padding-top: 15px;
}
/*-------------------- Pricing / APP ---------------------*/
.pricing-app .theme-title-one.upper-bar:before {background:#63e8ce;}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-header .price {font-size: 58px;color: #9285ff;}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-header .price sup {font-size: 24px;top:-23px;}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-header .title {
  font-size: 18px;
  color: rgba(35,61,99,0.45);
  padding: 0;
}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-header {padding-bottom: 54px;}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-header .package {color: #202020;}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-body li .icon {
  display: block;
  font-size: 14px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  text-align: center;
  line-height: 27px;
  margin: 0 auto;
}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-body li .available {
  background: #4cdd75;
  color: #fff;
}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-body li {padding: 24px 0;}
.agn-our-pricing.pricing-app .table-wrapper .pr-column .pr-footer .choose-button {border-radius: 4px;}
.agn-our-pricing.pricing-app .table-wrapper .pr-column:hover .pr-footer .choose-button {
  background: #614ef9;
  border-color: #614ef9;
  box-shadow: none;
}
.pricing-app .pricing-tab-menu .nav-wrapper {border-color: #9285ff;}
.pricing-app .pricing-tab-menu .nav-tabs .nav-item .nav-link {color: #9285ff;}
.pricing-app .pricing-tab-menu .nav-tabs .nav-item .nav-link.active {background: #9285ff; color: #fff;}
/*-------------------- Our Pricing/ SEO -----------------*/
.seo-our-pricing {position: relative;}
.seo-our-pricing.section-bg {
  background: url(assets/images/shape/bg2.png) no-repeat center top;
  background-size: cover;
  padding: 185px 0 280px;
}
.seo-our-pricing .theme-title-one {padding-bottom: 45px;}
.seo-our-pricing .single-pr-table {
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(60, 67, 113, 0.05);
  position: relative;
  text-align: center;
  border-radius: 5px 5px 0 0;
  margin-top: 50px;
  overflow: hidden;
}
.seo-our-pricing .single-pr-table:before {
  content: '';
  width: 50px;
  height: 6px;
  border-radius: 5px 5px 0 0;
  background: #fff;
  position: absolute;
  top:0;
  left:-50px;
  z-index: 1;
  transition: all 0.8s ease-out;
}
.seo-our-pricing .single-pr-table:after {
  content: '';
  width: 100%;
  height: 6px;
  border-radius: 5px 5px 0 0;
  position: absolute;
  top:0;
  left: calc(-100% - 100px);
  z-index: 1;
  transition: all 0.8s ease-out;
  background: #fff;
}
.seo-our-pricing .single-pr-table:hover:after {left:100%;}
.seo-our-pricing .single-pr-table:hover:before {opacity: 1;left: 100%;}

.seo-our-pricing .single-pr-table .pr-header {
  position: relative;
  z-index: 1;
  padding: 40px 0 32px;
  border-bottom: 1px solid #e8e8e8;
}
.seo-our-pricing .single-pr-table.subway .pr-header {border-top: 6px solid #00e1ce;}
.seo-our-pricing .single-pr-table.sandwich .pr-header {border-top: 6px solid #ffcd63;}
.seo-our-pricing .single-pr-table.burger .pr-header {border-top: 6px solid #9f4aff;}
.seo-our-pricing .single-pr-table .pr-header .title {
  font-size: 18px;
  text-transform: uppercase;
  color: #202020;
}
.seo-our-pricing .single-pr-table .pr-header .price {
  font-size: 60px;
  margin: -12px 0 -5px;
}
.seo-our-pricing .single-pr-table .pr-header .price sup {font-size: 24px; top:-20px;}
.seo-our-pricing .single-pr-table .pr-header .package {
  font-size: 15px;
  text-transform: uppercase;
  color: #b9b9b9;
}
.seo-our-pricing .single-pr-table .pr-header .icon {
  opacity: 0.18;
  position: absolute;
  left:-60px;
  top:17%;
  z-index: -1;
}
.seo-our-pricing .single-pr-table:hover .pr-header .icon {
  transform: scale(1.3)  translateX(80px);
}
.seo-our-pricing .single-pr-table .pr-body {padding: 42px 0 40px;}
.seo-our-pricing .single-pr-table .pr-body .slogan {font-size: 24px;padding-bottom: 12px;color: #2f3442;}
.seo-our-pricing .single-pr-table .pr-body ul li {
  line-height: 45px;
  font-size: 17px;
  color: rgba(35,61,99,0.7);
}
.seo-our-pricing .single-pr-table .pr-footer {border-top: 1px solid #e8e8e8;padding: 40px 0 40px;}
.seo-our-pricing .single-pr-table .pr-footer .plan-button {
  position: relative;
  z-index: 5;
  margin: 0 auto;
}
.seo-our-pricing .single-pr-table .pr-footer .plan-button:before {
  content: ' ';
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  left:0;
  background: linear-gradient( -105deg, rgb(0,233,176) 0%, rgb(0,212,237) 100%);
  z-index: -1;
  border-radius: 25px;
  transform: scaleX(0);
  opacity: 0;
}
.seo-our-pricing .single-pr-table:hover .pr-footer .plan-button {color: #fff;}
.seo-our-pricing .single-pr-table:hover .pr-footer .plan-button:before {
  transform: scaleX(1);
  opacity: 1;
}
.seo-our-pricing .single-pr-table .pr-footer .plan-button:hover {border-color: transparent;box-shadow: none;background: #fff;}
.seo-our-pricing .single-pr-table .pr-footer .trial-button {
  font-size: 17px;
  color: rgba(35,61,99,0.45);
  margin-top: 20px;
  display: block;
}
/*------------------------ Testimonial ---------------------*/
.agn-testimonial {position: relative;padding: 85px 0 125px;}
.agn-testimonial .main-content {
  max-width: 600px;
  float: right;
  position: relative;
  z-index: 1;
}
.agn-testimonial .main-content .theme-title-one {padding-bottom: 40px;}
.agn-testimonial .main-content .agn-testimonial-slider p {
  font-size: 20px;
  color: #76797e;
  line-height: 37px;
  padding: 0 80px 50px 0;
}
.agn-testimonial .main-content .agn-testimonial-slider .author-info .author-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
}
.agn-testimonial .main-content .agn-testimonial-slider .author-info .name-info {
  float: left;
  padding-left: 20px;
}
.agn-testimonial .main-content .agn-testimonial-slider .author-info .name-info .name {font-size: 20px;}
.agn-testimonial .main-content .agn-testimonial-slider .author-info .name-info span {
  color: #c7c7c7;
  font-size: 16px;
}
.agn-testimonial .owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  margin: 0 10px;
  padding: 0;
  font-size: 30px;
  color: rgba(0,0,0,0.4);
}
.agn-testimonial .owl-theme .owl-nav {
  text-align: left;
  margin-top: 35px;
}
.agn-testimonial .owl-theme .owl-nav [class*=owl-]:hover {color: #233D63;}
.agn-testimonial .shape-box {
  position: absolute;
  top:0;
  left:0;
  width: 38%;
  height: 100%;
}
.agn-testimonial .shape-box .people {
  position: absolute;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  box-shadow: 0px 10px 20px 0px rgba(20, 44, 56, 0.25);
  z-index: 1;
}
.agn-testimonial .shape-box .people:nth-child(2) {
  top:37%;
  right: 41%;
  animation: scale-up-one 14s infinite linear;
}
.agn-testimonial .shape-box .people:nth-child(3) {
  top:47%;
  right: 22%;
  animation: scale-up-two 9s infinite linear;
}
.agn-testimonial .shape-box .people:nth-child(4) {
  top:16%;
  right: 29%;
  animation: scale-up-three 8s infinite linear;
}
.agn-testimonial .shape-box .people:nth-child(5) {
  top:24%;
  right: 66%;
  animation: scale-up-two 10s infinite linear;
}
.agn-testimonial .shape-box .people:nth-child(6) {
  top:52%;
  right: 69%;
  animation: scale-up-three 11s infinite linear;
}
.agn-testimonial .shape-box .people:nth-child(7) {
  top:61%;
  right: 40%;
  animation: scale-up-one 12s infinite linear;
}
.agn-testimonial .shape-box .shape-one {
  position: absolute;
  left:-30%;
  top:28%;
  animation: rotated 2s infinite linear;
}
.agn-testimonial .shape-two {
  position: absolute;
  right: 0;
  bottom: 11%;
}
/*--------------------- Testimonial/Portfolio ---------------------*/
.portfo-testimonial.section-portfo {border-bottom: none;padding-bottom: 270px;}
.portfo-testimonial:before {
  content: '';
  background: -webkit-radial-gradient( 50% 50%, circle closest-side, rgb(35,38,91) 0%, rgb(27,29,61) 100%);
  position: absolute;
  width: 2366px;
  height: 2366px;
  border-radius: 50%;
  left:50%;
  top:-600px;
  transform: translateX(-50%);
  z-index: -1;
}
.portfo-testimonial .theme-title-two {padding-bottom: 95px;}
.portfo-testimonial .inner-title {
  font-family: 'CircularStdmed';
  color:#fff;
  font-size: 48px;
  line-height: 65px;
  text-decoration: underline;
}
.portfo-testimonial .slider-wrapper {
  position: relative;
  padding-left: 80px;
}
.portfo-testimonial .slider-wrapper:before {
  content: "\f111";
  font-family: Flaticon;
  position: absolute;
  color: #2d3061;
  font-size: 115px;
  top:-93px;
  left: 80px;
}
.portfo-testimonial .slider-wrapper .item p {
  font-size: 26px;
  line-height: 50px;
  color: rgba(255,255,255,0.8);
  font-style: italic;
  padding-bottom: 50px;
}
.portfo-testimonial .slider-wrapper  .author-info .author-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
}
.portfo-testimonial .slider-wrapper  .author-info .name-info {
  float: left;
  padding-left: 20px;
}
.portfo-testimonial .slider-wrapper  .author-info .name-info .name {font-size: 24px; color: #fff;}
.portfo-testimonial .slider-wrapper  .author-info .name-info span {
  color: #797a96;
  font-size: 18px;
}
.portfo-testimonial .owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  margin: 0 10px;
  padding: 0;
  font-size: 30px;
  color: rgba(255,255,255,0.6);
}
.portfo-testimonial .owl-theme .owl-nav {
  text-align: left;
  margin-top: 35px;
}
.portfo-testimonial .owl-theme .owl-nav [class*=owl-]:hover {color: #e1a34c;}
/*------------------------ Testimonial/SASS ---------------------*/
.sass-testimonial-section {
  position: relative;
  border-top:1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 115px 0 272px;
  background: linear-gradient( 90deg, rgb(243,255,231) 0%, rgb(222,255,240) 100%);
  overflow: hidden;
}
.sass-testimonial-section:before {
  content: 'D';
  font-family: 'CircularStdblck';
  font-size: 400px;
  color: #FAFFFD;
  position: absolute;
  right: -109px;
  bottom: -272px;
  transform: rotate(180deg);
}
.sass-testimonial-section .main-content {
  position: relative;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 16px 25px 0px rgba(0, 0, 0, 0.03);
  padding: 80px 70px 55px;
  z-index: 5;
}
.sass-testimonial-section .main-content:before {
  content: url(assets/images/icon/icon28.svg);
  position: absolute;
  top:50px;
  left:50%;
  transform: translateX(-50%);
  opacity: 0.04;
}
.sass-testimonial-section .inner-container {
  width: 69%;
  margin: 60px auto 0;
  position: relative;
  z-index: 1;
  text-align: center;
}
.sass-testimonial-section .inner-container:before {
  content: '';
  position: absolute;
  bottom: -30px;
  left:35px;
  right: 35px;
  height: 200px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 15px 23px 0px rgba(35, 49, 64, 0.09);
  z-index: -1;
}
.sass-testimonial-section .inner-container:after {
  content: '';
  position: absolute;
  bottom: -55px;
  left:80px;
  right: 80px;
  height: 200px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 14px 21px 0px rgba(0, 0, 0, 0.03);
  z-index: -3;
}
.sass-testimonial-section .inner-container p {
  font-size: 24px;
  line-height: 40px;
  color: #303858;
}
.sass-testimonial-section .inner-container .name {
  font-family: 'CircularStdmed';
  font-size: 18px;
  text-transform: uppercase;
  color: #303858;
  padding: 45px 0 4px;
}
.sass-testimonial-section .inner-container .designation {font-size: 17px;color: #9fa4b8;}
.sass-testimonial-section .owl-theme .owl-nav {
  position: absolute;
  width: 100%;
  left:0;
  bottom: -228px;
}
.sass-testimonial-section .owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  margin: 0 10px;
  padding: 0;
  font-size: 35px;
  color: #5fa775;
  opacity: 0.5;
}
.sass-testimonial-section .owl-theme .owl-nav [class*=owl-]:hover {opacity: 1;}
.sass-testimonial-section .people {
  position: absolute;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  box-shadow: 0px 10px 20px 0px rgba(20, 44, 56, 0.25);
}
.sass-testimonial-section .people:nth-child(1) {
  top: 12%;
  left: 13%;
  animation: scale-up-one 14s infinite linear;
}
.sass-testimonial-section .people:nth-child(2) {
  top: 44%;
  left: 4%;
  animation: scale-up-two 9s infinite linear;
}
.sass-testimonial-section .people:nth-child(3) {
  bottom: 7%;
  left: 10%;
  animation: scale-up-three 8s infinite linear;
}
.sass-testimonial-section .people:nth-child(4) {
  top: 12%;
  right: 19%;
  animation: scale-up-two 10s infinite linear;
}
.sass-testimonial-section .people:nth-child(5) {
  top:42%;
  right: 2%;
  animation: scale-up-three 11s infinite linear;
}
.sass-testimonial-section .people:nth-child(6) {
  bottom: 7%;
  right: 10%;
  animation: scale-up-one 12s infinite linear;
}
/*------------------------ Testimonial/Apps ---------------------*/
.apps-testimonial {
  background: #f8faff;
  position: relative;
  padding: 190px 0 20px;
  overflow: hidden;
}
.apps-testimonial.bg-white {background: #fff;}
.apps-testimonial .theme-title-one.upper-bar:before {background: #fee341;}
.apps-testimonial .theme-title-one {padding-bottom: 78px;display: inline-block;}
.apps-testimonial .customer-block {
  padding: 72px 30px 50px 45px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 30px 0px rgba(244, 245, 249, 0.8);
  margin: 10px 15px 30px;
}
.apps-testimonial .customer-block:before {
  content: url(assets/images/icon/icon35.svg);
  position: absolute;
  top: 50px;
  left: 45px;
}
.apps-testimonial.bg-white .customer-slider:before {opacity: 0.45;}
.apps-testimonial .customer-block p {
  font-size: 20px;
  line-height: 35px;
  padding-bottom: 45px;
}
.apps-testimonial .customer-block .customer-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  float: left;
}
.apps-testimonial .customer-block .customer-info {padding-left: 25px;float: left;}
.apps-testimonial .customer-block .customer-info .name {
  font-size: 20px;
  color: #303858;
  padding: 5px 0 2px;
}
.apps-testimonial .customer-block .customer-info span {color: #ACB0BD;}
.apps-testimonial .owl-theme .owl-nav {
  position: absolute;
  right: 0;
  top:-140px;
}
.apps-testimonial .owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  margin: 0 6px;
  padding: 0;
  font-size: 35px;
  color: #d3d3d3;
}
.apps-testimonial .owl-theme .owl-nav [class*=owl-]:hover {color: #45ebff;}
.apps-testimonial .customer-slider:before {
  content: url(assets/images/icon/icon36.svg);
  position: absolute;
  right: 0;
  top:-278px;
  z-index: -1;
}
.apps-testimonial .d-shape {
  position: absolute;
  font-size: 600px;
  font-family: 'CircularStdblck';
  line-height: 420px;
  color: #fff;
  bottom: -60px;
  left: -192px;
}
.apps-testimonial .u-shape {
  position: absolute;
  font-size: 705px;
  font-family: 'CircularStdblck';
  line-height: 420px;
  color: #fff;
  right: -199px;
  top: -190px;
}
.apps-testimonial .trusted-partner {margin-top: 130px;}
.apps-testimonial .shape-one {
  position: absolute;
  top:10%;
  left:5%;
  animation: animationFramesFive 20s infinite linear alternate;
}
.apps-testimonial .shape-two {
  position: absolute;
  top:50%;
  left:5%;
  animation: animationFramesFive 18s infinite linear alternate;
}
.apps-testimonial .shape-three {
  position: absolute;
  top:25%;
  left:50%;
  animation: animationFramesOne 15s infinite linear alternate;
}
.apps-testimonial .shape-four {
  position: absolute;
  top:50%;
  right: 5%;
  animation: animationFramesFive 18s infinite linear alternate;
}
/*------------------------- Home Blog ------------------------*/
.agn-home-blog {
  position: relative;
  padding: 200px 0 200px;
}
.agn-home-blog .shape-one {
  position: absolute;
  top:20%;
  left:9%;
  animation: animationFramesOne 25s infinite linear;
}
.agn-home-blog .shape-two {
  position: absolute;
  top:17%;
  right:13%;
  animation: animationFramesFour 50s alternate infinite linear;
}
.agn-home-blog .shape-three {
  position: absolute;
  bottom: 12%;
  right:6%;
  animation: rotated 18s infinite linear;
}
.agn-home-blog .shape-four {
  position: absolute;
  bottom: 9%;
  left: 25%;
  animation: animationFramesFour 50s alternate infinite linear;
}
.agn-home-blog .theme-title-one {padding-bottom: 100px;}
.agn-home-blog .single-blog-post {
  position: relative;
  overflow:hidden;
  -webkit-transform:perspective(1px) translateZ(0);
 transform:perspective(1px) translateZ(0);
}
.agn-home-blog .single-blog-post .flip-box-front {
  border:1px solid #f1f1f1;
  padding: 50px 15px 50px 35px;
  border-radius: 3px;
}
.agn-home-blog .single-blog-post .flip-box-front .author-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  float: left;
}
.agn-home-blog .single-blog-post .flip-box-front .author-info {float: left;padding-left: 22px;}
.agn-home-blog .single-blog-post .author-info .name {
  font-size: 20px;
  color: #233d63;
  padding-top: 7px;
}
.agn-home-blog .single-blog-post .author-info .date {font-size: 16px; color: #838b98;}
.agn-home-blog .single-blog-post .title {
  font-size: 24px;
  line-height: 32px;
  color: #233d63;
  margin: 28px 0 20px;
}
.agn-home-blog .single-blog-post p { color: rgba(87,102,126,0.8); }
.agn-home-blog .single-blog-post .flip-box-back {
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 3px;
  padding: 50px 15px 50px 35px;
  -webkit-transform:scaleY(0);
 transform:scaleY(0);
 -webkit-transform-origin:50% 100%;
 transform-origin:50% 100%;
 -webkit-transition-property:transform;
 transition-property:transform;
 -webkit-transition-duration:.4s;
 transition-duration:.4s;
 -webkit-transition-timing-function:ease-out;
 transition-timing-function:ease-out
}
.agn-home-blog .single-blog-post .flip-box-back .author-info .name,
.agn-home-blog .single-blog-post .flip-box-back .author-info .date,
.agn-home-blog .single-blog-post .flip-box-back .title {color: #fff;}
.agn-home-blog .single-blog-post .flip-box-back .author-info .name {padding: 0 0 5px;}
.agn-home-blog .single-blog-post .flip-box-back .more {
  color:#fff;
  font-size: 40px;
  position: absolute;
  left:35px;
  bottom: 10px;
}
.agn-home-blog .single-blog-post:hover .flip-box-back {
  transform-origin: center top;
  -webkit-transform:scaleY(1);
 transform:scaleY(1)
}
/*--------------------- Contact Banner/Agency ---------------------*/
.agn-contact-banner {
  text-align: center;
  background-color: #F9FBFE;
  background-image: url(assets/images/shape/map2.png);
  background-position: center center;
  background-repeat: no-repeat;
  padding: 140px 0;
}
.agn-contact-banner .title {
  font-size: 48px;
  line-height: 64px;
  color: #233D63;
  padding-bottom: 35px;
}
.agn-contact-banner .contact-button {
  font-size: 20px; 
  line-height: 56px;
  width: 220px;
}
/*--------------------- Contact Banner/SEO ---------------------*/
.seo-contact-banner {
  text-align: center;
  background: linear-gradient( -135deg, rgb(138,78,255) 0%, rgb(107,82,255) 100%);
  padding: 140px 0;
  position: relative;
  overflow: hidden;
  z-index: 5;
  margin-top: 210px;
}
.seo-contact-banner:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background-image: url(assets/images/shape/map2.png);
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.seo-contact-banner .title {
  font-size: 48px;
  line-height: 64px;
  color: #fff;
  padding-bottom: 35px;
}
.seo-contact-banner .contact-button {
  width: 220px;
  line-height: 56px;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 20px;
  color: #fff;
}
.seo-contact-banner .contact-button:hover {color: #fff;}
.seo-contact-banner .round-shape-one {
  position: absolute;
  width: 482px;
  height: 482px;
  border-radius: 50%;
  background: rgba(216,216,216,0.03);
  top:-175px;
  left: -130px;
  animation: jello 5s infinite linear;
  z-index: -1;
}
.seo-contact-banner .round-shape-two {
  position: absolute;
  width: 482px;
  height: 482px;
  border-radius: 50%;
  background: rgba(216,216,216,0.03);
  bottom:-105px;
  left: -290px;
  animation: pulse 2s infinite linear;
}
.seo-contact-banner .d-shape {
  font-family: 'CircularStdblck';
  font-size: 700px;
  position: absolute;
  line-height: 500px;
  right: -200px;
  top:-31px;
  transform: rotate(180deg);
  color: rgba(35,61,99,0.05);
  z-index: -1;
}
/*--------------------- Contact Banner/SASS ---------------------*/
.sass-contact-banner {
  text-align: center;
  background: linear-gradient( -134deg, rgb(254,255,239) 0%, rgb(255,251,208) 100%);
  padding: 140px 0;
  position: relative;
  overflow: hidden;
  z-index: 5;
}
.sass-contact-banner:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background-image: url(assets/images/shape/map3.png);
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.sass-contact-banner .title {
  font-size: 48px;
  line-height: 64px;
  padding-bottom: 35px;
}
.sass-contact-banner .subscribe-button {
  width: 248px;
  line-height: 60px;
  background: #fff;
  border-radius: 5px;
  font-size: 20px;
  color: #393939;
  box-shadow: 0px 15px 30px 0px rgba(246, 245, 211, 1);
}
.sass-contact-banner .subscribe-button:hover {color: #fff;background: #233d63;}
.sass-contact-banner .u-shape {
  font-family: 'CircularStdblck';
  font-size: 705px;
  position: absolute;
  top:-500px;
  left:-260px;
  color: #fff;
  z-index: -1;
}
.sass-contact-banner .d-shape {
  font-family: 'CircularStdblck';
  font-size: 700px;
  color: #FFFFFF;
  position: absolute;
  right: -218px;
  bottom: -515px;
  transform: rotate(180deg);
  z-index: -1;
}
/*-------------------------- Theme Footer One -------------------*/
.theme-footer-one {
  position: relative;
  padding-top: 125px;
}
.theme-footer-one .shape-two {
  position: absolute;
  right: 10%;
  top:50%;
  animation: rotated 18s infinite linear;
}
.theme-footer-one.top-border {border-top: 1px solid #ededed; padding-top: 95px;}
.theme-footer-one .about-widget .email {
  font-size: 18px;
  color: #76797e;
  margin: 15px 0 15px;
  display: block;
}
.theme-footer-one .about-widget .phone {font-size: 22px;}
.theme-footer-one .top-footer .title {font-size: 22px;padding: 12px 0 25px;}
.theme-footer-one .top-footer .footer-list ul li a {
  display: block;
  line-height: 38px;
  font-size: 17px;
  color: #76797e;
}
.theme-footer-one .top-footer .footer-information p {padding: 0 0 30px; color: #76797e;}
.theme-footer-one .top-footer .footer-information ul li {display: inline-block;}
.theme-footer-one .top-footer .footer-information ul li a {
  width: 40px;
  height: 40px;
  border:1px solid #dbdbdb;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #dbdbdb;
  margin-right: 10px;
}
.theme-footer-one .top-footer .footer-information ul li a:hover {color: #fff;}
.theme-footer-one .bottom-footer {
  border-top: 1px solid #ededed;
  margin-top: 90px;
  padding: 35px 0;
}
.theme-footer-one .bottom-footer p {float: left;color: rgba(87,102,126,0.8);}
.theme-footer-one .bottom-footer ul {float: right;}
.theme-footer-one .bottom-footer ul li {display: inline-block;}
.theme-footer-one .bottom-footer ul li a {
  line-height: 28px;
  color: rgba(87,102,126,0.8);
  margin-left: 15px;
}
.theme-footer-one .shape-one {
  position: absolute;
  width: 54px;
  height: 54px;
  border:6px solid #27c3ff;
  border-radius: 50%;
  position: absolute;
  left:-22px;
  top:48%;
}
.theme-footer-one .shape-one:before {
  content: '';
  position: absolute;
  top:-55px;
  left:-55px;
  right: -55px;
  bottom: -55px;
  border: 1px solid #ecf4ff;
  border-radius: 50%;
}
/*----------------------- Our Goal ------------------------*/
.seo-our-goal {
  position: relative;
  background: url(assets/images/shape/bg1.png) no-repeat center;
  background-size: cover;
  padding: 150px 0 220px;
}
.seo-our-goal .theme-title-one .upper-title {color: rgba(35,61,99,0.4);}
.seo-our-goal .text-wrapper p {
  font-size: 18px;
  line-height: 32px;
  padding: 22px 0 30px;
}
.seo-our-goal .text-wrapper .list-item li {
  font-size: 17px;
  line-height: 37px;
  color: rgba(87,102,126,0.8);
  padding-left: 28px;
  position: relative;
}
.seo-our-goal .text-wrapper .list-item li:before {
  content: "\f107";
  font-family: "Flaticon";
  font-size: 16px;
  color: #ff6400;
  position: absolute;
  left:0;
  top:0;
  line-height: 37px;
}
.seo-our-goal .text-wrapper .request-button {
  font-size: 16px;
  color: #ff6400;
  margin-top: 40px;
}
.seo-our-goal .text-wrapper .request-button i {
  font-size: 18px;
  vertical-align: -1px;
  margin-left: 2px;
}
.seo-our-goal .text-wrapper .request-button:hover {text-decoration: underline;}
.seo-our-goal .right-shape {
  position: absolute;
  top:60px;
  right: 0;
  height: 569px;
  width: 41%;
  background: url(assets/images/home/8.jpg) no-repeat left top;
  box-shadow: 0px 50px 100px 0px rgba(89, 102, 138, 0.1);
}

/*--------------------- Our Service/SEO ----------------------*/
.our-service-seo {
  position: relative;
  background: linear-gradient( -135deg, rgb(140,103,255) 0%, rgb(88,66,255) 100%);
  padding: 300px 0 350px;
  overflow:hidden;
}
.our-service-seo:before {
  content: '';
  width: 150%;
  height: 350px;
  background: #fff;
  transform: rotate(-4deg);
  position: absolute;
  top: -125px;
  right: 0;
}
.our-service-seo:after {
  content: '';
  width: 150%;
  height: 170px;
  background: #fff;
  transform: rotate(-4deg);
  position: absolute;
  bottom: -45px;
  left: -3px;
}
.our-service-seo .round-shape-one {
  position: absolute;
  font-size: 550px;
  color: rgba(255,255,255,0.05);
  top:88px;
  right: -145px;
  line-height: 290px;
  animation: tada 8s infinite linear;
}
.our-service-seo .round-shape-two {
  position: absolute;
  width: 396px;
  height: 396px;
  border: 50px solid rgba(0,0,0,0.03);
  border-radius: 50%;
  bottom: -100px;
  left: -190px;
  animation: jello 10s infinite linear;
}
.our-service-seo .theme-title-one .upper-title {color: rgba(255,255,255,0.5);}
.our-service-seo .theme-title-one .main-title {color: #fff;}
.our-service-seo .theme-title-one {padding-bottom: 100px;}
.our-service-seo .single-block {
  position: relative;
  z-index: 1;
  border: 1px solid rgba(250,250,250,0.15);
  border-radius: 7px;
  padding: 45px 60px 40px 115px;
  margin-bottom: 40px;
}
.our-service-seo .single-block .icon {
  position: absolute;
  top:50px;
  left: 42px;
}
.our-service-seo .single-block .title a {
  font-size: 24px;
  display: block;
  color: #fff;
  margin-right: 100px;
}
.our-service-seo .single-block p {
  line-height: 30px;
  color: rgba(255,255,255,0.8);
  padding: 12px 0 25px;
}
.our-service-seo .single-block .more-button {
  font-size: 16px;
  color: rgba(255,255,255,0.9);
}
.our-service-seo .single-block .more-button i {
  font-size: 18px;
  vertical-align: -1px;
  margin-left: 2px;
}
.our-service-seo .single-block .more-button:hover {text-decoration: underline;}
.our-service-seo .single-block:before {
  content: '';
  position: absolute;
  background: #fff;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 7px;
  transform: scaleY(0);
  transform-origin:50% 100%;
  transition: transform 0.4s ease-out;
}
.our-service-seo .single-block:hover:before {
  transform-origin: center top;
  -webkit-transform:scaleY(1);
 transform:scaleY(1)
}
.our-service-seo .single-block:hover .title a {color: #2c3e5c;}
.our-service-seo .single-block:hover p {color: rgba(52,52,52,0.6);}
.our-service-seo .single-block:hover .more-button {color: #ff6400;}
/*--------------------- Our Service/SASS ----------------------*/
.our-service-sass {
  background: url(assets/images/shape/shape-12.svg) no-repeat center bottom;
  background-size: cover;
  position: relative;
  padding: 105px 0 115px;
}
.our-service-sass:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: -1;
  background: linear-gradient( 0deg, rgb(251,253,255) 0%, rgb(255,255,255) 100%);
}
.our-service-sass:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 80px;
  background: #d4e5ea;
  top:-40px;
  left:50%;
  margin-left: -1px;
  transform: scale(1,0);
  transition: all 1s ease-out;
}
.our-service-sass.show-pr:after {transform: scale(1,1);}
.our-service-sass .theme-title-one {padding-bottom: 52px;z-index: 2;}
.our-service-sass .inner-wrapper {position: relative;z-index: 1;}
.our-service-sass .inner-wrapper:before {
  content: url(assets/images/shape/shape-13.svg);
  position: absolute;
  top:-165px;
  left:-165px;
  z-index: -1;
}
.our-service-sass .inner-wrapper:after {
  content: url(assets/images/shape/shape-14.svg);
  position: absolute;
  bottom:-165px;
  right:-165px;
  z-index: -1;
}
.our-service-sass .service-block {
  background: #fff;
  border:1px solid #eff7ff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 5;
  padding: 180px 30px 25px 40px;
  margin-top: 45px;
}
.our-service-sass .service-block:before {
  content: '';
  width: 273px;
  height: 273px;
  background: #d858f5;
  border-radius: 50%;
  position: absolute;
  top:-148px;
  left:-83px;
  transform: scale(0);
}
.our-service-sass .service-block:hover:before {
  transform: scale(1);
  transition: all 0.5s linear;
  transition-delay: 0.1s;
}
.our-service-sass .service-block:hover {border-color: transparent;}
.our-service-sass .service-block .icon-s {
  position: absolute;
  font-size: 65px;
  left:40px;
  top:52px;
}
.our-service-sass .single-block:nth-child(1) .service-block .icon-s {color: #6df7bc;}
.our-service-sass .single-block:nth-child(2) .service-block .icon-s {color: #25e5eb;}
.our-service-sass .single-block:nth-child(3) .service-block .icon-s {color: #ff4ca5;}

.our-service-sass .service-block .title a {
  font-size: 22px;
  line-height: 32px;
  color: #253d5f;
  margin-bottom: 30px;
}
.our-service-sass .service-block p {color: #737f92;padding-bottom: 20px;}
.our-service-sass .service-block .detail-button {
  font-size: 40px;
  color: #cad5e5;
}
.our-service-sass .service-block .hover-content {
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background: #9a5be8;
  z-index: -3;
  opacity: 0;
  transition: all 0.3s linear;
}
.our-service-sass .service-block:hover .hover-content {
  opacity: 1;
}
.our-service-sass .service-block .hover-content:before {
  content: '';
  width: 493px;
  height: 493px;
  background: #c647fe;
  border-radius: 50%;
  position: absolute;
  top:-250px;
  left:-180px;
  z-index: 1;
  transform: translate(-50% , -50%);
  opacity: 0;
}
.our-service-sass .service-block:hover .hover-content:before {
  opacity: 1;
  transform: translate(0% , 0%);
  transition: all 0.9s linear;
}
.our-service-sass .service-block .hover-content:after {
  content: '';
  width: 602px;
  height: 602px;
  background: #a461e8;
  border-radius: 50%;
  position: absolute;
  top:-196px;
  left:-180px;
  z-index: -1;
  transform: translate(-50% , -50%);
  opacity: 0;
}
.our-service-sass .service-block:hover .hover-content:after {
  opacity: 1;
  transform: translate(0% , 0%);
  transition: all 1.3s linear;
}
.our-service-sass .service-block .snow-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255,255,255,0.5);
  z-index: -1;

}
.our-service-sass .service-block .snow-dot:nth-child(1) {top:25px;left:41%; z-index: 1; animation: scale-upOne 1s alternate infinite ease-in;}
.our-service-sass .service-block .snow-dot:nth-child(2) {top:25px;left:78%; animation: scale-upOne 1.1s alternate infinite ease-in;}
.our-service-sass .service-block .snow-dot:nth-child(3) {top:75px;left:61%; animation: scale-upOne 0.8s alternate infinite ease-in;}
.our-service-sass .service-block .snow-dot:nth-child(4) {top:136px;left:84%; animation: scale-upOne 1s alternate infinite ease-in;}
.our-service-sass .service-block .snow-dot:nth-child(5) {bottom:35px;right:12%; animation: scale-upOne 1.2s alternate infinite ease-in;}
.our-service-sass .service-block .snow-dot:nth-child(6) {bottom:80px;right:31%; animation: scale-upOne 0.9s alternate infinite ease-in;}
.our-service-sass .service-block .snow-dot:nth-child(7) {bottom:40px;right:51%; animation: scale-upOne 1s alternate infinite ease-in;}
.our-service-sass .service-block:hover .title a,.our-service-sass .service-block:hover .icon-s,
.our-service-sass .service-block:hover p,.our-service-sass .service-block:hover .detail-button {
  color: #fff !important;
}
.theme-more-feature.our-service-sass:after,.theme-more-feature.our-service-sass .inner-wrapper:before,
.theme-more-feature.our-service-sass .inner-wrapper:after,.theme-more-feature.our-service-sass:before {display: none;}
.our-service-sass.theme-more-feature {background: none; padding: 165px 0 180px;}
/*----------------------- Our Service/APP ------------------------*/
.our-service-app {
  position: relative;
  padding: 260px 0 200px;
  border-bottom: 1px solid #ededed;
}
.our-service-app .main-content {
  max-width: 1450px;
  padding: 0 15px;
  margin: 90px auto 0;
}
.our-service-app .main-content .inner-wrapper {
  text-align: center;
  position: relative;
  margin-top: 50px;
}
.our-service-app .main-content .inner-wrapper .title a {
  font-size: 24px;
  color: #4e4e4e;
  margin: 45px 0 30px;
}
.our-service-app .main-content .inner-wrapper:hover .title a {color: #614ef9;}
.our-service-app .main-content .inner-wrapper p {
  line-height: 30px;
  padding: 0 50px;
  color: #8c929d;
}
.our-service-app .main-content .inner-wrapper .illustration-box {
  display: inline-block;
  position: relative;
  min-height: 230px;
}
.our-service-app .main-content .single-block:nth-child(1) .illustration-box .bg-shape,
.our-service-app .main-content .single-block:nth-child(3) .illustration-box .bg-shape {animation: rotated 35s infinite linear;}
.our-service-app .main-content .single-block:nth-child(1) .illustration-box .block-shape-one {
  position: absolute;
  top:42px;
  left:-16px;
}
.our-service-app .main-content .single-block:nth-child(1) .illustration-box .block-shape-two {
  position: absolute;
  top:42px;
  right: 0;
}
.our-service-app .main-content .single-block:nth-child(1) .illustration-box .block-shape-three {
  position: absolute;
  bottom: 24px;
  right: 0;
}
.our-service-app .main-content .single-block:nth-child(1) .illustration-box [class*="block-shape"] {transform: scale(1.5);}
.our-service-app .main-content .single-block .illustration-box [class*="block-shape"] {
  opacity: 0;
  transition: all 1.5s ease-in-out;
}
.our-service-app .main-content .single-block:nth-child(2) .illustration-box .block-shape-one {
  position: absolute;
  top:120px;
  left:26px;
  transform: scale(1.5);
}
.our-service-app .main-content .single-block:nth-child(2) .illustration-box .block-shape-two {
  position: absolute;
  top:0;
  left:120px;
  transform: scale(1.5);
}
.our-service-app .main-content .single-block:nth-child(2) .illustration-box .block-shape-three {
  position: absolute;
  top:66px;
  right: 6px;
  transform: scale(1.5);
}
.our-service-app .main-content .single-block:nth-child(2) .illustration-box .block-shape-four {
  position: absolute;
  top:52px;
  right: 63px;
  transform: translateY(30px);
}
.our-service-app .main-content.show-pr .single-block:nth-child(2) .illustration-box [class*="block-shape"] {
  opacity: 1;
  transform: scale(1);
}
.our-service-app .main-content.show-pr .single-block:nth-child(2) .illustration-box .block-shape-four {transform: translateY(0);}
.our-service-app .main-content .single-block:nth-child(3) .illustration-box .block-shape-one {
  position: absolute;
  top:7px;
  left:45px;
  transform: scale(1.5);
}
.our-service-app .main-content.show-pr .single-block:nth-child(1) .illustration-box [class*="block-shape"],
.our-service-app .main-content.show-pr .single-block:nth-child(2) .illustration-box [class*="block-shape"],
.our-service-app .main-content.show-pr .single-block:nth-child(3) .illustration-box [class*="block-shape"] {
  opacity: 1;
  transform: scale(1);
}
.our-service-app .main-content .single-block:nth-child(2) .illustration-box:before,
.our-service-app .main-content .single-block:nth-child(2) .illustration-box:after {
  content: '';
  position: absolute;
  width: 145px;
  height: 1px;
  border-top: 1px dashed #e6e6e6;
  top:45%;
}
.our-service-app .main-content .single-block:nth-child(2) .illustration-box:before {right: -150px;}
.our-service-app .main-content .single-block:nth-child(2) .illustration-box:after {left: -150px;}
.our-service-app .main-content .more-button {
  display: block;
  width: 230px;
  line-height: 51px;
  border-radius: 30px;
  background: #614ef9;
  border: 2px solid #614ef9;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  margin: 100px auto 0;
  text-align: center;
}
.our-service-app .main-content .more-button:hover {background: #fff;color:#614ef9; }
.our-service-app .main-content .down-arrow {
  display: block;
  width: 60px;
  height: 60px;
  box-shadow: 0px 15px 20px 0px rgba(209, 218, 235, 0.35);
  background: #fff;
  border-radius: 50%;
  margin: 0 0 0 -30px;
  line-height: 60px;
  text-align: center;
  color: #7494b5;
  font-size: 20px;
  position: absolute;
  bottom: -30px;
  left:50%;
  z-index: 1;
}
.our-service-app .main-content .down-arrow span {
  display: block;
  transform: rotate(-90deg);
}
.our-service-app .main-content .down-arrow:hover {color: #fff;background: #614ef9;}
/*--------------------- Work Progress/SEO -------------------------*/
.seo-work-progress {
  position: relative;
  padding: 210px 0 230px;
}
.seo-work-progress .single-work-list {position: relative; padding-bottom: 310px;}
.seo-work-progress .single-work-list:nth-child(odd):before {
  content: url(assets/images/shape/line-1.svg);
  position: absolute;
  top: 53%;
  left:22%;
  z-index: -1;
}
.seo-work-progress .single-work-list:nth-child(even):before {
  content: url(assets/images/shape/line-2.svg);
  position: absolute;
  top: 35%;
  right:16%;
  z-index: -1;
}
.seo-work-progress .single-work-list:last-child:before {display: none !important;}
.seo-work-progress .single-work-list:last-child {padding-bottom: 0;}
.seo-work-progress .single-work-list .text-wrapper {
  position: relative;
  padding-left: 90px;
}
.seo-work-progress .single-work-list .text-wrapper .icon-box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top:11px;
  left:0;
}
.seo-work-progress .single-work-list .text-wrapper .icon-box img {
  margin: 0 auto;
  position: relative;
  top:50%;
  transform: translateY(-50%);
}
.seo-work-progress .single-work-list .text-wrapper .icon-box:before {
  content: '';
  top:-10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  z-index: -1;
  position: absolute;
  border-radius: 50%;
  -webkit-animation: hvr-ripple-out-two 2s linear infinite;
  animation: hvr-ripple-out-two 2s linear infinite;
}
.seo-work-progress .single-work-list:nth-child(1) .text-wrapper .icon-box {background: #a16fff;}
.seo-work-progress .single-work-list:nth-child(1) .text-wrapper .icon-box:before {background: #bc99fd;}

.seo-work-progress .single-work-list:nth-child(2) .text-wrapper .icon-box {background: #ff6464;}
.seo-work-progress .single-work-list:nth-child(2) .text-wrapper .icon-box:before {background: #ff8888;}

.seo-work-progress .single-work-list:nth-child(3) .text-wrapper .icon-box {background: #01e9eb;}
.seo-work-progress .single-work-list:nth-child(3) .text-wrapper .icon-box:before {background: #49fcfe;}
.seo-work-progress .single-work-list .text-wrapper .title {
  font-size: 48px;
  line-height: 60px;
  padding-bottom: 28px;
  text-decoration: underline;
}
.seo-work-progress .single-work-list .text-wrapper p {
  font-size: 20px;
  line-height: 32px;
  color: rgba(87,102,126,0.8);
  padding-bottom: 15px;
}
.seo-work-progress .single-work-list .text-wrapper ul {padding-top: 10px;}
.seo-work-progress .single-work-list .text-wrapper ul li {
  font-size: 18px;
  color: rgba(87,102,126,0.8);
  line-height: 32px;
  position: relative;
  padding-bottom: 22px;
}
.seo-work-progress .single-work-list .text-wrapper ul li:before {
  content: "\f107";
  font-family: "Flaticon";
  font-size: 18px;
  color: #ff6400;
  position: absolute;
  left:-32px;
  top:0;
  line-height: 32px;
}
.seo-work-progress .single-work-list .img-box {position: relative;z-index: 1;}
.seo-work-progress .single-work-list:nth-child(even) .img-box img {float: right;}
/*-------------------------- Home Portfolio ---------------------*/
.home-portfo {background: #1b1d3d;color: #fff;}
.section-portfo {
  position: relative;
  border-bottom: 1px solid #31345e;
  margin-bottom: 190px;
}
.section-portfo .section-num {
  position: absolute;
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-size: 180px;
  color: #26294f;
  top:60px;
  right: 75px;
  z-index: -1;
}
.section-portfo .section-num:before {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  right: -75px;
  top:50%;
  margin-top: -1px;
  background: #e1a34c;
  transition: width 1s ease-out;
  transition-delay: 0.9s
}
.section-portfo .section-num span {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}
.section-portfo .section-num span:first-child{transition-delay: 0.1s}
.section-portfo .section-num span:last-child{transition-delay: 0.3s}
.section-portfo .section-num.show-pr span {opacity: 1;}
.section-portfo .section-num.show-pr:before {width: 60px;}
/*-------------------- About Me / Portfolio --------------------*/
.about-me-portfo {padding-bottom: 240px;}
.about-me-portfo .inner-content {
  position: relative;
  z-index: 1;
  padding-left: 330px;
}
.about-me-portfo .inner-content .profile-tab {
  margin: -25px 0 0;
  padding-left: 240px;
  position: relative;
}
.about-me-portfo .inner-content .profile-tab .nav-tabs {border: none;margin-bottom: 55px;position: relative;}
.about-me-portfo .inner-content .profile-tab .nav-tabs:before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: #fff;
  top:31px;
  left: -240px;
  transition: width 1.5s ease-in-out;
}
.about-me-portfo .inner-content .profile-tab .nav-tabs.show-pr:before {width: 200px;}
.about-me-portfo .inner-content .profile-tab .nav-tabs .nav-item {margin: 0;}
.about-me-portfo .inner-content .profile-tab .nav-tabs .nav-link {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  margin: 15px 25px 0 25px;
  padding: 0;
}
.about-me-portfo .inner-content .profile-tab .nav-tabs .nav-item:first-child .nav-link {margin-left: 0;}
.about-me-portfo .inner-content .profile-tab .nav-tabs .nav-item:last-child .nav-link {margin-right: 0;}
.about-me-portfo .inner-content .profile-tab .nav-tabs .nav-item.show .nav-link,
.about-me-portfo .inner-content .profile-tab .nav-tabs .nav-link.active {color: #e1a34c;}
.about-me-portfo .inner-content .profile-tab .tab-content p {
  font-size: 20px;
  line-height: 40px;
  color: #83859b;
}
.about-me-portfo .inner-content .profile-tab .tab-content p span {color: #fff;text-decoration: underline;}
.about-me-portfo .inner-content .profile-tab .tab-content p b {
  font-weight: normal;
  font-size: 22px;
  color: #fff;
}
.about-me-portfo .inner-content .profile-tab .tab-content .tag {
  font-size: 20px;
  color: #83859b;
  padding: 15px 0 30px;
}
.about-me-portfo .inner-content .profile-tab .tab-content ul {list-style-type: disc;}
.about-me-portfo .inner-content .profile-tab .tab-content ul li {
  font-size: 18px;
  line-height: 28px;
  color: #83859b;
  padding-bottom: 15px;
  margin-left: 18px;
}
.about-me-portfo .inner-content .profile-tab .skill-list {padding-top: 25px;}
.about-me-portfo .inner-content .profile-tab .skill-list li {font-size: 22px;color: #fff;}
.about-me-portfo .inner-content .profile-tab .skill-list li span {
  font-size: 14px;
  color: #e1a34c;
}
.about-me-portfo .inner-content .button-group {margin-top: 45px;}
.about-me-portfo .inner-content .button-group li {display: inline-block;margin-top: 10px;}
.about-me-portfo .inner-content .button-group li .download-button {
  width: 220px;
  line-height: 52px;
  text-align: center;
  border:1px solid #e1a34c;
  color: #e1a34c;
  font-size: 22px;
  margin-right: 20px;
}
.about-me-portfo .inner-content .button-group li .hire-button {
  width: 180px;
  line-height: 52px;
  text-align: center;
  border:1px solid rgba(255,255,255,0.2);
  color: #fff;
  font-size: 22px;
  margin-right: 20px;
}
.about-me-portfo .inner-content .button-group li .hire-button:hover,
.about-me-portfo .inner-content .button-group li .download-button:hover {
  background: #e1a34c;
  border:1px solid #e1a34c;
  color: #fff;
}
.about-me-portfo .inner-content .img-box {
  position: absolute;
  top:170px;
  left:0;
  z-index: -1;
  width: 38%;
}
.about-me-portfo .inner-content .img-box:before {
  content: '';
  width: 1px;
  height: 0;
  background: #e1a34c;
  position: absolute;
  top:-95px;
  left: -115px;
  transition: all 1.5s ease-out;
}
.about-me-portfo .inner-content .img-box:after {
  content: '';
  width: 0;
  height: 1px;
  background: #e1a34c;
  position: absolute;
  top:-95px;
  left: -115px;
  transition: all 1.5s ease-out;
}
.about-me-portfo .inner-content .img-box.show-pr:before {height: 425px;}
.about-me-portfo .inner-content .img-box.show-pr:after {width: 350px;}

/*-------------------------- My SKill Section/Portfolio ----------------------*/
.skill-section-portfo {padding-bottom: 225px;}
.skill-section-portfo:before {
  content: '';
  background: -webkit-radial-gradient( 50% 50%, circle closest-side, rgb(35,38,91) 0%, rgb(27,29,61) 100%);
  position: absolute;
  width: 2366px;
  height: 2366px;
  border-radius: 50%;
  left:50%;
  top:-500px;
  transform: translateX(-50%);
  z-index: -1;
}
.skill-section-portfo .skill-bar {padding: 225px 0 0px;}
.skill-section-portfo .skill-bar .progress {
  height:5px;
  width:100%;
  background: rgba(255,255,255,0.1);
  overflow: visible;
  position: relative;
  margin: 0 0 35px 0;
  border-radius: 3px;
}
.skill-section-portfo .skill-bar .progress-bar {
  position: absolute;
  width: 0;
  top:0;
  left:0;
  height: 100%;
  background: #e1a34c;
  border-radius: 3px;
  transition: all 3s ease-in-out;
}
.skill-section-portfo .skill-bar .progress-bar .percent-text {
  font-family: 'CircularStdmed';
  font-size: 72px;
  color: #fff;
  position: absolute;
  top:-127px;
  left:0;
}
.skill-section-portfo .skill-bar .skill-title {
  font-size: 30px;
  color: #fff;
  padding-bottom: 3px;
}
.skill-section-portfo .skill-bar .category {
  font-size: 17px;
  text-transform: uppercase;
  color: #e1a34c;
}
/*---------------------- Our Service/Portfolio -------------------*/
.our-service-portfo {padding-bottom: 200px;}
.our-service-portfo .title-wrapper {margin-bottom: 60px;}
.our-service-portfo .title-wrapper p {
  font-size: 24px;
  color: rgba(255,255,255,0.8);
  margin: -30px 0 0;
}
.our-service-portfo .service-block {
  background: #202245;
  position: relative;
  padding: 50px 80px 40px 70px;
  margin-top: 70px;
  z-index: 1;
}
.our-service-portfo .service-block .title a {
  font-size: 24px;
  color: #fff;
  line-height: 32px;
  display: block;
  margin: 28px 80px 25px 0;
}
.our-service-portfo .service-block p {
  font-size: 18px;
  line-height: 32px;
  color: rgba(255,255,255,0.6);
}
.our-service-portfo .service-block .read-more {
  font-size: 35px;
  color: #fff;
  margin-top: 20px;
}
.our-service-portfo .service-block:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  border-left:1px solid #e1a34c;
  border-right:1px solid #e1a34c;
  background: #1b1d3d;
  z-index: -1;
  transform: scale(1,0);
}
.our-service-portfo .service-block:after {
  content: '';
  height: 100%;
  position: absolute;
  top:0;
  left:1px;
  right: 1px;
  border-top:1px solid #e1a34c;
  border-bottom:1px solid #e1a34c;
  background: #1b1d3d;
  z-index: -1;
  transform: scale(0,1);
}
.our-service-portfo .service-block:hover:before,
.our-service-portfo .service-block:hover:after {transform: scale(1,1);}
.our-service-portfo .service-block:hover .read-more,
.our-service-portfo .service-block:hover .title a {color: #e1a34c;}
/*-------------------- Our Project/Portfolio ---------------*/
.our-project-portfo.section-portfo {border-bottom: none; padding-bottom: 90px;}
.our-project-portfo .isotop-menu-wrapper {
  position: relative;
  margin: 5px 0 60px;
  padding-left: 190px;
}
.our-project-portfo .isotop-menu-wrapper:before {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  top:17px;
  left:0;
  background: #e1a34c;
  transition: width 1s ease-out;
}
.our-project-portfo .isotop-menu-wrapper.show-pr:before {width: 150px;}
.our-project-portfo .isotop-menu-wrapper li {
  display: inline-block;
  font-size: 24px;
  color: #ffffff;
  margin-right: 65px;
  cursor: pointer;
}
.our-project-portfo .isotop-menu-wrapper li.is-checked {color:#e1a34c; }
.our-project-portfo .text-content {position: relative;margin-bottom: 120px;}
.our-project-portfo .text-content p {
  font-size: 20px;
  line-height: 38px;
  color: rgba(255,255,255,0.6);
  width: 70%;
  padding-left: 190px;
}
.our-project-portfo .text-content .gallery-button {
  width: 190px;
  line-height: 55px;
  font-size: 20px;
  text-align: center;
  color: #fff;
  background: #e1a34c;
  position: absolute;
  right: 0;
  top:8px;
}
.our-project-portfo .text-content .gallery-button:hover {background: #fff;color: #e1a34c;}
.our-project-portfo #isotop-gallery-wrapper .isotop-item,
.our-project-portfo #isotop-gallery-wrapper .grid-sizer {
  width: 25%;
  padding: 0 20px;
  margin-top: 35px;
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item .effect-zoe {
  position: relative !important;
  overflow: hidden;
  background: -webkit-radial-gradient( 50% 50%, circle closest-side, rgb(35,38,91) 0%, rgb(27,29,61) 100%);
  text-align: center;
  margin: 0
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item .effect-zoe img {
  position: relative;
  display: block;
  width: 100%;
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover .effect-zoe img {
  transform: scale3d(1.1,1.1,1);
  opacity: 0.4;
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item .effect-zoe .inner-caption {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 20px 25px;
  text-transform: uppercase;
  top: auto;
  bottom: 0;
  height: auto;
  background: #fff;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
  opacity: 0;
  visibility: hidden;
}

.our-project-portfo #isotop-gallery-wrapper .isotop-item h2 {font-size: 20px;float: left;}
.our-project-portfo #isotop-gallery-wrapper .isotop-item h2 span {font-weight: bold;}
.our-project-portfo #isotop-gallery-wrapper .isotop-item h2 a {color: #233D63;}
.our-project-portfo #isotop-gallery-wrapper .isotop-item p.icon-links a {
  float: right;
  color: #3c4a50;
  font-size: 25px;
  margin-left: 18px;
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover p.icon-links a:hover,
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover p.icon-links a:focus {
  color: #e1a34c;
}

.our-project-portfo #isotop-gallery-wrapper .isotop-item p.description {
  position: absolute;
  width: 100%;
  left:0;
  bottom: 100px;
  padding: 0 0 70px;
  color: #fff;
  text-transform: none;
  opacity: 0;
  font-size: 95%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden; /* Fix for Chrome 37.0.2062.120 (Mac) */
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item h2,
.our-project-portfo #isotop-gallery-wrapper .isotop-item p.icon-links a {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-transform: translate3d(0,200%,0);
  transform: translate3d(0,200%,0);
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover p.description {opacity: 1;}

.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover .inner-caption,
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover h2,
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover p.icon-links a {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  opacity: 1;
  visibility: visible;
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover h2 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover p.icon-links a:nth-child(2) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.our-project-portfo #isotop-gallery-wrapper .isotop-item:hover p.icon-links a:first-child {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
/*---------------------- Footer/Portfolio -------------------*/
.portfo-footer {
  position: relative;
  background: linear-gradient( -180deg, rgb(27,29,61) 0%, rgb(29,31,71) 100%);
  padding: 270px 0 0;
}
.portfo-footer:before {
  content: '';
  position: absolute;
  width: 3px;
  height: 182px;
  border-radius: 8px;
  background: #e1a34c;
  top:-91px;
  left:50%;
  transform: translateX(-50%) scale(1,0);
  transition: all 1s ease-out;
}
.portfo-footer.show-pr:before {
  transform: translateX(-50%) scale(1,1);
}
.portfo-footer .round-shape {
  position: absolute;
  left:0;
  top:-45px;
}
.portfo-footer .theme-title-one {margin-bottom: 118px;}
.portfo-footer .theme-title-one:before {
  content: url(assets/images/icon/icon22.svg);
  position: absolute;
  top:-32px;
  left:50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 1s ease-in-out;
}
.portfo-footer .theme-title-one.show-pr:before {
  top:-72px;
  opacity: 1;
}
.portfo-footer .theme-title-one .upper-title {color: #e1a34c;}
.portfo-footer .theme-title-one .main-title {color: #fff;}
.portfo-footer form {
  max-width: 840px;
  padding: 0 15px;
  margin: 0 auto;
}
.portfo-footer form .row {margin: 0 -25px;}
.portfo-footer form .row [class="col-"] {padding: 0 25px;}
.portfo-footer form .input-group {position: relative;z-index: 1;}
.portfo-footer form .input-group input {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(255,255,255,0.25);
  background: transparent;
  height: 53px;
  font-size: 18px;
  color: #fff;
  position: relative;
  margin-bottom: 75px;
}
.portfo-footer form .input-group textarea {
  width: 100%;
  max-width: 100%;
  height: 160px;
  max-height: 160px;
  resize:none;
  border: none;
  border-bottom: 1px solid rgba(255,255,255,0.25);
  background: transparent;
  font-size: 18px;
  color: #fff;
  padding-top: 12px;
}
.portfo-footer form .input-group label {
  font-weight: normal;
  font-size: 18px;
  color: rgba(255,255,255,0.3);
  line-height: 53px;
  position: absolute;
  left:0;
  top:0;
  z-index: -1;
  margin: 0;
}
.portfo-footer form .input-group input:focus ~ label,
.portfo-footer form .input-group input:valid ~ label,
.portfo-footer form .input-group textarea:focus ~ label,
.portfo-footer form .input-group textarea:valid ~ label {top: -35px;}
.portfo-footer form .input-group input:focus,
.portfo-footer form .input-group textarea:focus {border-bottom-color: #fff;}
.portfo-footer form button {
  float: right;
  margin-top: 40px;
  width: 185px;
  line-height: 48px;
  border: 1px solid #e1a34c;
  background: transparent;
  color: #e1a34c;
  font-size: 20px;
}
.portfo-footer form button:hover {
  background: #e1a34c;;
  color: #fff;
}
.portfo-footer .copyright-text {
  text-align: center;
  padding: 130px 0 50px;
}
.portfo-footer .copyright-text .social-icon li {display: inline-block;}
.portfo-footer .copyright-text .social-icon li a {
  width: 45px;
  height: 45px;
  background: rgba(255,255,255,0.05);
  border-radius: 50%;
  line-height: 45px;
  color: rgba(255,255,255,0.2);
  font-size: 22px;
  display: block;
  margin: 0 6px;
}
.portfo-footer .copyright-text .social-icon li a:hover {background: #e1a34c;color: #fff;}
.portfo-footer .copyright-text p {
  font-size: 20px;
  color: rgba(255,255,255,0.6);
  padding-top: 45px;
}
/*---------------------- Trsuted Partners/ SASS --------------------*/
.trusted-partner {margin-bottom: 130px;}
.trusted-partner .title {
  font-size: 18px;
  color: #878787;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 100px;
}
.trusted-partner .title span {font-size: 22px;color: #244574;}
.partner-slider {text-align: center;}
.partner-slider a {
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}
.partner-slider a:hover,.partner-slider .center a {opacity: 1;transform: scale(1.1);}
/*----------------------- Our Feature/SASS ---------------------*/
.our-feature-sass {
  background: url(assets/images/shape/bg3.png) no-repeat top 81% right;
  padding: 0 0 20px;
  position: relative;
  z-index: 1;
}
.our-feature-sass .down-arrow {
  display: block;
  width: 90px;
  height: 90px;
  box-shadow: 0px 15px 20px 0px rgba(209, 218, 235, 0.35);
  border-radius: 50%;
  margin: 0 auto 405px;
  line-height: 90px;
  text-align: center;
  color: #7494b5;
  font-size: 35px;
}
.our-feature-sass .down-arrow span {
  display: block;
  transform: rotate(-90deg);
}
.our-feature-sass .down-arrow:hover {color: #fff;background: #9a5be8;}
.our-feature-sass .single-feature-block {position: relative; margin-bottom: 330px;}
.our-feature-sass .single-feature-block:last-child {margin: 0;}
.our-feature-sass .single-feature-block  .text-box {max-width: 550px;}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(odd) .text-box {float: right;}
.our-feature-sass .single-feature-block  .text-box .theme-title-one .main-title .line {display: inline-block;position: relative;}
.our-feature-sass .single-feature-block  .text-box .theme-title-one .main-title .line:before {
  content: '';
  width: 0;
  height: 3px;
  background: #233D63;
  border-radius: 5px;
  position: absolute;
  right: -105px;
  bottom: 10px;
  transition: all 1s ease-out;
}
.our-feature-sass .single-feature-block  .text-box .theme-title-one.show-pr .main-title .line:before {width: 100px;}
.our-feature-sass .single-feature-block  .text-box p {
  font-size: 18px;
  line-height: 32px;
  padding: 40px 0 38px;
}
.our-feature-sass .single-feature-block  .text-box ul li {
  font-size: 17px;
  line-height: 38px;
  color: #76797e;
  padding-left: 30px;
  position: relative;
}
.our-feature-sass .single-feature-block  .text-box ul li:before {
  content: "\f107";
  font-family: Flaticon;
  font-size: 15px;
  color: #ff6400;
  position: absolute;
  top:0;
  left:0;
  line-height: 38px;
}
.our-feature-sass .single-feature-block  .text-box .read-more {
  width: 162px;
  line-height: 50px;
  border-radius: 4px;
  border:1px solid #e9e9e9;
  text-align: center;
  color: rgba(48,56,88,0.4);
  margin-top: 55px;
}
.our-feature-sass .single-feature-block  .text-box .read-more:hover {
  color: #fff;
  background: #f569f8;
  border-color:#f569f8;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(odd) .img-box {
  position: absolute;
  top:-35px;
  left:-52px;
  z-index: -1;
  width: 43%;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(odd) .img-box .bg-shape {
  position: absolute;
  left:100px;
  top:20%;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(odd) .img-box .screen-one {
  position: absolute;
  top:-50px;
  left:10px;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(odd) .img-box .screen-two {
  position: absolute;
  top:30px;
  left:42%;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(odd) .img-box .screen-three {
  position: absolute;
  top:42%;
  left:12%;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(odd) .img-box .screen-four {
  position: absolute;
  top:59%;
  left:55%;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(even) .img-box {
  position: absolute;
  right: 0;
  top:-80px;
  z-index: -1;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(even) .img-box .main-shape {
  position: absolute;
  right: 0;
  top:-100px;
}
.our-feature-sass .feature-wrapper .single-feature-block:nth-child(even) .img-box .screen-two {
  position: absolute;
  right: 45px;
  top:-130px;
}
.our-feature-sass .section-shape-one {
  width: 8px;
  height: 8px;
  background: #fce3ba;
  border-radius: 50%;
  position: absolute;
  left:8%;
  top:11%;
  animation: animationFramesOne 15s alternate infinite linear;
}
.our-feature-sass .section-shape-two {
  position: absolute;
  right: -356px;
  top:-170px;
  opacity: 0.7;
  animation: rotatedHalfTwo 15s alternate infinite linear;
  z-index: -1;
}
.our-feature-sass .section-shape-two:before {
  content: url(assets/images/shape/shape-19.svg);
  position: absolute;
  top:50%;
  left:50px; 
}
.our-feature-sass .section-shape-three {
  position: absolute;
  left: -400px;
  top:55%;
  opacity: 0.6;
}
.our-feature-sass .section-shape-four {
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid #ffe49f;
  border-radius: 50%;
  left: 27%;
  top:65%;
  animation: animationFramesOne 12s alternate infinite linear;
}
.our-feature-sass .section-shape-five {
  position: absolute;
  top:62%;
  left:46%;
  animation: rotatedHalfTwo 15s alternate infinite linear;
}
.our-feature-sass .section-shape-six {
  position: absolute;
  top:60%;
  right: 33%;
  animation: animationFramesThree 25s alternate infinite linear;
}
.our-feature-sass .section-shape-seven {
  position: absolute;
  bottom: -70px;
  left:50%;
  animation: animationFramesThree 25s alternate infinite linear;
}
.our-feature-sass .section-shape-eight {
  position: absolute;
  right: 150px;
  bottom: 100px;
  animation: rotatedHalfTwo 10s alternate infinite linear;
}
/*--------------------- Our Feature/APP --------------------------*/
.our-feature-app {
  position: relative;
  padding: 310px 0 250px;
}
.our-feature-app .single-feature-box {padding-bottom: 315px; position: relative;}
.our-feature-app .single-feature-box:last-child {padding: 0;}
.our-feature-app .single-feature-box:nth-child(1):before {
  content: url(assets/images/shape/line-3.svg);
  position: absolute;
  top:53%;
  left:81px;
}
.our-feature-app .single-feature-box:nth-child(2):before {
  content: url(assets/images/shape/line-4.svg);
  position: absolute;
  top:63%;
  left: 80px;
}
.our-feature-app .text-wrapper .title {
  font-size: 46px;
  line-height: 55px;
  color: #3e3e3e;
  padding: 40px 0 30px;
}
.our-feature-app .text-wrapper p {
  font-size: 18px;
  line-height: 30px;
  color: #8c929d;
}
.our-feature-app .text-wrapper .sub-heading {
  font-size: 21px;
  color: #57667e;
  padding-bottom: 30px;
}
.our-feature-app .text-wrapper ul li {
  font-size: 17px;
  line-height: 38px;
  color: rgba(87,102,126,0.7);
  position: relative;
  padding-left: 35px;
}
.our-feature-app .text-wrapper ul li:before {
  content: "\f107";
  font-family: "Flaticon";
  position: absolute;
  line-height: 30px;
  font-size: 16px;
  left:0;
  top:0;
  color: #ff6400;
}
.our-feature-app .text-wrapper .explore-button {
  width: 175px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  border-radius: 3px;
  margin-top: 38px;
}
.our-feature-app .single-feature-box:nth-child(1) .text-wrapper .explore-button {color: #ead24c;background: #fef9da;}
.our-feature-app .single-feature-box:nth-child(1) .text-wrapper .explore-button:hover {background: #ead24c;color: #fef9da;}
.our-feature-app .single-feature-box:nth-child(2) .text-wrapper .explore-button {color: #4ebef9;background: rgba(30,193,254,0.11);}
.our-feature-app .single-feature-box:nth-child(2) .text-wrapper .explore-button:hover {background: #4ebef9; color: #e6f8ff;}
.our-feature-app .single-feature-box:nth-child(3) .text-wrapper .explore-button {color: #ff8aa0;background: rgba(255,61,92,0.11);}
.our-feature-app .single-feature-box:nth-child(3) .text-wrapper .explore-button:hover {background: #ff8aa0;color: #ffeaed;}
.our-feature-app .single-feature-box:nth-child(1) .text-wrapper {margin-top: 165px;}
.our-feature-app .single-feature-box:nth-child(3) .text-wrapper {margin-top: 60px;}
.our-feature-app .feature-img-box {position: relative;padding-right: 90px;}
.our-feature-app .feature-img-box .feature-offer-box {
  padding: 25px;
  margin-bottom: 45px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 25px 50px 0px rgba(78, 81, 144, 0.07);
  min-height: 230px;
  max-height: 230px;
}
.our-feature-app .feature-img-box .feature-offer-box .icon-box {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-left: 35px;
}
.our-feature-app .feature-img-box .support-feature .icon-box {background: linear-gradient( 120deg, rgb(249,154,120) 0%, rgb(255,83,140) 100%);}
.our-feature-app .feature-img-box .price-feature .icon-box {background: linear-gradient( 120deg, rgb(108,71,218) 0%, rgb(224,129,255) 100%);}
.our-feature-app .feature-img-box .access-feature .icon-box {background: linear-gradient( 120deg, rgb(81,236,112) 0%, rgb(36,189,155) 100%);}

.our-feature-app .feature-img-box .feature-offer-box .icon-box img {
  margin: 0 auto;
  position: relative;
  top:50%;
  transform: translateY(-50%);
  width: 60%;
}
/* .our-feature-app .feature-img-box .price-feature .icon-box img {width: 22px;} */
.our-feature-app .feature-img-box .feature-offer-box .title {
  font-size: 16px;
  color: #4e4e4e;
  padding-top: 25px;
  text-align: center;
}
.our-feature-app .feature-img-box .feature-offer-box p {color: #8c929d;}

/* .our-feature-app .feature-img-box .support-feature {margin-top: 180px;} */

.our-feature-app .feature-img-box .feature-offer-box .icon-box:before,
.our-feature-app .feature-img-box .feature-offer-box .icon-box:after {
  content: '';
  position: absolute;
  top:4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 50%;
  background: linear-gradient( -51deg, rgb(249,154,120) 0%, rgb(136,52,204) 20%, rgb(55,189,209) 42%, rgb(212,234,62) 62%, rgb(248,102,157) 83%, rgb(252,93,149) 91%, rgb(255,83,140) 100%);
  background-size: 400%;
  z-index: -1;
  animation: gradientBG 10s infinite linear alternate;
  opacity: 0.65;
}
.our-feature-app .feature-img-box .feature-offer-box .icon-box:after {filter: blur(13px);}
.our-feature-app .feature-img-box .shape-one {
  position: absolute;
  top:34%;
  left: -12%;
  animation: animationFramesOne 15s infinite linear alternate;
}
.our-feature-app .feature-img-box .shape-two {
  position: absolute;
  top:12%;
  left: 15%;
  animation: animationFramesFive 18s infinite linear alternate;
}
.our-feature-app .feature-img-box .shape-three {
  position: absolute;
  top:0%;
  left: 24%;
  animation: animationFramesTwo 15s infinite linear alternate;
}
.our-feature-app .feature-img-box .shape-four {
  position: absolute;
  top:19%;
  right: 28%;
  animation: animationFramesFive 20s infinite linear alternate;
}
.our-feature-app .feature-img-box .shape-five {
  position: absolute;
  top:12%;
  right: 2%;
  animation: animationFramesFive 18s infinite linear alternate;
}

.our-feature-app .single-feature-box:nth-child(1) .feature-img-box:before {
  content:url(assets/images/shape/shape-44.svg);
  position: absolute;
  top:34px;
  right: 55px;
}
.our-feature-app .single-feature-box:nth-child(2) .feature-img-box:before {
  content:url(assets/images/shape/shape-50.svg);
  position: absolute;
  top:-24px;
  right: -12px;
}
.our-feature-app .single-feature-box:nth-child(3) .feature-img-box:before {
  content:url(assets/images/shape/shape-51.svg);
  position: absolute;
  top: -83px;
  left: -40px;
  z-index: -1;
}
.our-feature-app .single-feature-box .access-screen-holder .screen {
  float: right;
  position: relative;
}
.our-feature-app .single-feature-box .screen-mockup {position: relative;}
.our-feature-app .single-feature-box .screen-mockup .mockup {margin: 0 auto;}
.our-feature-app .single-feature-box .screen-mockup .screen-one {
  position: absolute;
  right: -84px;
  top:16%;
}
.our-feature-app .single-feature-box .screen-mockup .screen-two {
  position: absolute;
  left: -38px;
  top:43%;
}
/*--------------------- Our Pricing/SASS ---------------------*/
.sass-our-pricing {
  position: relative;
  padding: 260px 0 245px;
}
.sass-our-pricing .section-shape-one {
  position: absolute;
  right: -356px;
  top: 0;
  opacity: 0.7;
  animation: rotatedHalfTwo 15s alternate infinite linear;
  z-index: -1;
}
.sass-our-pricing .section-shape-one:before {
  content: url(./assets/images/shape/shape-23.svg);
  position: absolute;
  top:50%;
  left:calc(21% + 4px);
}
.sass-our-pricing .section-shape-two {
  position: absolute;
  left:-25px;
  top:13%;
  z-index: -1;
}
.sass-our-pricing .section-shape-three {
  position: absolute;
  left:17%;
  top:23%;
  animation: animationFramesFour 20s alternate infinite linear;
}
.sass-our-pricing .section-shape-four {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #937fff;
  top:50%;
  left:5%;
  animation: animationFramesTwo 13s infinite linear;
}
.sass-our-pricing .section-shape-five {
  position: absolute;
  top:51%;
  right:5%;
  animation: animationFramesTwo 13s infinite linear;
}
.sass-our-pricing .section-shape-six {
  position: absolute;
  right: -75px;
  bottom: 170px;
}
.sass-our-pricing .icon-box .icon {height: 31px;}
.sass-our-pricing .theme-title-one {margin-bottom: 80px;}
.sass-our-pricing .row [class*="col-"] {
  border-left: 1px solid #e0e9ef;
  padding: 0 50px;
}
.sass-our-pricing .row [class*="col-"]:last-child {border-right: 1px solid #e0e9ef;}
.sass-our-pricing .single-pr-table .pr-header {
  text-align: center;
  padding-top: 20px;
  min-height: 180px;
}
.sass-our-pricing .single-pr-table .pr-header .price {font-size: 44px;}
.sass-our-pricing .single-pr-table.free .pr-header .price {color: #ffcc6f;}
.sass-our-pricing .single-pr-table.regular .pr-header .price {color: #06df9e;}
.sass-our-pricing .single-pr-table.plus .pr-header .price {color: #ff6262;}

.sass-our-pricing .single-pr-table .pr-header .title {
  font-size: 19px;
  color: #303858;
  text-transform: uppercase;
  padding-bottom: 7px;
}
.sass-our-pricing .single-pr-table .pr-header .package {font-size: 18px;color: #696d80;}
.sass-our-pricing .single-pr-table .pr-body {
  border-top: 1px dashed #d8dde1;
  padding: 40px 0 30px;
  min-height: 330px;
}
.sass-our-pricing .single-pr-table .pr-body .feature {
  font-family: 'CircularStdmed';
  font-size: 20px;
  color: #303858;
  padding-bottom: 15px;
}
.sass-our-pricing .single-pr-table .pr-body ul li {
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  line-height: 40px;
  color: #818386;
}
.sass-our-pricing .single-pr-table .pr-body ul li:before {
  content: "\f107";
  font-family: Flaticon;
  font-size: 14px;
  position: absolute;
  color: #12d859;
  line-height: 40px;
  left:0;
  top:0;
}
.sass-our-pricing .single-pr-table .upgrade-button {
  display: block;
  text-align: center;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  line-height: 50px;
  font-size: 18px;
  color: #233d63;
  position: relative;
}
.sass-our-pricing .single-pr-table .upgrade-button:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  border-radius: 30px;
  background: linear-gradient( -135deg, rgb(191,108,255) 0%, rgb(144,65,255) 100%);
  transform: scale(0,1);
  z-index: -1;
}
.sass-our-pricing .single-pr-table:hover .upgrade-button {border-color: transparent;color: #fff;}
.sass-our-pricing .single-pr-table:hover .upgrade-button:before {transform: scale(1);}
.sass-our-pricing .single-pr-table .trial-button {
  display: block;
  text-align: center;
  color: #818386;
  margin-top: 32px;
}
.sass-our-pricing .single-pr-table .trial-button:hover {color: rgb(191,108,255);}
.sass-our-pricing .single-pr-table .pr-footer {padding-bottom: 70px;}
.sass-our-pricing .pricing-tab-menu .nav-wrapper {border:none;padding: 2px; background: linear-gradient( -135deg, rgb(191,108,255) 0%, rgb(144,65,255) 100%);}
.sass-our-pricing .pricing-tab-menu .nav-tabs .nav-item .nav-link {color: #9546ff;background: #fff;}
.sass-our-pricing .pricing-tab-menu .nav-tabs .nav-item .nav-link.active {background: transparent; color: #fff;}
/*-------------------------- FAQ Section/SASS ---------------------*/
.sass-faq-section {position: relative;padding: 150px 0;}
.sass-faq-section .section-shape-one {
  position: absolute;
  left: -356px;
  top: 20px;
  opacity: 0.6;
  z-index: -1;
  animation: rotatedHalfTwo 20s alternate infinite linear;
}
.sass-faq-section .section-shape-one:before {
  content: url(./assets/images/shape/shape-28.svg);
  position: absolute;
  top:50%;
  right:-10px;
}
.sass-faq-section .section-shape-two {
  position: absolute;
  top:69%;
  left:5%;
  animation: animationFramesTwo 13s infinite linear;
}
.sass-faq-section .section-shape-three {
  position: absolute;
  top:23%;
  right:10%;
  animation: animationFramesTwo 20s infinite linear;
}
.sass-faq-section .section-shape-four {
  position: absolute;
  right: -90px;
  bottom: 190px;
}
.sass-faq-section .sub-heading {
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  color: #9f9f9f;
  margin: 25px 0 95px;
}
.sass-faq-section .sub-heading a {color: #303858;text-decoration: underline;}
.sass-faq-section .faq-tab-wrapper .nav-tabs {
  margin: 0 0 95px;
  border: none;
  justify-content: center;
  -webkit-justify-content: center;
}
.sass-faq-section .faq-tab-wrapper .nav-tabs .nav-item {margin:10px 0 0;}
.sass-faq-section .faq-tab-wrapper .nav-tabs .nav-link {
  border: none;
  line-height: 32px;
  border-radius: 3px;
  padding: 0 18px;
  color: #989898;
  font-size: 17px;
  margin: 0 10px;
}
.sass-faq-section .faq-tab-wrapper .nav-tabs li:nth-child(1) .nav-link {background: #f4f3ff;}
.sass-faq-section .faq-tab-wrapper .nav-tabs li:nth-child(2) .nav-link {background: #ffeeee;}
.sass-faq-section .faq-tab-wrapper .nav-tabs li:nth-child(3) .nav-link {background: #f3ffed;}
.sass-faq-section .faq-tab-wrapper .nav-tabs li:nth-child(4) .nav-link {background: #edf3ff;}
.sass-faq-section .faq-tab-wrapper .nav-tabs li:nth-child(5) .nav-link {background: #fdf9e9;}
.sass-faq-section .faq-tab-wrapper .nav-tabs li:nth-child(6) .nav-link {background: #e6fafd;}
.sass-faq-section .faq-tab-wrapper .nav-tabs .nav-item.show .nav-link,
.sass-faq-section .faq-tab-wrapper .nav-tabs .nav-link.active {background: #22d4ab !important;color: #fff;}
.faq-tab-wrapper .faq-panel .panel {
  background: #fff;
  box-shadow: 0px 10px 14px 0px rgba(90, 111, 155, 0.05);
  margin-bottom: 30px;
}
.faq-tab-wrapper .faq-panel .panel .panel-heading .panel-title a {
  display: block;
  padding: 25px 10px 25px 65px;
  font-size: 22px;
  color: #303858;
  position: relative;
}
.faq-tab-wrapper .faq-panel .panel .panel-heading .panel-title a:before {
  content: '+';
  position: absolute;
  left:35px;
  top:50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: #464646;
}
.faq-tab-wrapper .faq-panel .panel .panel-heading.active-panel .panel-title a:before {content: '-';}
.faq-tab-wrapper .faq-panel .panel .panel-body p {
  line-height: 30px;
  padding: 0 90px 38px 65px;
}
.sass-faq-section .down-button {
  display: block;
  width: 50px;
  height: 50px;
  border: 2px solid #22d4ab;
  border-radius: 50%;
  margin: 60px auto 0;
  color: #22d4ab;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
}
.sass-faq-section .down-button:hover {background:#22d4ab; color: #fff; }
/*-------------------- Why Choose Us/APP -------------------------*/
.why-choose-us-app {
  background: linear-gradient( -35deg, rgb(144,42,252) 0%, rgb(52,152,246) 100%);
  position: relative;
  padding: 255px 0 150px;
  overflow: hidden;
  z-index: 5;
}
.why-choose-us-app .bg-shape-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: -1;
  overflow: hidden;
}
.why-choose-us-app .bg-shape-holder .big-round-one {
  position: absolute;
  width: 662px;
  height: 662px;
  background: rgba(255,255,255,0.03);
  top: -346px;
  left: -75px;
  border-radius: 50%;
}
.why-choose-us-app .bg-shape-holder .big-round-two {
  position: absolute;
  width: 1372px;
  height: 1372px;
  background: rgba(255,255,255,0.03);
  top: -689px;
  left: -368px;
  border-radius: 50%;
  z-index: -1;
}
.why-choose-us-app .bg-shape-holder .big-round-three {
  position: absolute;
  width: 2242px;
  height: 2242px;
  background: rgba(255,255,255,0.03);
  top: -1161px;
  left: -779px;
  border-radius: 50%;
  z-index: -3;
}
.why-choose-us-app .text-wrapper {max-width: 500px;float: right;}
.why-choose-us-app .text-wrapper .title-box {
  display: inline-block;
  line-height: 35px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  padding: 0 30px;
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 3px;
}
.why-choose-us-app .text-wrapper .bottom-title {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  padding: 45px 0 35px;
}
.why-choose-us-app .text-wrapper ul li {
  color:#fff;
  font-size: 20px;
  line-height: 32px;
  position: relative;
  padding: 0 0 15px 40px;
}
.why-choose-us-app .text-wrapper ul li:before {
  content: "\f103";
  font-family: "Flaticon";
  position: absolute;
  top:0;
  left:0;
  line-height: 32px;
  font-size: 24px;
}
.why-choose-us-app .text-wrapper ul {
  border-bottom: 1px solid rgba(216,216,216,0.2);
  padding-bottom: 48px;
  margin-bottom: 55px;
}
.why-choose-us-app .text-wrapper .director-speech {position: relative;}
.why-choose-us-app .text-wrapper .director-speech .d-img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  float: left;
}
.why-choose-us-app .text-wrapper .director-speech .bio-block {float: left;padding-left: 25px;}
.why-choose-us-app .text-wrapper .director-speech .bio-block .name {
  font-size: 20px;
  color: #fff;
  padding: 2px 0;
}
.why-choose-us-app .text-wrapper .director-speech .bio-block span {
  font-size: 18px;
  color: #fff;
  font-style: italic;
}
.why-choose-us-app .text-wrapper .director-speech .sign {
  position: absolute;
  right: 0;
  top:-32px;
}
.why-choose-us-app .screen-preview {
  position: absolute;
  bottom: -1px;
  left:0;
  width: 50%;
}
/*--------------------- Newsletter Section -------------------*/
.newsletter-section {
  position: relative;
  padding: 140px 0 0;
}
.newsletter-section .theme-title-one.upper-bar:before {background: #7491ff;}
.newsletter-section .main-wrapper {border-bottom: 1px solid #e8e8e8;padding-bottom: 120px;}
.newsletter-section .main-wrapper form {
  max-width: 57%;
  margin: 50px auto 0;
  position: relative;
}
.newsletter-section .main-wrapper form input {
  width: 100%;
  height: 65px;
  border: 1px solid rgba(186,186,186,0.3);
  border-radius: 38px;
  padding: 0 105px 0 40px;
  font-size: 18px;
  color: rgba(62,62,62,0.4);
}
.newsletter-section .main-wrapper form button {
  position: absolute;
  top:0;
  right: 0;
  height: 65px;
  width: 100px;
  border-radius: 0 38px 38px 0;
  color: #fff;
  font-size: 32px;
  background: #6d5cf8;
}
.newsletter-section .main-wrapper form ::placeholder {color: rgba(62,62,62,0.4);opacity: 1;}
.newsletter-section .main-wrapper form :-ms-input-placeholder { color: rgba(62,62,62,0.4);}
.newsletter-section .main-wrapper form ::-ms-input-placeholder {color: rgba(62,62,62,0.4);}
.newsletter-section .subscription-label {text-align: center;margin: 0;display: block;}
.newsletter-section .subscription-label.error {color: #ff4343;}
.newsletter-section .subscription-label.valid {color: #28a745;}

/*----------------------- Theme E-Commerce Menu ----------------------*/
.theme-Ecommerce-menu {
  position: relative;
  padding: 60px 60px 40px;
  z-index: 99;
}
.theme-Ecommerce-menu .left-content ul li,
.theme-Ecommerce-menu .right-content>ul>li {display: inline-block;vertical-align: middle;}
.theme-Ecommerce-menu .left-content ul .menu-button {
  display: block;
  background: transparent;
  position: relative;
  margin: -8px 15px 0 0;
}
.eCommerce-search {width: 300px; position: relative;} 
.eCommerce-search input {
  width: 100%;
  border: none;
  font-style: italic;
  color: rgba(65,65,65,0.33);
  padding-left: 30px;
  background: transparent;
}
.eCommerce-search .icon {
  position: absolute;
  left:0;
  top:0;
  font-size: 20px;
  line-height: 27px;
  color: #2e2e2e;
}
.eCommerce-search ::placeholder { color: rgba(65,65,65,0.33);opacity: 1;}
.eCommerce-search :-ms-input-placeholder { color: rgba(65,65,65,0.33);}
.eCommerce-search ::-ms-input-placeholder { color: rgba(65,65,65,0.33);}
.theme-Ecommerce-menu .right-content .dropdown-toggle:after {display: none;}
.theme-Ecommerce-menu .right-content .dropdown-toggle {background: transparent;}
.theme-Ecommerce-menu .right-content .dropdown-menu {
  background: #fff;
  box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
  border: 1px solid #f8f8f8;
  border-radius: 0;
  margin: 15px -35px 0 0;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .dropdown-toggle {
  height: 27px;
  position: relative;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .dropdown-toggle .item-count {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff3612;
  line-height: 15px;
  text-align: center;
  font-size: 9px;
  color: #fff;
  right: -12px;
  top:-6px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .dropdown-menu {
  padding: 40px 35px;
  min-width: 300px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-img {
  width: 70px;
  height: 80px;
  display: block;
  float: left;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info {
  width: calc(100% - 70px);
  float: left;
  padding-left: 25px;
  position: relative;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .close {
  position: absolute;
  top:13px;
  right: 0;
  font-size: 12px;
  color: rgba(31,31,31,0.9);
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .close:hover {color: #ff3612;}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .name {
  font-size: 18px;
  color: #1f1f1f;
  margin: 5px 0;
  padding-right: 12px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .price {
  font-size: 20px;
  color: #1f1f1f;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .item-info .price .quantity {
  font-size: 17px;
  color: rgba(31,31,31,0.3);
  margin-left: 15px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .selected-item {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .cart-product-list .selected-item:last-child {margin-bottom: 14px;}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .subtotal .title {font-size: 18px;color: #1f1f1f}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .subtotal .total-price {font-size: 20px;color: #1f1f1f}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .subtotal {padding-bottom: 7px;}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a {
  font-family: 'CircularStdmed';
  display: block;
  line-height: 45px;
  border: 1px solid #dbdbdb;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #1f1f1f;
  margin-top: 15px;
}
.theme-Ecommerce-menu .right-content .cart-action-wrapper .button-group a:hover {color: #fff;}
.theme-Ecommerce-menu .right-content .user-profile-action {padding-left: 35px;}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-toggle img {display: inline-block;}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-toggle span {
  font-size: 17px;
  color: #414141;
  vertical-align: middle;
  padding-left: 10px;
  line-height: 16px;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu {
  min-width: 220px;
  padding: 20px 25px 25px;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a {
  font-size: 16px;
  line-height: 42px;
  color: #4b4c51;
  padding-left: 30px;
  position: relative;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a .icon {
  position: absolute;
  left:0;
  top:11px;
}
.theme-Ecommerce-menu .right-content .user-profile-action .dropdown-menu ul li a:hover .icon path {fill: #ff3612;}
/*---------------------- E-Commerce Home Page ------------------------*/
#eCommerce-home {
  height: 100vh;
  position: relative;
}
#eCommerce-home:before {
  content: '';
  position: absolute;
  top:0;
  bottom: 0;
  width: 1px;
  background: #f0f0f0;
  left:50%;
  z-index: -1;
}
#eCommerce-home .main-page-wrapper {height: 100%;position: relative;}
#eCommerce-home .main-page-wrapper:before,
#eCommerce-home .main-page-wrapper:after {
  content: '';
  position: absolute;
  height: 100%;
  top:0;
  width: 16.666666666%;
  border-left:1px solid #f0f0f0;
  border-right:1px solid #f0f0f0;
  z-index: -1;
}
#eCommerce-home .main-page-wrapper:before {left:16.6666666%;}
#eCommerce-home .main-page-wrapper:after {right:16.6666666%;}
/*---------------------- Sidebar Menu ------------------------*/
.eCommerce-side-menu {
  position: fixed;
  top:0;
  bottom: 0;
  left:0;
  width: 360px;
  background: #fff;
  box-shadow: 0px 2px 100px 0px rgba(218, 218, 218, 0.5);
  z-index: 999;
  padding:0 25px 30px 80px;
  opacity: 0;
  transform: scale(0 , 1);
  transform-origin: 0% 0%;
  transition:all 0.5s ease-in-out;
  max-height: 100vh;
  overflow-y: auto;
}
.eCommerce-side-menu.show-menu {
  opacity: 1;
  transform: scale(1);
}
.eCommerce-side-menu .logo-wrapper {
  margin-top: 60px;
  position: relative;
  padding-left: 30px;
}
.eCommerce-side-menu .logo-wrapper .close-button {
  position: absolute;
  top:0;
  left:0;
  background: transparent;
  height: 36px;
}
.eCommerce-side-menu .main-menu-list>ul {margin-top: 70%;padding-bottom: 30px;}
.eCommerce-side-menu .main-menu-list>ul>li>a {
  font-size: 36px;
  line-height: 60px;
  color: #3e3e3e;
  position: relative;
  display: block;
}
.eCommerce-side-menu .main-menu-list>ul>li {
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.7s ease-in-out;
}
.eCommerce-side-menu.show-menu .main-menu-list>ul>li:nth-child(1) {transition-delay: 0.2s;}
.eCommerce-side-menu.show-menu .main-menu-list>ul>li:nth-child(2) {transition-delay: 0.3s;}
.eCommerce-side-menu.show-menu .main-menu-list>ul>li:nth-child(3) {transition-delay: 0.4s;}
.eCommerce-side-menu.show-menu .main-menu-list>ul>li:nth-child(4) {transition-delay: 0.5s;}
.eCommerce-side-menu.show-menu .main-menu-list>ul>li:nth-child(5) {transition-delay: 0.6s;}
.eCommerce-side-menu.show-menu .main-menu-list>ul>li {
  opacity: 1;
  transform: translateX(0);
}
.eCommerce-side-menu .main-menu-list>ul>li>a .expander {
  position: absolute;
  right: 0;
  top:0;
  background: transparent;
  font-size: 15px;
  line-height: 60px;
  color: inherit;
}
.eCommerce-side-menu .main-menu-list>ul .sub-menu {padding: 10px 0 10px 30px;}
.eCommerce-side-menu .main-menu-list>ul .sub-menu a {
  font-size: 24px;
  line-height: 35px;
  color: #b7b7b7;
}
.eCommerce-side-menu .copy-right {font-size: 16px;color: rgba(62,62,62,0.7);margin-top: 100px;}
.main-menu-list ul .sub-menu {
  display: none;
  transition: all 0.3s ease-in-out;
}
.main-menu-list ul .sub-menu.show {display: block;}
.eCommerce-side-menu .eCommerce-search {width: 100%;margin:40px 0; display: none;}
/*--------------------- E-Commerce Hero Section ---------------------*/
.eCommerce-hero-section {position: relative;}
.eCommerce-hero-section:before,
.eCommerce-hero-section:after {
  content: url(./assets/images/shape/shape-54.svg);
  position: absolute;
  z-index: -3;
}
.eCommerce-hero-section:before {right: 0;bottom: 0;}
.eCommerce-hero-section:after {top:4%;right: 35%;}
.eCommerce-hero-section .carousel-indicators {
  position: absolute;
  right: auto;
  left:60px;
  top:30%;
  bottom: auto;
  display: block !important;
  margin: 0;
}
.eCommerce-hero-section .carousel-indicators li {
  width: 3px;
  height: 20px;
  border-radius: 3px;
  background: #e7e7e7;
  margin: 5px 0;
  cursor: pointer;
  border: none;
}
.eCommerce-hero-section .carousel-indicators li.active,
.eCommerce-hero-section .carousel-indicators li:hover {background: #454545;}
.eCommerce-hero-section .carousel-indicators li.active {transform: scale(1,1.2);}
#eCommerce-carousel .inner-item-wrapper {position: relative;padding: 100px 0 90px; z-index: 1;}
#eCommerce-carousel .inner-container {
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 90px;
}
#eCommerce-carousel .inner-item-wrapper .main-title {
  font-family: 'Playfair Display', serif;
  font-size: 130px;
  line-height: 120px;
  color: #19212b;
  width: 35%;
}
#eCommerce-carousel .inner-item-wrapper .price-tag {padding: 25px 0 50px;}
#eCommerce-carousel .inner-item-wrapper .price-tag .current-price {
  font-size: 58px;
  color: #ff4b68;
  font-weight: normal;
  margin-right: 40px;
  display: inline-block;
  animation-delay: 0.5s;
}
#eCommerce-carousel .inner-item-wrapper .price-tag del {
  font-size: 32px;
  color: #d3d3d3;
  display: inline-block;
  animation-delay: 0.5s;
}
#eCommerce-carousel .inner-item-wrapper .button-group li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
}
#eCommerce-carousel .inner-item-wrapper .button-group .shop-now {
  width: 180px;
  line-height: 55px;
  display: inline-block;
  text-align: center;
  font-family: 'CircularStdbld';
  font-size: 14px;
  text-transform: uppercase;
  color: #3c3c3c;
  background: #fff;
  box-shadow: 0px 15px 40px 0px rgba(199, 202, 211, 0.25);
  margin-right: 115px;
  animation-delay: 1s;
}
#eCommerce-carousel .inner-item-wrapper .button-group .shop-now:hover {color: #fff;}
#eCommerce-carousel .inner-item-wrapper .button-group .details-info-button {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  color: #fff;
  font-size: 50px;
  background: #2e2e2e;
  display: block;
  animation-delay: 1s;
}
#eCommerce-carousel .inner-item-wrapper .product-img {
  position: absolute;
  right: 0;
  top:50px;
  z-index: -1;
  animation-delay: 1.2s;
}
#eCommerce-carousel .color-tab {
  text-align: center;
  position: absolute;
  top:100px;
  right: -20px;
  transform: rotate(-90deg);
}
#eCommerce-carousel .color-tab p {
  font-size: 20px;
  color: #343638;
  padding-bottom: 35px;
}
#eCommerce-carousel .color-tab ul li {display: inline-block;}
#eCommerce-carousel .color-tab ul li a {
  display: block;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 5px;
  border-style: solid;
}
#eCommerce-carousel .color-tab ul li:nth-child(1) a{border-color: #9aa2ce;}
#eCommerce-carousel .color-tab ul li:nth-child(2) a{border-color: #00ead0;}
#eCommerce-carousel .color-tab ul li:nth-child(3) a{border-color: #ff4b68;}
#eCommerce-carousel .color-tab ul li:nth-child(1):hover a{background:#9aa2ce }
#eCommerce-carousel .color-tab ul li:nth-child(2):hover a{background: #00ead0;}
#eCommerce-carousel .color-tab ul li:nth-child(3):hover a{background: #ff4b68;}
#eCommerce-carousel .brand-text {
  font-size: 400px;
  font-family: 'CircularStdblck';
  color: #f8f8f8;
  position: absolute;
  right: -100px;
  bottom: 0;
  line-height: 290px;
  z-index: -3;
  animation-delay: 1.2s;
}
.eCommerce-hero-section .social-share {
  position: absolute;
  bottom: 150px;
  left:-40px;
  transform: rotate(-90deg);
}
.eCommerce-hero-section .social-share li {
  display: inline-block;
  font-family: 'CircularStdmed';
  font-size: 16px;
  color: #3c3c3c;
  text-transform: uppercase;
}
.eCommerce-hero-section .social-share li a {
  color: #dbdbdb;
  font-size: 20px;
  margin: 0 10px;
  transform: rotate(90deg);
}
.eCommerce-hero-section .social-share li:first-child {margin-right: 25px;}
.product-details-modal .main-bg-wrapper {
  width: 100%;
  height: 100%;
  padding: 50px 0 100px;
  text-align: center;
}
.product-details-modal .main-bg-wrapper .product-img {margin: 0 auto;max-width: 400px;}
.product-details-modal .main-bg-wrapper .close-button {
  position: fixed;
  top:20px;
  right: 30px;
  background: transparent;
  font-size: 35px;
  color: rgba(0,0,0,0.6);
  font-weight: normal;
}
.product-details-modal .main-bg-wrapper .title {
  font-family: 'Playfair Display', serif;
  font-size: 40px;
  color: #19212b;
  padding: 80px 0 30px;
}
.product-details-modal .main-bg-wrapper p {
  font-size: 18px;
  line-height: 30px;
  color: #5a6473;
  width: 60%;
  margin: 0 auto;
}
.product-details-modal .main-bg-wrapper .cart-button {
  line-height: 45px;
  padding: 0 30px;
  color: #fff;
  border-radius: 3px;
  margin-top: 35px;
  font-size: 16px;
}
.product-details-modal .main-bg-wrapper .next,
.product-details-modal .main-bg-wrapper .prev {
  position: absolute;
  font-size: 35px;
  color: rgba(0,0,0,0.4);
  background: transparent;
  top:50%;
}
.product-details-modal .main-bg-wrapper .next {right: 10px;}
.product-details-modal .main-bg-wrapper .prev {left:10px;}
.product-details-modal .main-bg-wrapper .next:hover,
.product-details-modal .main-bg-wrapper .prev:hover {color: #000;}
/*------------------- Product Filter Area/Sidebar -----------------------*/
.product-filter-area {position: relative;padding-bottom: 40px;}
.product-filter-area .display-item-filter {font-size: 17px;color: rgba(79,79,79,0.7);}
.filter-dropdown-holder .filter-button {
  background: transparent;
  font-size: 20px;
  color: #2e2e2e;
  padding-left: 45px;
  position: relative;
  line-height: 30px;
  margin-right: 55px;
}
.filter-dropdown-holder .filter-button .icon {
  width: 30px;
  height: 30px;
  background: #3a3a3a;
  border-radius: 50%;
  position: absolute;
  left:0;
  top:0;
  padding-top: 8px;
}
.filter-dropdown-holder .filter-button .icon span {
  display: block;
  height: 2px;
  background: #fff;
  margin: 2px auto;
  transition: all 0.3s ease-in-out;
}
.filter-dropdown-holder .filter-button .icon span:nth-child(1){width: 17px;}
.filter-dropdown-holder .filter-button .icon span:nth-child(2){width: 11px;}
.filter-dropdown-holder .filter-button .icon span:nth-child(3){width: 5px;}
.filter-dropdown-holder .filter-button.open .icon span:nth-child(1){width: 5px;}
.filter-dropdown-holder .filter-button.open .icon span:nth-child(2){width: 11px;}
.filter-dropdown-holder .filter-button.open .icon span:nth-child(3){width: 17px;}
.product-filter-area .large-filter-content {
  position: absolute;
  z-index: 1;
  left:0;
  top:70px;
  width: 100%;
  background: #fff;
  border:1px solid #f4f4f4;
  padding: 50px 70px 70px;
  box-shadow: 0px 50px 100px 0px rgba(207, 215, 224, 0.2);
  transform: scale(1,0);
  transform-origin: 0%  0%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.product-filter-area .large-filter-content.show-content {
  opacity: 1;
  transform: scale(1);
}
.theme-sidebar-widget .sidebar-title {
  font-family: 'CircularStdmed';
  font-size: 24px;
  color: #1d1d1d;
  padding-bottom: 30px;
}
.theme-sidebar-widget .list-item {margin-top: -17px;}
.theme-sidebar-widget .list-item li a {
  display: block;
  position: relative;
  line-height: 50px;
  font-size: 18px;
  color: #4e4e4e;
}
.theme-sidebar-widget .list-item li a .expander {
  position: absolute;
  background: transparent;
  line-height: 50px;
  right: 0;
  top:0;
  color: inherit;
  font-size: 14px;
}
.theme-sidebar-widget .list-item li .sub-menu {padding-left: 30px;}
.theme-sidebar-widget .list-item li .sub-menu a {
  font-size: 16px;
  line-height: 31px;
  color: #585858;
}
.theme-sidebar-widget .list-item li .sub-menu a:hover {padding-left: 10px;}
.theme-sidebar-widget .list-item li a span {
  position: absolute;
  right: 0;
  top:0;
  line-height: 50px;
}
.theme-sidebar-widget .list-item.blog-category li a {border-bottom: 1px solid #eaeaea;line-height: 56px;}
.theme-sidebar-widget .list-item.blog-category li a span {line-height: 50px;}
.theme-sidebar-widget .price-ranger .ui-widget-content {
  background: #eeeeee;
  border: none;
  height:5px;
  border-radius: 5px;
  margin-bottom: 22px;
  max-width: 350px;
}
.theme-sidebar-widget .price-ranger .ui-slider-handle {
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(207, 207, 207, 0.61);
}
.theme-sidebar-widget .price-ranger .ui-slider-horizontal .ui-slider-handle {top: -5px;}
.theme-sidebar-widget .price-ranger .ranger-min-max-block li {
  display: inline-block;
  line-height: 30px;
  font-size: 18px;
}
.theme-sidebar-widget .price-ranger .ranger-min-max-block li:first-child {color: #989ca2;}
.theme-sidebar-widget .price-ranger .ranger-min-max-block input {
  width: 50px;
  height: 30px;
  border: none;
  background: transparent;
  text-align: center;
  color: #3e3e3e;
}
.theme-sidebar-widget .size-filter {margin: 0 -8px;}
.theme-sidebar-widget .size-filter li {float: left;padding: 0 8px;}
.theme-sidebar-widget .size-filter li a {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  border:1px solid #dfdfdf;
  line-height: 38px;
  font-size: 15px;
  text-transform: uppercase;
  color: rgba(29,29,29,0.4);
}
.theme-sidebar-widget .size-filter li a:hover {color: #fff;}
.theme-sidebar-widget .color-filter li {display: inline-block;}
.theme-sidebar-widget .color-filter li a {
  display: block;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 5px;
  border-style: solid;
}
.theme-sidebar-widget .color-filter li:nth-child(1) a{border-color: #9aa2ce;}
.theme-sidebar-widget .color-filter li:nth-child(2) a{border-color: #00ead0;}
.theme-sidebar-widget .color-filter li:nth-child(3) a{border-color: #ff4b68;}
.theme-sidebar-widget .color-filter li:nth-child(4) a{border-color: #ffd657;}
.theme-sidebar-widget .color-filter li:nth-child(5) a{border-color: #dc6cff;}
.theme-sidebar-widget .color-filter li:nth-child(6) a{border-color: #57ff86;}
.theme-sidebar-widget .color-filter li:nth-child(1):hover a{background:#9aa2ce }
.theme-sidebar-widget .color-filter li:nth-child(2):hover a{background: #00ead0;}
.theme-sidebar-widget .color-filter li:nth-child(3):hover a{background: #ff4b68;}
.theme-sidebar-widget .color-filter li:nth-child(4):hover a{background: #ffd657;}
.theme-sidebar-widget .color-filter li:nth-child(5):hover a{background: #dc6cff;}
.theme-sidebar-widget .color-filter li:nth-child(6):hover a{background: #57ff86;}
.shop-demo-filter .selectize-input {
  width: 170px;
  line-height: 45px;
  border-radius: 0;
  border: 1px solid #e9e9e9;
  box-shadow: none;
  outline: none;
  padding: 0 15px;
}
.shop-demo-filter .selectize-input input,
.shop-demo-filter .selectize-input .item {font-size: 15px;color: #4f4f4f;}
.shop-demo-filter .selectize-dropdown {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-top: none;
  box-shadow: 0px 50px 100px 0px rgba(229, 232, 235, 0.2);
  cursor: pointer;
}
.shop-demo-filter .selectize-dropdown .option {font-size: 15px;color: #4f4f4f;line-height: 30px;}
.shop-demo-filter .selectize-dropdown .option:hover,
.shop-demo-filter .selectize-dropdown .active {color: #fff;}
.shop-demo-filter .selectize-control.single .selectize-input:after {right: 8px;}
.theme-sidebar-widget .sidebar-search {height: 60px;position: relative;}
.theme-sidebar-widget .sidebar-search input {
  width: 100%;
  height: 100%;
  border: 1px solid #f9f9f9;
  padding: 0 50px 0 25px;
  color: #a9a9a9;
  box-shadow: 4.994px 9.801px 25px 0px rgba(217, 224, 235, 0.4);
}
.theme-sidebar-widget .sidebar-search ::placeholder {color: #a9a9a9;opacity: 1; /* Firefox */}
.theme-sidebar-widget .sidebar-search :-ms-input-placeholder {color: #a9a9a9;}
.theme-sidebar-widget .sidebar-search ::-ms-input-placeholder {color: #a9a9a9;}
.theme-sidebar-widget .sidebar-search button {
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  width: 50px;
  background: #fff;
  color: #b1b1b1;
  font-size: 20px;
}
.theme-sidebar-widget .sidebar-popular-product .img {width: 85px;height: 85px;}
.theme-sidebar-widget .sidebar-popular-product .info {padding-left: 25px;}
.theme-sidebar-widget .sidebar-popular-product .info a {
  font-size: 22px;
  color: #3e3e3e;
  margin-bottom: 3px;
}
.theme-sidebar-widget .sidebar-popular-product .info .price {font-size: 22px;color: #989ca2;}
.theme-sidebar-widget .sidebar-popular-product li {margin-bottom: 30px;}
.theme-sidebar-widget .sidebar-popular-product li:last-child {margin: 0;}
.theme-sidebar-widget .recent-news-item .rn-title {
  font-family: 'CircularStdmed';
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}
.theme-sidebar-widget .recent-news-item .rn-title a {color: #3e3e3e;}
.theme-sidebar-widget .recent-news-item .date {font-size: 18px;color: #b5b5b5;}
.theme-sidebar-widget .recent-news-item li {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.theme-sidebar-widget .recent-news-item li:last-child {margin-bottom: 0;}
.theme-sidebar-widget .keywords-tag {margin: 0 -5px;}
.theme-sidebar-widget .keywords-tag li {float: left;padding: 0 5px;margin-bottom: 15px;}
.theme-sidebar-widget .keywords-tag li a {
  font-size: 15px;
  text-transform: uppercase;
  color: #989ca2;
  padding: 0 20px;
  line-height: 33px;
  border: 1px solid #e0e0e0;
}
.theme-sidebar-widget .keywords-tag li a:hover {color: #fff;}
/*-------------------- Product Showcase ----------------------*/
.product-showcase .single-product-case {margin-bottom: 85px;}
.product-showcase .single-product-case .img-holder {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}
.product-showcase .single-product-case .img-holder a {display: block;}
.product-showcase .single-product-case .img-holder img {
  width: 100%;
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}
.product-showcase .single-product-case:hover .img-holder img {transform: scale(1);}
.product-showcase .single-product-case .info {position: relative;}
.product-showcase .single-product-case .info .name {font-size: 24px;color: #3e3e3e;}
.product-showcase .single-product-case .info .price {font-size: 24px;color: rgba(84,84,84,0.55);margin-top: 5px;}
.product-showcase .single-product-case .info .cart-button {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 15px;
  right: 0;
}
.product-showcase .single-product-case .info .cart-button:before,
.product-showcase .single-product-case .info .cart-button:after {
  content: ' ';
  position: absolute;
  background: #bbbbbb;
}
.product-showcase .single-product-case .info .cart-button:before {
  width: 1px;
  height: 100%;
  top:0;
  left:50%;
}
.product-showcase .single-product-case .info .cart-button:after {
  width: 100%;
  height: 1px;
  top:50%;
  left:0;
}
.product-showcase .single-product-case .info .cart-button:hover:before,
.product-showcase .single-product-case .info .cart-button:hover:after {transform: scale(0);}
.product-showcase .single-product-case .info .cart-button span {
  display: block;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  transform: scale(0);
}
.product-showcase .single-product-case .info .cart-button:hover span {transform: scale(1);}
/*------------------------- Cart Page -----------------------*/
.cart-section .main-container {max-width: 1260px;padding: 0 15px;margin: 0 auto;}
.cart-list-form {position: relative;}
.cart-list-form table {margin: 0;}
.cart-list-form .table th {
  border: none;
  text-align: center;
  padding: 0 0 60px;
  text-align: center;
  text-transform: uppercase;
  color: #1d1d1d;
  font-size: 15px;
}
.cart-list-form .table th:first-child {text-align: left;}
.cart-list-form .table tbody td {
  padding: 0 0 70px; 
  border: none; 
  vertical-align: middle;
  text-align: center;
}
.cart-list-form .table .product-thumbnails {width: 85px;}
.cart-list-form .table .product-thumbnails img {max-width: none;}
.cart-list-form .table .product-img {display: block;}
.cart-list-form .table .product-info {padding-left: 30px;text-align: left;}
.cart-list-form .table .product-info .product-name {font-size: 20px;color: #1f1f1f;}
.cart-list-form .table .product-info .serial {
  font-size: 16px;
  color: rgba(31,31,31,0.33);
  padding-bottom: 10px;
}
.cart-list-form .table .product-info ul li {
  display: inline-block;
  font-size: 16px;
  color: #404040;
  padding-right: 15px;
}
.cart-list-form .table .price {font-size: 24px;color: #1d1d1d;}
.cart-list-form .table .quantity li {
  display: inline-block;
  line-height: 40px;
  max-height: 40px;
}
.cart-list-form .table .quantity li button {
  font-size: 24px;
  color: #1d1d1d;
  background: transparent;
}
.cart-list-form .table .quantity .product-value {
  font-size: 20px;
  color: #1d1d1d;
  max-width: 45px;
  background: transparent;
  border: none;
  text-align: center;
  padding-left: 12px;
}
.cart-list-form .table .remove-product {color: #d6d6d6; font-size: 22px;}
.cart-section .cart-footer {
  border-top: 2px solid #545454;
  margin-top: 28px;
  padding-top: 35px;
}
.cart-section .cart-footer .coupon-form {padding-bottom: 120px;}
.cart-section .cart-footer .coupon-form input {
  width: 240px;
  height: 50px;
  border:none;
  border-bottom: 2px solid #545454;
  font-size: 16px;
  color: #c4c4c4;
  margin-right: 30px;
}
.cart-section .cart-footer .coupon-form ::placeholder {color: #c4c4c4;opacity: 1; /* Firefox */}
.cart-section .cart-footer .coupon-form :-ms-input-placeholder {color: #c4c4c4;}
.cart-section .cart-footer .coupon-form ::-ms-input-placeholder {color: #c4c4c4;}
.cart-section .cart-footer .cart-total-section {text-align: right;}
.cart-section .cart-footer .cart-total-table tr th {
  font-size: 18px;
  color: rgba(29,29,29,0.33);
  font-weight: normal;
  padding-right: 40px;
}
.cart-section .cart-footer .cart-total-table tr td {
  font-size:24px;
  color: #1d1d1d;
}
.cart-section .cart-footer .cart-total-table tr th,
.cart-section .cart-footer .cart-total-table tr td {padding-bottom: 12px;}
.cart-section .cart-footer .cart-total-section .checkout-process {margin-top: 24px;}
.cart-section .cart-footer .cart-total-section {padding-right: 82px;}
/*--------------------- CheckOut Page -----------------*/
.checkout-form .main-title {
  font-family: 'CircularStdmed';
  font-size:28px;
  color: #1d1d1d;
  padding-bottom: 55px;
}
.checkout-form .single-input-wrapper {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #1a1a1a;
  border: none;
  border-bottom: 2px solid #e5e5e5;
  margin-bottom: 55px;
}
.checkout-form ::placeholder {color: #1a1a1a;opacity: 1; /* Firefox */}
.checkout-form :-ms-input-placeholder {color: #1a1a1a;}
.checkout-form ::-ms-input-placeholder {color: #1a1a1a;}
.checkout-form .single-input-wrapper:focus {border-bottom-color:#545454; }
.checkout-form .selectize-input {
  width: 100%;
  line-height: 40px;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #e5e5e5;
  box-shadow: none;
  outline: none;
  padding: 0 15px 0 0;
}
.checkout-form .selectize-control {margin-bottom: 50px;}
.checkout-form .selectize-input input,
.checkout-form .selectize-input .item {font-size: 16px;color: #1a1a1a;}
.checkout-form .selectize-dropdown {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-top: none;
  box-shadow: 0px 50px 100px 0px rgba(229, 232, 235, 0.2);
  cursor: pointer;
}
.checkout-form .selectize-dropdown .option {font-size: 14px;color: #1a1a1a;line-height: 22px;}
.checkout-form .selectize-dropdown .option:hover,
.checkout-form .selectize-dropdown .active {color: #fff; background:#1a1a1a; }
.checkout-form .selectize-control.single .selectize-input:after {right: 8px;}
.checkout-form .checkbox-list li label {
  position: relative;
  font-family: 'CircularStdmed';
  font-size: 16px;
  line-height: 15px;
  padding-left: 30px;
  color: #1a1a1a;
  cursor: pointer;
  margin: 0 0 20px;
}
.checkout-form .checkbox-list li input[type="checkbox"] {display: none;}
.checkout-form .checkbox-list li label:before {
  content: '';
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 2px;
  border: 1px solid #d5d5d5;
  font-size: 10px;
  text-align: center;
  position: absolute;
  left:0;
  top:-1px;
}
.checkout-form .checkbox-list li input[type="checkbox"]:checked + label:before {
  content: "\f107";
  font-family: "Flaticon";
  background: #373737;
  color: #fff;
  border-color:  #373737;
}
.checkout-form .checkbox-list {padding-bottom: 55px;}
.checkout-form .other-note-area p {
  font-family: 'CircularStdmed';
  font-size: 16px;
  color: #1a1a1a;
  padding-bottom: 8px;
}
.checkout-form .other-note-area textarea {
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 15px;
  resize: none;
  height: 145px;
}
.checkout-form .order-confirm-sheet {padding-left: 60px;}
.checkout-form .order-confirm-sheet .order-review {
  border: 1px solid #e5e5e5;
  padding: 50px 40px;
}
.checkout-form .order-confirm-sheet .order-review .product-review {width: 100%;}
.checkout-form .order-confirm-sheet .order-review .product-review tr {font-family: 'CircularStdmed';}
.checkout-form .order-confirm-sheet .order-review .product-review tbody th span {
  font-size: 18px;
  color: #242424;
  font-weight: normal;
}
.checkout-form .order-confirm-sheet .order-review .product-review tbody td {
  font-size: 18px;
  color: #1d1d1d;
  text-align: right;
}
.checkout-form .order-confirm-sheet .order-review .product-review tbody th,
.checkout-form .order-confirm-sheet .order-review .product-review tbody td {padding-bottom: 25px;}
.checkout-form .order-confirm-sheet .order-review .product-review tfoot th {
  font-size: 16px;
  text-transform: uppercase;
  color: #242424;
  font-weight: normal;
}
.checkout-form .order-confirm-sheet .order-review .product-review tfoot td {
  text-align: right;
  font-size: 24px;
  color: #222222;
}
.checkout-form .order-confirm-sheet .order-review .product-review tfoot td,
.checkout-form .order-confirm-sheet .order-review .product-review tfoot th {
  border-top: 1px solid #e9e9e9;
  padding-top: 15px;
}
.checkout-form .order-confirm-sheet .order-review .payment-list li {padding: 0 0 5px 30px;}
.checkout-form .order-confirm-sheet .order-review .payment-list li p {font-size: 15px;line-height: 22px;padding-bottom: 15px;}
.checkout-form .order-confirm-sheet .order-review .payment-list li label {
  position: relative;
  font-family: 'CircularStdmed';
  font-size: 18px;
  line-height: 15px;
  color: #1a1a1a;
  cursor: pointer;
  margin: 0 0 15px;
}
.checkout-form .order-confirm-sheet .order-review .payment-list li {position: relative;}
.checkout-form .order-confirm-sheet .order-review .payment-list li input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.checkout-form .order-confirm-sheet .order-review .payment-list li label:before {
  content: '';
  width: 15px;
  height: 15px;
  line-height: 12px;
  border-radius: 50%;
  border: 1px solid #d5d5d5;
  font-size: 10px;
  text-align: center;
  position: absolute;
  left:-30px;
  top:-1px;
}
.checkout-form .order-confirm-sheet .order-review .payment-list li input:checked + label:before {
  content: "";
  font-family: 'font-awesome';
  background: #373737;
  color: #fff;
  border-color:  #373737;
}
.checkout-form .order-confirm-sheet .order-review .payment-list {padding: 30px 0 15px;border-bottom: 1px solid #e9e9e9;}
.checkout-form .credit-card-form {margin-top: 25px; display: none;}
.checkout-form .credit-card-form h6 {
  font-family: 'CircularStdmed';
  font-size: 16px;
  padding-bottom: 5px;
}
.checkout-form .credit-card-form input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  border: 1px solid rgba(0,0,0,0.07);
  padding: 0 10px;
  border-radius: 3px;
  margin-bottom: 30px;
}
.checkout-form .credit-card-form span {padding: 0 5px;margin-bottom: 30px;}
.checkout-form .order-confirm-sheet .policy-text {
  font-size: 15px;
  line-height: 22px;
  color: #979797;
  padding: 25px 0 20px;
}
.checkout-form .order-confirm-sheet .agreement-checkbox label {
  position: relative;
  font-family: 'CircularStdmed';
  font-size: 16px;
  color: #1a1a1a;
  cursor: pointer;
  padding-left: 30px;
  margin-bottom: 35px;
}
.checkout-form .order-confirm-sheet .agreement-checkbox input[type="checkbox"] {display: none;}
.checkout-form .order-confirm-sheet .agreement-checkbox label:before {
  content: '';
  width: 15px;
  height: 15px;
  line-height: 12px;
  border-radius: 2px;
  border: 1px solid #d5d5d5;
  font-size: 10px;
  text-align: center;
  position: absolute;
  left:0;
  top:3px;
}
.checkout-form .order-confirm-sheet .agreement-checkbox input[type="checkbox"]:checked + label:before {
  content: "";
  font-family: 'font-awesome';
  background: #373737;
  color: #fff;
  border-color:  #373737;
}
.checkout-form .order-confirm-sheet .dark-button-one {width: 100%;}
.checkout-toggle-area p {padding-bottom: 5px;}

.checkout-toggle-area p button {
  color: #1a1a1a;
  background: transparent;
  display: inline-block;
  text-decoration: underline;
}
.checkout-toggle-area form input {
  width: 100%;
  height: 55px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 0 30px;
  margin-bottom: 20px;
}
.checkout-toggle-area form input:focus {border-color: #777;}
.checkout-toggle-area form .lost-passw {
  color: #636067;
  font-size: 16px;
  margin: 12px 0 35px;
}
.checkout-toggle-area form button {font-size: 15px;}
.checkout-toggle-area form p {padding-top: 30px;}
/*--------------------- Theme Solid Inner Banner -----------------*/
.solid-inner-banner {
  background: #fafcff;
  text-align: center;
  padding: 265px 0 0;
}
.solid-inner-banner.white-bg {background: #fff;}
.solid-inner-banner .page-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  color: #313131;
  padding-bottom: 150px;
}
.solid-inner-banner .page-breadcrumbs {
  background: #fff;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 0;
}
.solid-inner-banner .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  color: #244574;
  margin: 0 3px;
}
.solid-inner-banner .page-breadcrumbs li a {color: #244574;}
/*----------------------- Shop Details ----------------------*/
.shop-details .procuct-details .tab-content img {width: 100%;}
.shop-details .procuct-details .tab-content {margin-bottom: 40px;}
.shop-details .nav-tabs > li {margin: 0;}
.shop-details .procuct-details .nav-tabs > li > a {
  padding: 0;
  margin: 0 20px 0 0;
  border: 0;
  border-radius: 0;
  width: 100px;
  height: 100px;
  display: block;
  overflow: hidden;
}
.shop-details .nav-tabs {border: none;}
.shop-details .procuct-details .product-info {padding-left: 30px;}
.shop-details .procuct-details .product-info .product-name {
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 15px;
  color: #313131;
}
.shop-details .procuct-details .product-info .rating li {display: inline-block;color: #a2a2a2;}
.shop-details .procuct-details .product-info .rating li:nth-child(6) {margin: 0 10px;}
.shop-details .procuct-details .product-info .rating li i {color: #ffbd3a;}
.shop-details .procuct-details .product-info .price {
  font-family: 'CircularStdmed';
  display: block;
  font-weight: normal;
  font-size: 32px;
  margin: 30px 0 20px;
}
.shop-details .procuct-details .product-info .availability li {
  display: inline-block;
  line-height: 40px;
  font-size: 18px;
  color: #989ca2;
}
.shop-details .procuct-details .product-info .availability li span {color: #212121;}
.shop-details .procuct-details .product-info .availability li:nth-child(2) {margin: 0 10px;}
.shop-details .procuct-details .product-info p {
  font-size: 18px;
  margin: 25px 0 50px 0;
}
.shop-details .procuct-details .product-info .customize-order h6 {
  font-size: 24px;
  color: #313131;
  padding-bottom: 25px;
}
.shop-details .procuct-details .product-info .quantity ul {
  border: 1px solid #e3e3e3;
  display: inline-block;
}
.shop-details .procuct-details .product-info .quantity ul li {
  display: inline-block;
  line-height: 40px;
  max-height: 40px;
}
.shop-details .procuct-details .product-info .quantity ul li button {
  font-size: 25px;
  color: #c9c9c9;
  background: transparent;
  width: 25px;
}
.shop-details .procuct-details .product-info .quantity ul .product-value {
  font-size: 19px;
  color: #313131;
  max-width: 42px;
  background: transparent;
  border: none;
  padding-left: 10px;
}
.shop-details .procuct-details .product-info .quantity {padding-right: 90px;}
.shop-details .procuct-details .product-info .color-filter li {display: inline-block;}
.shop-details .procuct-details .product-info .color-filter li a {
  display: block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
}
.shop-details .procuct-details .product-info .color-filter li:nth-child(1) a{border-color: #9aa2ce;}
.shop-details .procuct-details .product-info .color-filter li:nth-child(2) a{border-color: #00ead0;}
.shop-details .procuct-details .product-info .color-filter li:nth-child(3) a{border-color: #ff4b68;}
.shop-details .procuct-details .product-info .color-filter li:nth-child(4) a{border-color: #ffd657;}
.shop-details .procuct-details .product-info .color-filter li:nth-child(1):hover a{background:#9aa2ce }
.shop-details .procuct-details .product-info .color-filter li:nth-child(2):hover a{background: #00ead0;}
.shop-details .procuct-details .product-info .color-filter li:nth-child(3):hover a{background: #ff4b68;}
.shop-details .procuct-details .product-info .color-filter li:nth-child(4):hover a{background: #ffd657;}
.shop-details .procuct-details .product-info .customize-order {margin-bottom: 50px;}
.shop-details .procuct-details .product-info .cart-button {
  font-family: 'CircularStdmed';
  width: 155px;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase;
  border-width: 2px;
  border-style: solid;
  color: #fff;
  text-align: center;
  margin-right: 20px;
}
.shop-details .procuct-details .product-info .cart-button:hover {background: #fff;}
.shop-details .procuct-details .product-info .wishlist-button {
  font-family: 'CircularStdmed';
  width: 190px;
  line-height: 48px;
  font-size: 14px;
  color: rgba(0,0,0,0.45);
  text-align: center;
  border:1px solid #dcdcdc;
}
.shop-details .procuct-details .product-info .wishlist-button:hover {color: #fff;}
.shop-details .procuct-details .product-info .wishlist-button i {margin-right: 8px;opacity: 0.8;}
.shop-details .product-review-tab {
  border: 1px solid #ebebeb;
  padding: 40px 55px 35px;
  margin: 125px 0 130px;
}
.shop-details .product-review-tab .nav-tabs li a {
  padding: 0 0 22px 0;
  margin-right: 60px;
  font-size: 22px;
  color: #a6aab1;
  border: none;
  border-radius: 0;
  background: transparent;
  position: relative;
}
.shop-details .product-review-tab .nav-tabs li a:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-top:1px solid #ebebeb;
  border-right:1px solid #ebebeb;
  transform: rotate(135deg);
  bottom:-11px;
  left:50%;
  margin-left: -10px;
  z-index: 1;
  opacity: 0;
}
.shop-details .product-review-tab .nav-tabs li:last-child a {margin-right: 0;}
.shop-details .product-review-tab .nav-tabs {border-bottom: 1px solid #ebebeb;}
.shop-details .product-review-tab .nav-tabs .nav-link.active {color: #474747;}
.shop-details .product-review-tab .nav-tabs .nav-link.active:before {opacity: 1;}
.shop-details .product-review-tab .tab-pane {padding: 30px 0 0;}
.shop-details .product-review-tab .tab-content p {line-height: 32px;color: #989ca2;}
.shop-details .product-review-tab .tab-content .list-title {
  font-size: 18px;
  color: #474747;
  font-style: italic;
  padding: 35px 0 22px;
}
.shop-details .product-review-tab .tab-content .tab-title {
  font-size: 20px;
  color: #474747;
  padding: 10px 0 22px;
}
.shop-details .product-review-tab .tab-content .list-item li {
  font-size: 18px;
  color: #989ca2;
  padding-left: 20px;
  margin-bottom: 15px;
  position: relative;
}
.shop-details .product-review-tab .tab-content .list-item li:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left:0;
  top:10px;
}
.shop-details .user-comment-area {padding-right: 200px;}
.user-comment-area .single-comment {padding-bottom: 50px;}
.user-comment-area .single-comment:last-child {padding-bottom: 0;}
.user-comment-area .single-comment .user-img {width: 60px;height: 60px;border-radius: 50%;}
.user-comment-area .single-comment .user-comment-data {padding-left: 30px;position: relative;}
.user-comment-area .single-comment .user-comment-data .name {
  font-family: 'CircularStdmed';
  font-size: 20px;
  color: #3e3e3e;
  padding-bottom: 5px;
}
.user-comment-area .single-comment .user-comment-data .rating li {display: inline-block;}
.user-comment-area .single-comment .user-comment-data .rating li a {
  font-size: 15px;
  color: #fbc134;
  margin-right: 1px;
}
.user-comment-area .single-comment .user-comment-data p {padding-top: 15px; color: #989ca2;}
.realated-product .title {font-size:36px;padding-bottom: 55px;}
.realated-product.product-showcase .single-product-case {margin: 0;}
.realated-product .owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  padding: 0;
  font-size: 32px;
  margin: 0 0 0 15px;
  color: #cacaca;
}
.realated-product .owl-theme .owl-nav {
  position: absolute;
  top:-100px;
  right: 0;
}
/*--------------------------- FAQ Page -------------------*/
.faq-page .faq-page-title {font-size: 32px;}
.faq-page .faq-search-form {height: 60px;position: relative;}
.faq-page .faq-search-form input {
  width: 500px;
  height: 100%;
  border: 1px solid #e1e1e1;
  padding: 0 70px 0 30px;
  font-size: 18px;
  color: #a6a6a6;
  font-style: italic;
}
.faq-page .faq-search-form input:focus {border-color: #313131;}
.faq-page .faq-search-form button {
  position: absolute;
  width: 70px;
  top:0;
  right: 0;
  bottom: 0;
  background: transparent;
  color: #b1b1b1;
}
.faq-page .submit-faq .faq-page-title {padding-bottom: 75px;}
.faq-page .submit-faq input {
  width: 100%;
  height: 60px;
  padding: 0 15px 0 30px;
  color: #989ca2;
  border: 1px solid #e7e7e7;
  margin-bottom: 30px;
}
.faq-page .submit-faq ::placeholder {color: #989ca2;opacity: 1; /* Firefox */}
.faq-page .submit-faq :-ms-input-placeholder {color: #989ca2;}
.faq-page .submit-faq ::-ms-input-placeholder {color: #989ca2;}
.faq-page .submit-faq textarea {
  width: 100%;
  height: 290px;
  max-height: 290px;
  padding: 15px 30px;
  color: #989ca2;
  resize:none;
  border: 1px solid #e7e7e7;
  margin-bottom: 30px;
}
.faq-page .submit-faq input:focus,
.faq-page .submit-faq textarea:focus {border-color: #212121;}
.faq-page .submit-faq button {
  display: block;
  width: 100%;
  height: 60px;
  border-width: 2px;
  border-style: solid;
  font-size: 18px;
  color: #fff;
}
.faq-page .submit-faq button:hover {background: #fff;}
/*-------------------- Our Team Standard -------------------*/
.our-team .theme-title-one .upper-title {color: rgba(36,69,116,0.55);}
.our-team .img-box img {width: 100%;}
.team-standard .single-team-member {
  position: relative;
  padding: 0 45px 55px 0;
  margin-bottom: 100px;
}
.team-standard .single-team-member .wrapper {position: relative;}
.team-standard .single-team-member .img-box img {border-radius: 4px;}
.team-standard .single-team-member .info-meta {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  transform: translate(45px , 55px);
  padding: 25px 0 25px 50px;
  background: #fff;
  box-shadow: 5.994px 14.835px 30px 0px rgba(229, 234, 239, 0.5);
}
.our-team .single-team-member .info-meta .name {
  font-size: 22px;
  color: #3d3d3d;
  padding-bottom: 5px;
}
.our-team .single-team-member .info-meta span {font-size: 20px;color: rgba(0,0,0,0.4);}
.team-standard .single-team-member:hover .info-meta {transform: translate(0px , 55px);}
.team-standard .single-team-member .hover-content {
  position: absolute;
  width: 45px;
  background: #fff;
  box-shadow: 8px 0px 8.6px 1.4px rgba(229, 234, 239, 0.2);
  right: 0;
  top:0;
  bottom: 0;
  transform: scale(0,1);
  transform-origin: 0% 0%;
}
.team-standard .single-team-member .hover-content ul {
  position: relative;
  top:40%;
  transform: translateY(-50%);
}
.team-standard .single-team-member .hover-content ul li a {
  display: block;
  text-align: center;
  color: rgba(0,0,0,0.4);
  font-size: 20px;
  margin: 15px 0;
}
.team-standard .single-team-member:hover .hover-content {transform: scale(1,1);}
/*-------------------- Our Team Standard -------------------*/
.team-minimal .single-team-member {text-align: center;margin-bottom: 130px;}
.team-minimal .single-team-member .img-box {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
.team-minimal .single-team-member .info-meta {padding: 30px 0 0;}
.team-minimal .single-team-member .hover-content {
  position: absolute;
  top:0;
  right: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  transform: scale(0.7);
  opacity: 0;
}
.team-minimal .single-team-member:hover .hover-content {transform: scale(1);opacity: 1;}
.team-minimal .single-team-member .hover-content ul {position: relative;top:50%;transform: translateY(-50%);}
.team-minimal .single-team-member .hover-content ul li {display: inline-block;margin: 0 12px;}
.team-minimal .single-team-member .hover-content ul li a {font-size: 25px;color: #fff;}
/*--------------------- Our Team Buisness --------------------*/
.team-business .single-team-member {margin-bottom: 120px;}
.team-business .single-team-member .img-box {width: 220px;}
.team-business .single-team-member .info-meta {padding-left: 40px;width: calc(100% - 220px);}
.team-business .single-team-member .info-meta q {
  font-size: 21px;
  line-height: 25px;
  font-style: italic;
  color: #3d3d3d;
  display: block;
  padding: 30px 0 45px;
}
/*--------------------- Our Team Classic --------------------*/
.team-classic .wrapper {position: relative;background: #353535;margin-bottom: 100px;}
.team-classic .single-team-member:hover img {opacity: 0.4;}
.team-classic .wrapper .info-meta {
  position: absolute;
  left:0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background: #fff;
  box-shadow: 0px 18px 24px 0px rgba(0, 0, 0, 0.03);
  transform: translateY(10%);
  opacity: 0;
}
.team-classic .wrapper .hover-content {
  position: absolute;
  width: 100%;
  text-align: center;
  left:0;
  top:40%;
  transform: translateY(-50%);
  opacity: 0;
}
.team-classic .wrapper .hover-content li {display: inline-block;margin: 0 10px;}
.team-classic .wrapper .hover-content li a {font-size: 25px;color: #fff;}
.team-classic .single-team-member:hover .hover-content {opacity: 1;}
.team-classic .single-team-member:hover .info-meta {
  transform: translateY(0);
  opacity: 1;
}
/*----------------------- About Us Standard ---------------------*/
.about-us-standard .top-icon-box {text-align: center;padding-bottom: 50px;}
.about-us-standard .top-icon-box .icon {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(202, 221, 243, 0.5);
  width: 70px;
  height: 70px;
  margin: 0 auto;
  line-height: 70px;
  border-radius: 50%;
  font-size: 28px;
  color: #ffc77e;
}
.about-us-standard .top-icon-box span {
  display: block;
  text-transform: uppercase;
  font-size: 20px;
  color: rgba(36,69,116,0.55);
  padding-top: 30px;
}
.about-us-standard .single-block .block-title {
  font-size: 24px;
  color: #244574;
}
.about-us-standard .single-block p {
  font-size: 18px;
  line-height: 32px;
  color: #798598;
  padding-right: 50px;
}
.about-us-standard .single-block .author-data {padding: 38px 0 0 40px;}
.about-us-standard .single-block .author-data .name {
  font-family: 'CircularStdmed';
  font-size: 16px;
  text-transform: uppercase;
  color: #3e3e3e;
  position: relative;
}
.about-us-standard .single-block .author-data .name:before {
  content: '';
  position: absolute;
  width: 23px;
  height: 2px;
  background: #666666;
  top:8px;
  left:-40px;
}
.about-us-standard.agency-style .row {padding-bottom: 120px;}
.about-us-standard.agency-style .row:last-child {padding: 0;}
.about-us-standard.agency-style .row:nth-child(even) .img-box img {margin-top: -185px;}
/*-------------------- Theme Counter Two ---------------------*/
.theme-counter-two {border-top:1px solid #e9e9e9; border-bottom: 1px solid #e9e9e9;}
.theme-counter-two .single-counter-box:nth-child(2) {border-left:1px solid #e9e9e9; border-right: 1px solid #e9e9e9;}
.theme-counter-two .single-counter-box {padding: 95px 15px 95px 7%;}
.theme-counter-two .single-counter-box .icon-box {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.theme-counter-two .single-counter-box .icon-box img {
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.theme-counter-two .single-counter-box:nth-child(1) .icon-box {background: linear-gradient( 120deg, rgb(249,154,120) 0%, rgb(255,83,140) 100%);}
.theme-counter-two .single-counter-box:nth-child(2) .icon-box {background: linear-gradient( 120deg, rgb(81,236,112) 0%, rgb(36,189,155) 100%);}
.theme-counter-two .single-counter-box:nth-child(3) .icon-box {background: linear-gradient( 120deg, rgb(108,71,218) 0%, rgb(224,129,255) 100%);}
.theme-counter-two .single-counter-box .text {padding-left: 30px;}
.theme-counter-two .single-counter-box .text .number {font-size: 36px;color: #3e3e3e;}
.theme-counter-two .single-counter-box .text p {font-size: 18px;color: #a4a7ac;margin-top: -8px;}
/*--------------------- Video Action Banner One ----------------------*/
.video-action-banner-one {
  background-image: url(./assets/images/home/12.jpg);
  background-size: 120%;
  background-repeat: no-repeat;
  animation: imageBgAnim 50s infinite linear alternate;
}
.video-action-banner-one .overlay {background: rgba(0,0,0,0.4);padding: 320px 0;text-align: center;}
.video-action-banner-one .video-button {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  position: relative;
}
.video-action-banner-one .video-button img {
  margin: 0 auto;
  position: relative;
  top:50%;
  transform: translateY(-50%);
}
.video-action-banner-one .video-button:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  top:0;
  left:0;
  border-radius: 50%;
  animation: hvr-ripple-out-two 1.2s linear infinite;
}
/*--------------------- Video Action Banner Two ----------------------*/
.theme-action-banner-two {
  position: relative;
  background: url(./assets/images/home/13.jpg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  padding: 150px 0 160px;
  z-index: 1;
}
.theme-action-banner-two:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  background: rgba(0,0,0,0.18);
  z-index: -1;
}
.theme-action-banner-two .title {
  font-family: 'CircularStdmed';
  font-size: 48px;
  color: #fff;
  padding: 0 200px;
}
.theme-action-banner-two .banner-button {
  width: 210px;
  line-height: 53px;
  color: #fff;
  font-size: 19px;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-top: 65px;
}
/*----------------------- Our Service Minimal ----------------------*/
.our-service .service-block .service-title {font-size: 48px;line-height: 54px;}
.our-service .service-block .service-title a {color: #3e3e3e;}
.our-service .service-block .read-more {font-size: 38px;line-height: 20px;}
.service-minimal .row {margin: 0 -110px;}
.service-minimal .row [class*="col-"] {padding: 0 110px;}
.service-minimal .service-block .icon-box {height: 80px;margin-bottom: 50px;}
.service-minimal .service-block p {
  font-size: 17px;
  line-height: 32px;
  color: #798598;
  padding: 35px 0;
}
.service-minimal .service-block .read-more {color: #cfd5df;}
.service-minimal .service-block {margin-bottom: 140px;}
/*----------------------- Our Service Standard ----------------------*/
.service-standard .service-block {
  background-size: cover;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  height: 475px;
}
.service-standard .service-block:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(0,0,0,0.2);
  opacity: 0;
  z-index: -1;
}
.service-standard .service-block:hover:before {opacity: 1;}
.service-standard .service-block .hover-content {
  position: absolute;
  width: 100%;
  left:0;
  bottom: 15px;
  padding: 20px 40px;
}
.service-standard .service-block .hover-content .title a {
  font-size: 22px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 12px;
}
.service-standard .service-block .hover-content .read-more {color: #fff;}
.service-standard .service-block .hover-content p {
  font-size: 16px;
  color: #fff;
  padding: 10px 40px 20px;
  opacity: 0;
  position: absolute;
  top:0;
  left:-50px;
  transform: translateY(-100%);
}
.service-standard .service-block:hover .hover-content p {opacity: 1; left: 0;}
/*----------------------- Our Service Creative ---------------------*/
.our-service .our-history .img-box {
  position: relative;
  overflow: hidden;
  padding-bottom: 180px;
}
.our-service .our-history .img-box .bottom-img {
  position: absolute;
  right: -65px;
  bottom:0;
  border:30px solid #fff;
}
.our-service .our-history .text-wrapper {padding: 80px 0 0 180px;}
.our-service .our-history .text-wrapper p {
  color: #67707e;
  font-size: 20px;
  line-height: 35px;
  padding-top: 30px;
}
.service-creative .service-block {padding: 110px 0 125px;}
.service-creative .service-block .img-box {margin-top: 30px;}
.service-creative .service-block .service-info .num {
  font-size: 80px;
  color: rgba(255,255,255,0.1);
  line-height: 70px;
  margin-bottom: 30px;
}
.service-creative .service-block .service-info .service-title a {color: #fff;}
.service-creative .service-block .service-info p {
  font-size: 18px;
  color: rgba(255,255,255,0.8);
  padding: 22px 0 40px;
}
.service-creative .service-block .service-info .read-more {color: #fff;}
.service-creative .service-block:nth-child(even) .service-info {padding-left: 60px;}
.service-creative .service-block:nth-child(odd) .service-info {padding-right: 60px;}
.service-creative .service-block:nth-child(odd) .img-box {float: right;}
/*------------------------ Our Service Modren -------------------*/
.service-modren .service-block .img-box img {border-radius: 5px;}
.service-modren .service-block .service-info .tag {
  display: inline-block;
  line-height: 40px;
  font-size: 15px;
  padding: 0 35px;
  border-radius: 3px;
}
.service-modren .service-block .service-info .tag.color-one {background: #ffeaee;color: #ff8aa0;}
.service-modren .service-block .service-info .tag.color-two {background: #fef9da;color: #ead24c;}
.service-modren .service-block .service-info .tag.color-three {background: #e7fff5;color: #70e9ae;}
.service-modren .service-block .service-info .tag.color-four {background: #edf9ff;color: #7fd8ff;}

.service-modren .service-block .service-info .read-more {color: #cfd5df;}
.service-modren .service-block .service-info .service-title {margin: 40px 0 45px;}
.service-modren .service-block {padding-bottom: 150px;}
.service-modren .service-block:last-child {padding-bottom: 0;}
.service-modren .service-block:nth-child(odd) .service-info {padding-right: 30px;}
.service-modren .service-block:nth-child(even) .service-info {padding-left: 30px;}
/*----------------------- Our Project --------------------*/
.project-ms-grid .inner-container {max-width: 1410px;padding: 0 15px;margin: 0 auto;}
.our-project .isotop-menu-wrapper {text-align: center;}
.our-project .isotop-menu-wrapper li {
  display: inline-block;
  font-size: 20px;
  color: #244574;
  line-height: 31px;
  text-transform: capitalize;
  border-radius: 5px;
  padding: 0 16px;
  border:2px solid transparent;
  cursor: pointer;
  margin: 0 5px 10px;
}
#isotop-gallery-wrapper .grid-sizer,#isotop-gallery-wrapper .isotop-item {width: 33.33333333%;}
.project-standard #isotop-gallery-wrapper {margin: 0 -35px;}
.project-standard #isotop-gallery-wrapper .grid-sizer,.project-standard #isotop-gallery-wrapper .isotop-item {
  width: 50%;
  padding: 0 35px;
  margin-bottom: 70px;
}
.project-ms-full-width #isotop-gallery-wrapper .grid-sizer,.project-ms-full-width #isotop-gallery-wrapper .isotop-item {width: 25%;}
.project-ms-grid #isotop-gallery-wrapper {margin: 0 -25px;}
.project-ms-grid #isotop-gallery-wrapper .grid-sizer,.project-ms-grid #isotop-gallery-wrapper .isotop-item {
  width: 33.3333333%;
  padding: 0 25px;
  margin-bottom: 45px;
}
.our-project .project-item {overflow: hidden;position: relative;}
.our-project .project-item .img-box img {width: 100%; transition: all 1s ease-in-out;}
#isotop-gallery-wrapper .isotop-item .hover-jojo {
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background: rgba(55,55,55,0.5);
  padding: 10px 25px;
  opacity: 0;
}
#isotop-gallery-wrapper .isotop-item .project-item:hover .hover-jojo {opacity: 1;}
#isotop-gallery-wrapper .isotop-item .hover-jojo>div {
  position: relative;
  display: inline-block;
  top:50%;
  transform: translateY(-50%);
}
.our-project .project-item .title a {
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.our-project .project-item .title span {font-family: 'CircularStdmed';text-decoration: underline;}
#isotop-gallery-wrapper .isotop-item .hover-jojo .title a {
  opacity: 0;
  transform: translate3d(-360px,0,0);
}
#isotop-gallery-wrapper .isotop-item .hover-jojo>div p {
  font-size: 95%;
  line-height: 30px;
  padding: 0 15px;
  margin-bottom: 5px;
  background: rgba(255,255,255,0.9);
  opacity: 0;
  transform: translate3d(-300px,0,0);
}
#isotop-gallery-wrapper .isotop-item .hover-jojo div div>p:first-child {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
#isotop-gallery-wrapper .isotop-item .hover-jojo div div>p:nth-child(2) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
#isotop-gallery-wrapper .isotop-item .hover-jojo div div>p:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li {
  display: inline-block;
  vertical-align: middle;
  margin-top: 20px;
  opacity: 0;
  transform: translate3d(-100px,0,0);
}
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li:first-child {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li:last-child {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a {
  width: 45px;
  height: 45px;
  border: 1px solid rgba(255,255,255,0.8);
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  margin-right: 8px;
  border-radius: 50%;
}
#isotop-gallery-wrapper .isotop-item .hover-jojo ul li a span {
  font-family: 'Lato', sans-serif;
  display: block;
  font-weight: 300;
  font-size: 40px;
  margin-top: -2px;
}
#isotop-gallery-wrapper .isotop-item .project-item:hover .hover-jojo .title a,
#isotop-gallery-wrapper .isotop-item .project-item:hover .hover-jojo p,
#isotop-gallery-wrapper .isotop-item .project-item:hover .hover-jojo ul li {
  opacity: 1;
  transform: translate3d(0,0,0);
}
#isotop-gallery-wrapper .isotop-item .project-item:hover .img-box img,
.project-with-sidebar .project-item:hover .img-box>img {
  -webkit-transform: scale3d(1.1,1.1,1);
  transform: scale3d(1.1,1.1,1);
}
.our-project .project-item .hover-valina {
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  padding: 30px;
  opacity: 0;
}
.our-project .project-item:hover .hover-valina {opacity: 1;}
.our-project .project-item .hover-valina:before, .our-project .project-item .hover-valina:after {
  content: '';
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  opacity: 0;
}
.our-project .project-item .hover-valina:before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}
.our-project .project-item .hover-valina:after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  transform: scale(1,0);
}
.our-project .project-item:hover .hover-valina:before,
.our-project .project-item:hover .hover-valina:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.our-project .project-item .hover-valina>div {
  text-align: center;
  position: relative;
  z-index: 1;
  top:50%;
  transform: translateY(-50%);
}
.our-project .project-item .hover-valina .title a {
  opacity: 0;
  -webkit-transform: translate3d(0,-20px,0);
  transform: translate3d(0,-20px,0);
  margin-bottom: 15px;
}
.our-project .project-item .hover-valina p {
  opacity: 0;
  font-size:98%;
  color: #fff;
  padding-bottom: 30px;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
  transition-delay: 0.15s;
}
.our-project .project-item .hover-valina .zoom {
  opacity: 0;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
  transition-delay: 0.2s;
}
.our-project .project-item .hover-valina .svg  {
  width: 35px;
  height: 35px;
}
.our-project .project-item .hover-valina .zoom:hover .svg path,
.project-with-sidebar .project-item .img-box a .svg:hover path  {fill: #ff3612;}
.our-project .project-item:hover .hover-valina .title a,
.our-project .project-item:hover .hover-valina p,
.our-project .project-item:hover .hover-valina .zoom {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.project-with-sidebar .project-item {padding-bottom: 60px;margin-bottom: 70px;}
.project-with-sidebar .project-item .hover-coco {
  position: absolute;
  bottom: 0;
  left:0;
  right: 60px;
  background: #fff;
  padding: 25px 0 10px;
  z-index: 1;
}
.project-with-sidebar .project-item .hover-coco .title a {
  font-size: 24px;
  color: #1d1d1d;
  text-transform: capitalize;
  margin-bottom: 0;
}
.project-with-sidebar .project-item .hover-coco p {color: #c1c1c1;font-size: 18px;}
.project-with-sidebar .project-item .img-box {position: relative;overflow: hidden;}
.project-with-sidebar .project-item .img-box a {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(0,0,0,0.5);
  opacity: 0;
}
.project-with-sidebar .project-item .img-box a .svg {
  width: 35px;
  height: 35px;
  margin: 0 auto;
  position: relative;
  top:52%;
  transition: all 0.35s ease-in-out;
  transform: translateY(-50%);
}
.project-with-sidebar .project-item:hover .img-box a {opacity: 1;}
.project-with-sidebar .project-item:hover .img-box a .svg {top:50%;}
.project-with-sidebar .page-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  color: #313131;
  padding: 100px 0 80px;
}
.project-minimal-page-wrapper {height: 100vh;}
.project-minimal-style {padding: 250px 95px 50px;}
.project-minimal-style .inner-wrapper {padding: 0 35px;}
.project-minimal-style .owl-theme .owl-nav [class*=owl-] {
  width: 59px;
  height: 54px;
  line-height: 54px;
  background: #fff;
  padding: 0;
  border-radius: 50%;
  color: #222222;
  font-size: 32px;
  margin: 0;
  position: absolute;
  top:50%;
  margin-top: -27px;
}
.project-minimal-style .owl-theme .owl-nav .owl-prev {left: -35px;}
.project-minimal-style .owl-theme .owl-nav .owl-next {right: -35px;}
.project-minimal-style .share-icon {text-align: right;padding-top: 60px;}
.project-minimal-style .share-icon li {display: inline-block;font-size: 24px;color: #3e3e3e;}
.project-minimal-style .share-icon li a {
  font-size:20px;
  color: #d3d3d3;
  margin-left: 15px;
}
.project-minimal-style .share-icon li a:hover {color: #212121;}
/*----------------------- Gallery Sidebar -------------------------*/
.gallery-sidebar {padding-top: 245px;}
.gallery-sidebar .sidebar-title {
  font-size: 28px;
  color: #313131;
  position: relative;
  padding-bottom: 18px;
  margin-bottom: 50px;
}
.gallery-sidebar .sidebar-title:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 3px;
  bottom: 0;
  left: 0;
}
.gallery-sidebar .sidebar-list li a {
  display: block;
  font-size: 18px;
  line-height: 40px;
  color: #585858;
}
.gallery-sidebar .sidebar-icon ul li {display: inline-block;}
.gallery-sidebar .sidebar-icon ul li a {
  font-size: 22px;
  color: #bcbcbc;
  margin-right: 15px;
}
/*----------------------------- Project Details -------------------------*/
.project-details .project-title-one {
  font-size: 48px;
  line-height: 55px;
  color: #3e3e3e;
  width: 45%;
}
.project-details .project-title-two {
  font-size: 60px;
  line-height: 65px;
  color: #3e3e3e;
  width: 80%;
  margin: 0 auto;
}
.project-details .share-icon li {
  font-family: 'CircularStdmed';
  display: inline-block;
  font-size:18px;
  text-transform: uppercase;
  color: #3e3e3e;
}
.project-details .share-icon li:first-child {padding-right: 15px;}
.project-details .share-icon li a {
  font-size:20px;
  color: #d3d3d3;
  margin-left: 13px;
}
.project-details .share-icon li a:hover {color: #212121;}
.project-details .project-info .title {
  font-size: 28px;
  color: #3e3e3e;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 25px;
}
.project-details .project-info .list-title {
  font-family: 'CircularStdmed';
  font-size: 20px;
  color: #3e3e3e;
  text-transform: uppercase;
  padding-bottom: 8px;
}
.project-details .project-info span {font-size: 20px;color: #999999;}
.project-details .project-info ul li {padding-bottom: 50px;}
.project-details .project-info ul li:last-child {padding-bottom: 0;}
.project-details .side-block .block-title {
  font-size: 24px;
  text-transform: capitalize;
  color: #3e3e3e;
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 25px;
}
.project-details .side-block .block-title:before {
  content: '';
  width: 28px;
  height: 4px;
  position: absolute;
  left:0;
  bottom: 0;
  border-radius: 3px;
}
.project-details .side-block p {padding-right: 50px;}
.project-details .side-block {padding-bottom: 75px;}
.pd-footer .theme-pager {
  font-size: 24px;
  color: #3e3e3e;
  line-height: 35px;
}
.pd-footer .theme-pager span {
  font-size: 35px;
  line-height: 35px;
  display: inline-block;
  vertical-align: -6px;
  color: #979797;
}
.project-details .pd-body p {font-size: 18px;line-height: 32px;color: #a4a7ac;}
.project-details-agency .pd-img-box {margin: 68px 0 90px;}
.project-details-agency .pd-body .sub-heading {
  font-family: 'CircularStdmed';
  font-size: 24px;
  line-height: 35px;
  color: #3e3e3e;
  padding-bottom: 50px;
}
.project-details-agency .pd-body .project-info {border-left: 1px solid #e1e1e1;padding-left: 100px;}
.project-details-agency .pd-footer {padding-top: 170px;}
.project-details-standard .pd-footer {padding-top: 35px; position: relative;}
.project-details-standard .pd-header {
  max-width: 890px;
  margin: 0 auto;
  padding: 120px 0 65px;
}
.project-details-standard .pd-header p {
  font-size: 20px;
  color: #8d8d8d;
  line-height: 35px;
  padding: 70px 100px 100px;
}
.project-details-standard .pd-img-box {margin-bottom: 90px;}
.project-details-business .pd-img-box {margin-bottom: 50px;}
.project-details.project-details-business .project-info .title {margin: 60px 0 35px;}
.project-details.project-details-business .project-info .list-title {font-size: 18px;text-transform: capitalize;}
.project-details.project-details-business .project-info ul li {padding-bottom: 35px;}
.project-details.project-details-business .project-info {padding-bottom: 50px;}
.project-details .pd-banner {
  height: 820px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  animation: imageBgAnim 40s infinite linear alternate; 
}
.project-details-creative .pd-banner .container,
.project-details-creative .pd-banner .container-inner {position: relative;height: 100%;}
.project-details-creative .pd-banner .project-title-one {
  background: #fff;
  padding: 45px 100px 35px 60px;
  position: absolute;
  left:0;
  bottom: -60px;
  width: 55%;
  z-index: 1;
}
.project-details-creative .container-wrapper {padding: 0 60px;}
.project-details-creative .pd-body {padding: 180px 0 150px;}
.project-details-creative .project-info {border-right: 1px solid #e1e1e1;margin-right: 50px;}
.project-details.project-details-creative .project-info ul li {padding-bottom: 73px;}
.project-details.project-details-creative .project-info ul li:last-child {padding-bottom: 0;}
.project-details.project-details-creative .side-block .block-title {padding-bottom: 0;}
.project-details.project-details-creative .side-block .block-title:before {display: none;}
.project-details.project-details-creative .pd-footer>div {width: 37%;}
/*Related Project Section*/
.related-project {background: #f9fbfe;padding: 135px 0 150px;}
.related-project .sec-title {
  font-size: 36px;
  color: #313131;
  padding-bottom: 55px;
}
.related-project .owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  padding: 0;
  font-size: 32px;
  margin: 0 0 0 15px;
  color: #cacaca;
}
.related-project .owl-theme .owl-nav {
  position: absolute;
  top:-100px;
  right: 0;
}
/*--------------------------- Our Blog ------------------------------*/
.masnory-blog-wrapper {margin: 0 -25px;}
.masnory-blog-wrapper .grid-sizer,.masnory-blog-wrapper .isotop-item {width: 33.333333%;padding: 0 25px;}
.our-blog .img-holder {background: #292928;overflow: hidden;position: relative;}
.our-blog .img-holder img {width: 100%;transition: all 0.6s ease-in;}
.our-blog .single-blog-post:hover .img-holder img {opacity: 0.6;transform: scale3d(1.1,1.1,1);}
.our-blog .img-holder .video-button {
  display: block;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  color: #000000;
  font-size: 50px;
  position: absolute;
  top:50%;
  left:50%;
  padding-left: 10px;
  transform: translate(-50% , -50%);
}
.our-blog .single-blog-post:hover .img-holder .video-button {color: #fff;}
.our-blog .post-data .date {font-size: 20px;color: #b5b5b5;text-transform: capitalize;}
.our-blog .post-data .date:hover {color: #363636;text-decoration: underline;}
.our-blog .post-data .blog-title-one {
  font-family: 'CircularStdmed';
  font-size: 24px;
  line-height: 35px;
  color: #3e3e3e;
}
.our-blog .post-data .blog-title-two {
  font-family: 'CircularStdmed';
  font-size: 28px;
  line-height: 40px;
}
.our-blog .post-data .title a {color: #3e3e3e;}
.our-blog .post-data p {color: #76797E;line-height: 30px;}
.our-blog .post-data .read-more {
  font-size: 35px;
  color: rgba(0,0,0,0.25);
  line-height: 20px;
}
.our-blog .post-data .read-more:hover {color: #5e5e5e;}
.our-blog .bg-solid-post.single-blog-post .post-data {
  padding: 100px 65px 70px;
  text-align: center;
  position: relative;
  background: #ff4c57;
  z-index: 1;
}
.our-blog .bg-solid-post.single-blog-post .post-data:before {
  content: url(./assets/images/icon/icon58.svg);
  position: absolute;
  left:50%;
  top:0;
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0;
  transition: all 1s ease-in-out;
}
.our-blog .bg-solid-post.single-blog-post.show-pr .post-data:before {top:45px;opacity: 1;}
.our-blog .bg-solid-post.single-blog-post .post-data .title {
  font-family: 'CircularStdmed';
  font-size: 36px;
  line-height: 50px;
  margin: 0 0 38px;
}
.our-blog .bg-solid-post.single-blog-post .post-data .title a {color: #fff;}
.our-blog .bg-solid-post.single-blog-post .post-data .title a:hover {
  text-shadow: 0px 0px 5px rgba(255,255,255,0.3);
}
.our-blog .bg-solid-post.single-blog-post .post-data .author {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.blog-masonry .single-blog-post .post-data {
  padding: 28px 20px 18px 30px;
  border: 1px solid #ededed;
  border-top: none;
}
.blog-masonry .single-blog-post {margin-bottom: 35px;}
.blog-masonry .single-blog-post .title {margin: 18px 0 15px;}
.blog-default .single-blog-post .post-data {padding-top: 45px;}
.blog-default .single-blog-post .post-data .title {margin: 15px 0 20px;}
.blog-default .single-blog-post .post-data .read-more {margin-top: 26px;}
.blog-default .single-blog-post {margin-bottom: 90px;}
.blog-default .single-blog-post.blog-text-style .post-data {
  border: 1px solid #ededed;
  padding: 45px 55px 35px;
}
.blog-filter-title {text-align: center;padding: 270px 0 100px;}
.blog-filter-title .upper-title {
  font-family: 'CircularStdmed';
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(62,62,62,0.3);
  padding-bottom: 45px;
}
.blog-filter-title .main-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  line-height: 60px;
  color: #313131;
}
.blog-filer {padding-left: 50px;padding-right: 50px;}
.blog-filer .blog-filter-nav {border-bottom: 1px solid #5d5d5d;margin-bottom: 80px;}
.blog-filer .blog-filter-nav li {
  font-family: 'CircularStdmed';
  float: left;
  width: 16.66666666%;
  text-align: center;
  margin: 15px 0 0;
  font-size: 18px;
  color: rgba(62,62,62,0.4);
  position: relative;
  padding-bottom: 20px;
}
.blog-filer .blog-filter-nav li span {cursor: pointer;}
.blog-filer .blog-filter-nav li.is-checked {color: #3e3e3e;}
.blog-filer .blog-filter-nav li:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-top: 1px solid #5d5d5d;
  border-right: 1px solid #5d5d5d;
  transform: rotate(135deg);
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.blog-filer .blog-filter-nav li.is-checked:before {opacity: 1;bottom: -11px;}
.blog-filer .masnory-blog-wrapper .grid-sizer,
.blog-filer .masnory-blog-wrapper .isotop-item {width: 25%;padding: 0 25px;}
.our-blog.blog-filer .post-data {padding-top: 28px;}
.our-blog.blog-filer .post-data .blog-title-one {font-size: 26px;margin: 15px 0 16px;}
.our-blog.blog-filer .post-data .read-more {margin-top: 15px;}
.our-blog.blog-filer .single-blog-post {margin-bottom: 100px;}
.our-blog  .full-grid-container {max-width: 1030px;margin: 0 auto; padding: 0 15px;}
.our-blog .full-grid-container .bg-solid-post.single-blog-post .post-data {padding-left: 90px;padding-right: 90px;}
/*--------------------- Blog Details --------------------*/
.blog-details .post-data {padding: 40px 0 60px;}
.blog-details .post-data .title {padding: 15px 0 20px;}
.blog-details .post-data p {padding-bottom: 30px;}
.blog-details .post-data .quote-one {
  font-size: 32px;
  line-height: 45px;
  margin: 42px 0 60px;
  color: #3e3e3e;
  position: relative;
  padding-left: 90px;
}
.blog-details .post-data .quote-one:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #3e3e3e;
  position: absolute;
  top:17px;
  left:0;
}
.blog-details .post-data .bold-text {
  font-family: 'CircularStdmed';
  font-size: 20px;
  line-height: 32px;
  color: #3e3e3e;
  padding-bottom: 30px;
}
.blog-details .post-tag-area .tags li {
  display: inline-block;
  font-size: 20px;
  color: #3e3e3e;
}
.blog-details .post-tag-area .tags li:first-child {font-family: 'CircularStdmed';}
.blog-details .post-tag-area .tags li a {font-size: 18px;color: #989ca2;}
.blog-details .post-tag-area .tags li a:hover {color: #3e3e3e;}
.blog-details .post-tag-area .share-icon li {
  font-family: 'CircularStdmed';
  display: inline-block;
  font-size:20px;
  color: #3e3e3e;
}
.blog-details .post-tag-area .share-icon li a {
  font-size:20px;
  color: #d3d3d3;
  margin-left: 13px;
}
.blog-details .post-tag-area ul {padding-bottom: 20px;}
.blog-details .post-tag-area {border-bottom: 1px solid #e5e5e5;padding-bottom: 10px;}
.blog-details .inner-block-title {
  font-family: 'CircularStdmed';
  font-size: 36px;
  color: #3e3e3e;
  padding-bottom: 28px;
}
.blog-details .user-comment-area .single-comment .user-comment-data .date {
  font-style: italic;
  color: #989ca2;
}
.blog-details .user-comment-area .single-comment {
  padding: 50px 0 40px;
  border-bottom: 1px solid #e5e5e5;
}
.blog-details .user-comment-area .single-comment.comment-reply {margin-left: 100px;} 
.blog-details .user-comment-area .single-comment .reply {
  font-family: 'CircularStdmed';
  width: 70px;
  height: 30px;
  border:2px solid #e7e7e7;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
  color: #3e3e3e;
  position: absolute;
  top:0;
  right: 0;
  background: transparent;
}
.blog-details .user-comment-area .single-comment .reply:hover {color: #fff;}
.blog-details .comment-form-area form {padding-top: 55px;}
.blog-details .comment-form-area form input,
.blog-details .comment-form-area form textarea {
  border:1px solid #dcdcdc;
  width: 100%;
  max-width: 100%;
  margin-bottom: 35px;
  color: #989ca2;
}
.blog-details .comment-form-area form input:focus,
.blog-details .comment-form-area form textarea:focus {border-color: #383838;}
.blog-details .comment-form-area form input {height: 60px;padding: 0 25px;}
.blog-details .comment-form-area form textarea {
  height: 260px;
  max-height: 240px;
  padding: 25px;
}
.blog-details .comment-form-area form ::placeholder {color: #989ca2;;opacity: 1;}
.blog-details .comment-form-area form :-ms-input-placeholder {color: #989ca2;;}
.blog-details .comment-form-area form ::-ms-input-placeholder {color: #989ca2;;}
.blog-details .blog-hero-banner {
  padding: 480px 0 220px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  animation: imageBgAnim 50s infinite linear alternate;
}
.blog-details .blog-custom-container {
  max-width: 980px;
  padding: 0 15px;
  margin: 0 auto;
}
.blog-details .blog-hero-banner .date {font-size: 20px;color: #fff;margin-bottom: 30px;}
.blog-details .blog-hero-banner .blog-title {
  font-family: 'CircularStdmed';
  font-size: 48px;
  line-height: 55px;
  color: #fff;
  width: 70%;
}
.blog-details-fg .blog-fg-data .post-data {padding: 0;}
.blog-details-fg .blog-fg-data {
  position: relative;
  z-index: 1;
  margin-top: -150px;
}
.blog-details-fg .blog-fg-data .custom-container-bg {padding: 0 75px 0; background: #fff;}
.blog-details-fg .blog-fg-data .post-data .sub-heading {
  font-family: 'CircularStdmed';
  font-size: 28px;
  color: #3d3d3d;
  padding: 30px 0 25px;
}
.blog-details-fg .blog-fg-data .blog-img-gallery {padding: 50px 0 80px;}
.blog-details-fg .blog-fg-data .blog-img-gallery img {width: 100%;}
.blog-details-fg .blog-fg-data .video-banner-blog {
  background: url(./assets/images/blog/34.jpg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  height: 615px;
  position: relative;
  margin: 100px 0 105px;
}
.blog-details-fg .blog-fg-data .video-banner-blog .video-button {
  display: block;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  color: #000000;
  font-size: 50px;
  position: absolute;
  top:50%;
  left:50%;
  padding-left: 10px;
  transform: translate(-50% , -50%);
}
.blog-details-fg .blog-fg-data .video-banner-blog .video-button:hover {color: #fff;}
.blog-details .post-data .quote-two {
  display: inline-block;
  font-size: 23px;
  line-height: 32px;
  font-style: italic;
  margin: 0 0 45px;
  color: #3e3e3e;
  position: relative;
  padding-right: 90px;
}
.blog-details .post-data .quote-two span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 100px;
  height: 2px;
  background: #535353;
}
.blog-details.blog-details-fg .user-comment-area .single-comment {border-bottom: none;padding-bottom: 30px;}
.blog-details-fg .comment-form-area {background: #f8fcff;padding: 130px 0 140px;}
.blog-details-fg .comment-form-area .custom-container-bg {background: transparent;}
.blog-details.blog-details-fg .comment-form-area form input,
.blog-details.blog-details-fg .comment-form-area form textarea {
  border: none;
  border-bottom: 2px solid #383838;
  background: transparent;
}
.blog-details.blog-details-fg .comment-form-area form input {padding: 0;}
.blog-details.blog-details-fg .comment-form-area form textarea {padding: 25px 0;}
.blog-details.blog-details-fg .comment-form-area form ::placeholder {color: #b8b8b8;;opacity: 1;}
.blog-details.blog-details-fg .comment-form-area form :-ms-input-placeholder {color: #b8b8b8;;}
.blog-details.blog-details-fg .comment-form-area form ::-ms-input-placeholder {color: #b8b8b8;;}
/*------------------- Contact us -------------------*/
#contact-form .form-group {position: relative;margin-bottom: 22px;}
#contact-form .form-group input,#contact-form .form-group textarea {
  border: 1px solid #ebebeb;
  width: 100%;
  max-width: 100%;
  color: #989ca2;
  background: transparent;
}
#contact-form .form-group input:focus,#contact-form .form-group textarea:focus {border-color: #545454;}
#contact-form .form-group ::placeholder {color: #989ca2;;opacity: 1;}
#contact-form .form-group :-ms-input-placeholder {color: #989ca2;;}
#contact-form .form-group ::-ms-input-placeholder {color: #989ca2;;}
#contact-form .form-group input {height: 60px;padding: 0 25px;}
#contact-form .form-group textarea {
  height: 190px;
  max-height: 190px;
  resize:none;
  padding: 20px 25px;
}
#contact-form .form-group .help-block {
  position: absolute;
  left: 0;
  bottom: -12px;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  padding: 0 15px;
  border-radius: 3px;
  box-shadow: 0px 10px 25px 0px rgba(123,147,171,0.15);
}
#contact-form .form-group .help-block li {position: relative;}
#contact-form .form-group .help-block li:before {
  content: '';
  font-family: 'font-awesome';
  position: absolute;
  top:-12px;
  left:0;
}
#contact-form button {margin-top: 35px;}
.contact-us-section .contact-info {padding-left: 100px;}
.contact-us-section .contact-info .title {
  font-family: 'CircularStdmed';
  font-size: 42px;
  line-height: 55px;
  color: #3e3e3e;
  margin: -8px 0 25px;
}
.contact-us-section .contact-info p {font-size: 20px;color: #798598;}
.contact-us-section .contact-info .call {
  font-size: 27px;
  color: #3e3e3e;
  margin: 25px 0 40px;
}
.contact-us-section .contact-info .call:hover {text-decoration: underline;}
.contact-us-section .contact-info ul li {display: inline-block;}
.contact-us-section .contact-info ul li a {
  width: 40px;
  line-height: 36px;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  color: #dbdbdb;
  margin-right: 5px;
}
.contact-us-section .contact-info ul li a:hover {color: #fff;}
#google-map {height: 700px;}
#google-map-two {margin: 170px 70px 0;height: 625px;}
#google-map-three {height: 100%;}
.map-canvas {height: 100%;}
#contact-form.form-style-two .form-group input,
#contact-form.form-style-two .form-group textarea {
  border: none;
  border-bottom: 1px solid #ebebeb;
  padding-left: 0;
  padding-right: 0;
}
#contact-form.form-style-two .form-group input:focus,#contact-form.form-style-two .form-group textarea:focus {border-bottom-color: #545454;}
.contact-address-two {
  text-align: center;
  padding: 250px 0 150px;
}
.contact-address-two .theme-title-one .upper-title {color: rgba(147,155,169,0.5);}
.contact-address-two .theme-title-one {padding-bottom: 50px;}
.contact-address-two .address-block {padding-top: 40px;}
.contact-address-two .address-block .icon-box {display: inline-block;height: 70px;}
.contact-address-two .address-block h5 {font-size: 24px;padding: 18px 0 20px;}
.contact-address-two .address-block p,.contact-address-two .address-block p a {color: #939ba9;}
.contact-address-two .address-block ul li {display: inline-block;margin: 10px 8px 0;}
.contact-address-two .address-block ul li a {font-size: 20px;color: #d3d3d3;}
.contact-minimal .inner-wrapper {background: #2f2f2f;padding: 60px 15px 80px 100px;}
.contact-minimal .inner-wrapper .contact-form {max-width: 585px;}
#contact-form.form-style-three .form-group ::placeholder {color: #fff;;opacity: 1;}
#contact-form.form-style-three .form-group :-ms-input-placeholder {color: #fff;;}
#contact-form.form-style-three .form-group ::-ms-input-placeholder {color: #fff;;}
#contact-form.form-style-three .form-group input,#contact-form.form-style-three .form-group textarea {
  border: none;
  border-bottom: 2px solid #ffffff;
  padding-left: 0;
  padding-right: 0;
  color: #fff;
}
.form-style-three .send-button {
  font-family: 'CircularStdmed';
  width: 193px;
  line-height: 51px;
  border: 2px solid #fff;
  font-size: 17px;
  color: #fff;
  background: transparent;
}
/*-------------------- Error Page --------------------*/
.page-404 {height: 100vh;position: relative;}
.page-404 .main-page-wrapper, .error-content {height: 100%;position: relative;}
.error-content:before {
  content: '';
  position: absolute;
  right: 0;
  top:0;
  z-index: -1;
  width: 40%;
  height: 100%;
  background: url(./assets/images/home/404.png) no-repeat;
  background-size: cover;
}
.error-content .inner-wrapper {
  width: 60%;
  text-align: center;
  padding: 95px 0 50px;
  position: relative;
  top:50%;
  transform: translateY(-50%);
}
.error-content .inner-wrapper h2 {font-size: 200px;color: #313131;}
.error-content .inner-wrapper p {
  font-size:25px;
  line-height: 42px;
  margin:-30px 0 50px;
}
.error-creative-content {
  background: url(./assets/images/home/error-bg.svg) no-repeat center;
  background-size: cover;
  height: 100%;
}
.error-creative-content .inner-wrapper {
  text-align: center;
  padding: 35px 0 50px;
  position: relative;
  top:50%;
  transform: translateY(-50%);
}
.error-creative-content .inner-wrapper h2 {font-size: 70px;color: #302c48;}
.error-creative-content .inner-wrapper p {
  font-size: 24px;
  line-height: 35px;
  color: rgba(62,62,62,0.55);
  margin: 35px 0 45px;
}
.error-creative-content .inner-wrapper .solid-button-one:hover {background: transparent;}
/*----------------- Creative Project -----------------*/
.project-creative {padding-top: 50px;color: #76797E; position: relative;}
.project-creative .back-button {
  position: fixed;
  top:10px;
  right: 15px;
  z-index: 99;
}
.project-creative .back-button .icon {margin: 0;}
.project-creative .slideshow {
  position: relative;
  overflow: hidden;
  margin: 0;
  height: 100vh;
  width: 100%;
  height: calc(100vh - 10rem);
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-column-gap: 0.5%;
  grid-template-rows: 100%;
  grid-template-areas: '... slide ...';
}
.project-creative .slide {
  width: 100%;
  display: flex;
  pointer-events: none;
  cursor: pointer;
  position: relative;
  height: 100%;
  grid-area: slide;
}
.project-creative .slideshow--previewopen .slide {cursor: default;}
.project-creative .slide--current {pointer-events: auto;}
.project-creative .slide__img-wrap {
  width: 100%;
  overflow: hidden;
  z-index: 100;
  height: 80%;
  top: 10%;
  position: absolute;
}
.project-creative .slideshow__deco {
  grid-area: slide;
  background: #f8f8f8;
  width: 100%;
  height: 80%;
  align-self: center;
  position: relative;
  margin: -40px 0 0 0;
  right: -20px;
}
.project-creative .nav {
  position: absolute;
  background: none;
  width: 48px;
  height: 48px;
  z-index: 1000;
  border: 0;
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 30px;
  pointer-events: none;
  transition: transform 0.8s, opacity 0.8s;
  transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}
.project-creative .nav--next {bottom: 10px;right: 10px;}
.project-creative .icon--navarrow-next {transform: rotate(45deg);}
.project-creative .nav--prev {top: 10px;left: 10px;}
.project-creative .icon--navarrow-prev {transform: rotate(-135deg);}
.project-creative .slideshow--previewopen .nav {opacity: 0;transition-duration: 0.4s;}
.project-creative .slideshow--previewopen .nav--next {transform: translate3d(100%, 100%, 0);}
.project-creative .slideshow--previewopen .nav--prev {transform: translate3d(-100%, -100%, 0);}
.project-creative .slide__img {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  pointer-events: none;
  transform: scale3d(1.01,1.01,1);
}
.js .slide__img-wrap, 
.js .slide__title-wrap,
.js .slide__side {opacity: 0;pointer-events: none;}
.js .slide--current .slide__img-wrap {
  opacity: 1;
  pointer-events: auto;
}
.project-creative .slide--visible .slide__img-wrap {pointer-events: auto;}
.project-creative .slide__title-wrap {
  justify-self: flex-end;
  width: 100%;
  position: relative;
  z-index: 1000;
}
.project-creative .slide__number {
  display: block;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
}
.project-creative .slide__title {font-family: 'CircularStdmed';color: #fff;}
.project-creative .slide__number::before {
  content: "\2014";
  display: inline-block;
  margin: 0 1rem 0 0;
}
.project-creative .slide__title,
.project-creative .slide__subtitle,
.project-creative .slide__side {display: none;}
.project-creative .content {
  position: fixed;
  top: 10rem;
  left: 0;
  width: 100%;
  height: calc(100% - 10rem);
  pointer-events: none;
  z-index: 100;
}
.project-creative .content__item {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 10vh 5vw;
  overflow: auto;
}
.project-creative .content__item--current,
.project-creative .content__item--current ~ .content__close {
  pointer-events: auto;
}
.project-creative .content__close {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 30px;
}
.project-creative .content__close:focus {outline: none;}
.project-creative .content__number {
  color: #212121;
  font-weight: bold;
}
.project-creative .content__number::before {
  content: "\2014";
  display: inline-block;
  margin: 0 1rem 0 0;
}
.project-creative .content__title {
  font-family: 'CircularStdblck';
  margin: 10px 0;
  font-size: 40px;
  color: #212121;
}
.project-creative .content__subtitle {
  margin: 0 0 5px;
  font-size: 16px;
  color: #777;
}
.js .content__title,
.js .content__subtitle,
.js .content__number,
.js .content__text,
.js .content__close {
  opacity: 0;
}
@media screen and (min-width: 53em) {
.project-creative {padding: 0;}
.project-creative  .slideshow {
  height: 100vh;
  grid-template-columns: 27% 27% 27%;
  grid-column-gap: 9.5%;
}
.project-creative  .slide {
  padding: 10vh 0 7vh;
  flex-direction: column;
  justify-content: space-between;
}
.project-creative  .slide__side {margin: 0 0 0 -30px;}
.project-creative  .slide__title-wrap {margin: 0 0 0 20px;}
.project-creative  .slide__title,
.project-creative  .slide__subtitle,
.project-creative  .slide__side {display: block;}
.project-creative  .slide__title {font-size: 48px;margin: 0 0 5px;}
.project-creative  .slide__subtitle {font-size: 15px;}
.project-creative  .slide__side {
    font-size: 15px;
    position: relative;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    z-index: 1000;
  }
.project-creative  .content {
    top: 0;
    height: 100%;
  }
.project-creative  .content__item {
    padding: calc(10vh + 5rem) 0 7vh;
    width: 50.5%;
    right: 7.5%;
    overflow: visible;
  }
.project-creative  .content__close {
    left: 42%;
    top: calc(10vh + 1rem);
  }
.project-creative  .content__number {
    position: absolute;
    bottom: 7vh;
    right: 0;
    font-size: 2rem;
  }
.project-creative  .content__title {font-size: 100px;}
.project-creative  .content__subtitle {margin-bottom: 7.5vh;}
.project-creative  .content__text {
    column-count: 2;
    column-gap: 30px;
    max-width: 700px;
    text-align: justify;
  }
}
/*------------------------------ Sign Up ----------------------------*/
.signUp-illustration {
  position: fixed;
  top:0;
  left:0;
  bottom: 0;
  width: 38%;
  background: #f8faff;
  z-index: -1;
}
.signUp-illustration img {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50% , -50%);
}
.signUp-page {position: relative;min-height: 100vh;z-index: 5;}
.signUp-minimal {padding-left: 15px;padding-right: 15px;}
.signUp-minimal .sign-up-form-wrapper {
  max-width: 650px;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  padding: 70px 80px 60px;
  position: relative;
}
.signUp-page .shape-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 0;
}
.signUp-page .shape-wrapper span {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.signUp-page .shape-wrapper span:nth-child(1) {
  background: #ff9898;
  top:0;
  left:20%;
  animation: scale-upOne 3.9s alternate infinite ease-in;
}
.signUp-page .shape-wrapper span:nth-child(2) {
  background: #abafff;
  top:14%;
  right:7%;
  animation: scale-upOne 6s alternate infinite ease-in;
}
.signUp-page .shape-wrapper span:nth-child(3) {
  background: #67f5e2;
  top:48%;
  right:62%;
  margin-right: -13px;
  animation: scale-upOne 5s alternate infinite ease-in;
}
.signUp-page.signUp-minimal .shape-wrapper span:nth-child(3) {right: 88%;}
.signUp-page .shape-wrapper span:nth-child(4) {
  background: #ffb3b3;
  bottom:5%;
  right:25%;
  animation: scale-upOne 4s alternate infinite ease-in;
}
.signUp-page .shape-wrapper span:nth-child(5) {
  background: #ffe281;
  bottom: 4%;
  left:5%;
  animation: scale-upOne 5s alternate infinite ease-in;
}
.signUp-page .title-area p {font-size: 20px;padding: 5px 0 35px;}
.signUp-page .social-icon-wrapper a {
  display: block;
  text-align: center;
  line-height: 55px;
  font-size:20px;
  color: #fff;
  margin-top: 10px;
}
.signUp-page .social-icon-wrapper .gmail {background: #d93025;}
.signUp-page .social-icon-wrapper .facebook {background: #4267b2;}
.signUp-page .social-icon-wrapper a .fa {font-size: 18px;margin-right: 5px;vertical-align: 1px;}
.signUp-page .or-text {position: relative;text-align: center;margin: 50px 0 45px;}
.signUp-page .or-text:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #e2e2e2;
  left:0;
  top:14px;
  z-index: -1;
}
.signUp-page .or-text span {
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  color: #454545;
  background: #fff;
  width: 50px;
}
.signUp-page form ::placeholder {color: #bcbcbc;opacity: 1; /* Firefox */}
.signUp-page form :-ms-input-placeholder {color: #bcbcbc;}
.signUp-page form ::-ms-input-placeholder {color: #bcbcbc;}
.signUp-page form .input-group {position: relative;z-index: 1; margin-bottom: 35px;}
.signUp-page form .input-group input {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: transparent;
  height: 60px;
  font-size: 18px;
  color: #454545;
  position: relative;
  padding: 0 20px;
}
.signUp-page form .input-group label {
  font-weight: normal;
  font-size: 15px;
  color: #bcbcbc;
  line-height: 60px;
  position: absolute;
  left:20px;
  top:0;
  z-index: -1;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.signUp-page form .input-group input:focus ~ label,
.signUp-page form .input-group input:valid ~ label {top: -12px;}
.signUp-page form .input-group input:focus {border-color: #393939;}
.signUp-page form .input-group input:focus,
.signUp-page form .input-group input:valid {padding-top: 18px;}
.signUp-page .selectize-control {width: 100%;}
.signUp-page .input-group .selectize-input {
  width: 100%;
  line-height: 60px;
  height: 60px;
  border-radius: 5px;
  border: none;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  outline: none;
  padding: 0 20px;
  background: transparent;
}
.signUp-page .input-group .selectize-input input {font-size: 15px;}
.signUp-page .selectize-input .item {font-size: 18px;color: #454545;}
.signUp-page .selectize-dropdown {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-top: none;
  box-shadow: 0px 50px 100px 0px rgba(229, 232, 235, 0.2);
  cursor: pointer;
}
.signUp-page .selectize-dropdown .option {font-size: 14px;color: #1a1a1a;line-height: 22px;}
.signUp-page .selectize-dropdown .option:hover,
.signUp-page .selectize-dropdown .active {color: #fff; background:#1a1a1a; }
.signUp-page .selectize-control.single .selectize-input:after {right: 8px;}
.signUp-page .acType-content h4 {font-size: 28px;}
.signUp-page .acType-content p {padding: 8px 0 25px;}
.signUp-page .acType-content .acType-list {margin: 0 -22px;}
.signUp-page .acType-content .acType-list li {float: left;padding: 0 22px;}
.signUp-page .acType-content .acType-list li>div {position: relative;}
.signUp-page .acType-content .acType-list li>div input {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.signUp-page .acType-content .acType-list li>div label {
  position: relative;
  font-size: 16px;
  line-height: 15px;
  color: rgba(47,52,62,0.8);
  cursor: pointer;
  padding-left: 25px;
  margin: 0 0 15px;
  transition: all 0.1s ease-in-out;
}
.signUp-page .acType-content .acType-list li>div label:before {
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #d6d6d6;
  position: absolute;
  left:0;
  top:0;
  transition: all 0.1s ease-in-out;
}
.signUp-page .acType-content .acType-list li>div input:checked + label:before {border-color: #357694;}
.signUp-page .acType-content .acType-list li>div input:checked + label {color:rgba(47,52,62,1); }
.signUp-page .acType-content {border-bottom: 1px solid #e0e0e0;padding-bottom: 65px;}
.signUp-page .agreement-checkbox label {
  position: relative;
  font-size: 16px;
  color: rgba(47,52,62,0.6);
  cursor: pointer;
  padding-left: 22px;
  margin: 30px 0 35px;
  transition: all 0.1s ease-in-out;
}
.signUp-page .agreement-checkbox input[type="checkbox"] {display: none;}
.signUp-page .agreement-checkbox label:before {
  content: '';
  width: 12px;
  height: 12px;
  line-height: 10px;
  border-radius: 2px;
  border: 1px solid #d5d5d5;
  font-size: 8px;
  text-align: center;
  position: absolute;
  left:0;
  top:6px;
  transition: all 0.1s ease-in-out;
}
.signUp-page .agreement-checkbox input[type="checkbox"]:checked + label:before {
  content: "";
  font-family: 'font-awesome';
  background: #373737;
  color: #fff;
  border-color:  #373737;
}
.signUp-page .agreement-checkbox input[type="checkbox"]:checked + label {color:#373737; }
.signUp-page.signUp-minimal .agreement-checkbox label {margin-top: 0;}
.signUp-page.signUp-minimal form .line-button-one {width: 100%;border-radius: 5px;}
.signin-form-wrapper .title-area {margin-bottom: 50px;}
.signUp-standard .signin-form-wrapper #login-form .input-group input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 0;
}
.signUp-standard .signin-form-wrapper #login-form .input-group label {left: 0;}
.signUp-standard .signin-form-wrapper #login-form {padding-right: 170px;}
#login-form .agreement-checkbox label {margin: 0;}
#login-form .agreement-checkbox a {font-size: 16px;color: rgba(47,52,62,0.6);}
#login-form .agreement-checkbox a:hover {color: #212121;}
#login-form .agreement-checkbox  {margin-bottom: 55px;}
.signin-form-wrapper .signUp-text {font-size: 20px;padding-top: 40px;}
#login-form button {text-transform: uppercase;}
.signUp-minimal .signin-form-wrapper {
  max-width: 520px;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  padding: 70px 65px 35px;
  position: relative;
}
.signUp-minimal .signin-form-wrapper .signUp-text {font-size: 17px;padding-top: 35px;}
/*------------------------------- Shortcode style ---------------------------------*/
.element-section .highlight {
  background: #f7f7f9;
  padding: 40px 15px;
  margin: 30px 0;
}
.highlight .s {color: #e83e8c;}
.highlight .na {color: #4f9fcf;}
.highlight .nt {color: #2f6f9f;}
.highlight .c  {color: #999;}
.element-tile {
  font-size: 28px;
  color: #313131;
  margin-bottom: 22px;
  text-transform: capitalize;
}
.element-tile-two {
  font-size: 36px;
  color: #313131;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 70px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.button-style button {display: inline-block;margin: 35px 0  0;}
.element-section .section-portfo {background: #1b1d3d;padding: 30px 0 150px;}
.element-section .project-minimal-style {padding-top: 0;padding-bottom: 0;}
.element-section .portfo-footer {padding: 150px 0;}
.element-section .portfo-footer:before {display: none;}
.element-section .btn {margin-top:10px;}
/*--------------- Accordion -----------------*/
.faq-tab-wrapper-two .faq-panel .panel .panel-heading .panel-title a {
  display: block;
  padding: 22px 40px 22px 45px;
  font-size: 22px;
  color: #303858;
  position: relative;
}
.faq-tab-wrapper-two .faq-panel .panel {margin-bottom: 20px;border:1px solid rgba(21,21,21,0.06);}
.faq-tab-wrapper-two .faq-panel .panel-body {
  border-top:1px solid rgba(21,21,21,0.06);
  padding: 35px 40px 10px 45px;
}
.faq-tab-wrapper-two .faq-panel .panel-body p {padding-bottom: 25px;}
.faq-tab-wrapper-two .faq-panel .panel .panel-heading .panel-title a:before {
  content: '';
  font-family: 'font-awesome';
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;
  color: #464646;
}
.faq-tab-wrapper-two .faq-panel .panel .panel-heading.active-panel .panel-title a:before {content: '';}
/*Accrodion Three*/
.faq-tab-wrapper-three .faq-panel .panel .panel-heading .panel-title a {
  display: block;
  padding: 20px 40px 20px 25px;
  font-size: 21px;
  color: #303858;
  position: relative;
  background: #f9f9f9;
}
.faq-tab-wrapper-three .faq-panel .panel {margin-bottom: 20px;}
.faq-tab-wrapper-three .faq-panel .panel-body {padding: 35px 40px 10px 25px;}
.faq-tab-wrapper-three .faq-panel .panel-body p {padding-bottom: 25px;}
.faq-tab-wrapper-three .faq-panel .panel .panel-heading .panel-title a:before {
  content: '';
  font-family: 'font-awesome';
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;
  color: #464646;
}
.faq-tab-wrapper-three .faq-panel .panel .panel-heading.active-panel .panel-title a:before {content: '';color: #fff;}
.faq-tab-wrapper-three .faq-panel .panel .panel-heading.active-panel .panel-title a {color: #fff;}
/*Accrodion Four*/
.faq-tab-wrapper-four .faq-panel .panel .panel-heading .panel-title a {
  display: block;
  padding: 20px 10px 20px 60px;
  font-size: 21px;
  color: #303858;
  position: relative;
  background: #f9f9f9;
}
.faq-tab-wrapper-four .faq-panel .panel .panel-heading .panel-title a i {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}
.faq-tab-wrapper-four .faq-panel .panel {margin-bottom: 20px;}
.faq-tab-wrapper-four .faq-panel .panel-body {padding: 40px 15px 30px 15px;}
.faq-tab-wrapper-four .faq-panel .panel .panel-heading.active-panel .panel-title a,
.faq-tab-wrapper-four .faq-panel .panel .panel-heading.active-panel .panel-title a i {color: #fff;}
.faq-panel .panel-body .img-gallery {padding: 25px 0 30px;}
.faq-panel .panel-body .img-gallery img {width: 100%;margin-top: 5px;}
/*------------------- Breadcrumbs ----------------*/
.breadcrumbs-two {background: #fafcff;padding: 265px 0 150px;}
.breadcrumbs-two .page-title {
  font-family: 'CircularStdmed';
  font-size: 55px;
  color: #313131;
}
.breadcrumbs-two .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  color: #244574;
  margin: 0 0 0 3px;
}
.breadcrumbs-two .page-breadcrumbs li a {color: #244574;}

.breadcrumbs-three {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 265px 0 150px;
  position: relative;
  z-index: 1;
}
.breadcrumbs-three .page-title {
  font-family: 'CircularStdmed';
  font-size: 60px;
  padding-bottom: 15px;
}
.breadcrumbs-three .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  margin: 0 3px;
}
.b-wh-text .page-title,.b-wh-text .page-breadcrumbs li a,.b-wh-text .page-breadcrumbs li {color: #fff;}
.breadcrumbs-four {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 265px 0 150px;
  position: relative;
  z-index: 1;
}
.breadcrumbs-four .page-title {font-family: 'CircularStdmed';font-size: 55px;}
.breadcrumbs-four .page-breadcrumbs li {
  display: inline-block;
  font-size: 22px;
  margin: 0 0 0 3px;
}
/*------------------- Counter ------------------*/
.theme-counter-three .single-counter-box {
  text-align: center;
  background: #fff;
  box-shadow: 0px 10px 25px 0px rgba(123,147,171,0.15);
  padding: 40px 5px 50px;
  border-radius: 5px;
  margin-bottom: 40px;
}
.theme-counter-three .single-counter-box .icon {font-size: 45px;padding-bottom: 10px;}
.theme-counter-three .single-counter-box .number {font-size: 60px;}
.theme-counter-three .single-counter-box p {font-size: 20px;}
/*-------------------- List Item -------------------*/
.theme-list-item li {
  font-family: 'Rubik', sans-serif;
  line-height: 44px;
  color: #777777;
  position: relative;
  padding-left: 22px;
}
.theme-list-item li i,.theme-list-item li span {
  position: absolute;
  line-height: 44px;
  left:0;
  top:0;
  color: #313131;
  font-size: 15px;
}
/*----------------------- Footer -------------------*/
/*Footer Two*/
.theme-footer-two {background: #1e202d;padding: 25px 0;}
.theme-footer-two .copy-right {color: rgba(255,255,255,0.7);}
.theme-footer-two .footer-menu li {display: inline-block;}
.theme-footer-two .footer-menu li a {color: #a4a6b0;margin: 5px 8px;}
.theme-footer-two .footer-menu li a:hover {color: #fff;}
.theme-footer-two .social-icon,.theme-footer-two .footer-menu {text-align: right;}
.theme-footer-two .social-icon li {display: inline-block;}
.theme-footer-two .social-icon li a {color: rgba(219,219,219,0.3);margin: 0 0 0 30px;font-size: 20px;}
/*Footer Three*/
.theme-footer-three {text-align: center;padding-bottom: 30px;}
.theme-footer-three .email {color:rgba(87,102,126,0.8); font-size: 20px;margin: 15px 0;}
.theme-footer-three .phone {font-size: 22px;margin-bottom: 30px;}
.theme-footer-three .social-icon li {display: inline-block;margin: 0 8px;}
.theme-footer-three .social-icon li a {
  width: 40px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #dadada;
}
.theme-footer-three .social-icon li a:hover {color: #fff;}
.theme-footer-three .copy-right {
  font-size: 14px;
  color: #798598;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 35px;
}
/*Footer Four*/
.theme-footer-four {background: #1e202d;padding-top: 90px;}
.theme-footer-four .row [class*="col-"] {padding-bottom: 35px;}
.theme-footer-four .title {font-size: 24px;color:#fff;padding-bottom: 25px;}
.theme-footer-four .gallery-widget ul {margin: 30px -6px 0; max-width: 260px}
.theme-footer-four .gallery-widget li {float: left;width: 33.33333%;padding: 0 6px;}
.theme-footer-four .footer-list ul li a {
  line-height: 36px;
  font-size: 17px;
  color: rgba(255,255,255,0.7);
}
.theme-footer-four .email {color:rgba(255,255,255,0.7); font-size: 20px;}
.theme-footer-four .phone {font-size: 22px;margin: 15px 0 25px;}
.theme-footer-four .social-icon li {display: inline-block;margin-right: 10px;}
.theme-footer-four .social-icon li a {
  width: 40px;
  height: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #dbdbdb;
}
.theme-footer-four .social-icon li a:hover {color: #fff;}
.theme-footer-four .bottom-footer {
  border-top: 1px solid rgba(237,237,237,0.05);
  margin-top: 45px;
  padding: 35px 0;
}
.theme-footer-four .bottom-footer p {float: left;color: rgba(255,255,255,0.7);}
.theme-footer-four .bottom-footer ul {float: right;}
.theme-footer-four .bottom-footer ul li {display: inline-block;}
.theme-footer-four .bottom-footer ul li a {
  line-height: 28px;
  color: rgba(255,255,255,0.7);
  margin-left: 15px;
}
/*Footer Five*/
.theme-footer-four.light-v {background: #fff;padding-top: 0;}
.theme-footer-four.light-v .title {
  color: #102622;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.theme-footer-four.light-v .footer-list ul li a,.theme-footer-four.light-v .email,
.theme-footer-four.light-v .bottom-footer p,.theme-footer-four.light-v .bottom-footer ul li a {color: #76797e;}
.theme-footer-four .about-widget p {padding: 0 80px 35px 0;}
.theme-footer-four.light-v .bottom-footer {border-top-color: #ededed;}
/*--------------------- Modal -------------------*/
/*
Modal animation 
*/
.modal .modal-dialog {transform: translate(0, 0); }
.modal .fade-right {animation: fadeInLeft 0.5s;animation-direction: reverse; }
.modal .fade-left {animation: fadeInRight 0.5s;animation-direction: reverse; }
.modal .fade-up {animation: fadeInDown 0.5s;animation-direction: reverse; }
.modal .fade-down {animation: fadeInUp 0.5s;animation-direction: reverse; }
.modal.show .fade-right {animation: fadeInRight 0.5s; }
.modal.show .fade-left {animation: fadeInLeft 0.5s; }
.modal.show .fade-up {animation: fadeInUp 0.5s; }
.modal.show .fade-down {animation: fadeInDown 0.5s; }
.modal-button {width: 100%;}
.btn-white,.form-control {border: 1px solid rgba(0,0,0,0.05);}
.form-control {line-height: 30px;}
.btn-white:hover {color: #fff;}
.modal-content {border: 1px solid rgba(0,0,0,0.05);}
.modal-header {border-bottom: 1px solid rgba(0,0,0,0.05);}
.modal-footer {border-top: 1px solid rgba(0,0,0,0.05);}
.text-sm {font-size: 15px;}
.modal-body form ::placeholder {color: rgba(0,0,0,0.4);opacity: 1; /* Firefox */}
.modal-body form :-ms-input-placeholder { /* Internet Explorer 10-11 */color: rgba(0,0,0,0.4);}
.modal-body form ::-ms-input-placeholder { /* Microsoft Edge */color: rgba(0,0,0,0.4);}
.modal-body .facebook {background: #3f51b5;}
.modal-body .google {background: #f44336;}
.modal-body .twitter {background: #00bcd4;}
.modal-body .s-sign {padding: 5px 15px; color: #fff; font-size: 15px;}
.modal-body .s-sign i {margin-top: 5px;}
.modal-content.bg-dark .modal-title,.modal-content.bg-dark p,.modal-content.bg-dark {color: rgba(255, 255, 255, 0.85);}
.modal-content.bg-dark .modal-header {border-bottom-color: rgba(135, 150, 165, 0.1);}
.modal-content.bg-dark .modal-footer {border-top-color: rgba(135, 150, 165, 0.1);}
.modal-content.bg-dark .btn-white {color: #fff;background: rgba(0,0,0,0.2);}
/*--------------------- Header -------------------*/
.shrt-menu {position: relative;z-index: 99;}
.element-section .shrt-menu {z-index: inherit;}
.shrt-menu .main-header {position: relative;}
.shrt-menu .widget-container {position: relative;}
.shrt-menu .logo a {display: block;position: relative;z-index: 1;}
.shrt-menu .contact-us {margin-left: 35px;}
.shrt-menu.light-bg {background: #fff;box-shadow: 0px 260px 100px 0px rgba(236, 236, 236, 0.2);}
.shrt-menu.dark-bg {background: #222836;}

.text-dark .navbar-nav .nav-item .nav-link {color: #353535;}
.shrt-menu .navbar-nav .nav-item .nav-link {padding: 30px 0;}
.shrt-menu .top-header {border-bottom: 1px solid #ececec;}
.shrt-menu ::placeholder {color: rgba(0,0,0,0.35);opacity: 1;}
.shrt-menu :-ms-input-placeholder {color: rgba(0,0,0,0.35);}
.shrt-menu ::-ms-input-placeholder {color: rgba(0,0,0,0.35);}
.shrt-menu.dark-bg ::placeholder {color: rgba(255,255,255,0.35);opacity: 1;}
.shrt-menu.dark-bg :-ms-input-placeholder {color: rgba(255,255,255,0.35);}
.shrt-menu.dark-bg ::-ms-input-placeholder {color: rgba(255,255,255,0.35);}
.shrt-menu .top-header .infoList li {
  display: inline-block;
  line-height: 52px;
  border-right: 1px solid #ececec;
  padding: 0 35px;
}
.shrt-menu .top-header .infoList li a {color: #2a2a2a;}
.shrt-menu .top-header .infoList li a i {margin-right: 5px;}
.shrt-menu .top-header .right-widget>li {display: inline-block;line-height: 52px;vertical-align: middle;}
.shrt-menu .top-header .right-widget .language-switcher button {
  background: transparent;
  height: 52px;
  text-transform: uppercase;
  color: #233D63;
  font-size: 14px;
  margin: 0 20px 0;
  padding: 0 20px;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.shrt-menu .top-header .right-widget .language-switcher .dropdown-menu {
  min-width: 170px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
  border: 1px solid #f8f8f8;
  border-radius: 0;
}
.shrt-menu .top-header .right-widget .language-switcher .dropdown-menu ul li a {
  display: block;
  padding: 0 15px;
  font-size: 15px;
  color: #767a89;
  line-height: 32px;
  text-transform: uppercase;
}
.shrt-menu .top-header .right-widget .user-profile-action {margin-right: 18px;}
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-toggle img {display: inline-block;}
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-toggle span {
  font-size: 17px;
  color: #414141;
  vertical-align: middle;
  padding-left: 10px;
  line-height: 16px;
}
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu {
  min-width: 220px;
  padding: 20px 25px 25px;
  background: #fff;
  box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
  border: 1px solid #f8f8f8;
  border-radius: 0;
  margin: 0;
}
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li {line-height: 42px;}
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li a {
  font-size: 16px;
  line-height: 42px;
  color: #4b4c51;
  padding-left: 30px;
  position: relative;
}
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li a .icon {
  position: absolute;
  left:0;
  top:11px;
}
.shrt-menu .top-header .right-widget .user-profile-action .dropdown-menu ul li a:hover .icon path {fill: #ff3612;}
.shrt-menu .top-header .right-widget .theme-button-two {line-height: 52px;display: block;}
.header-search {position: relative; height: 40px;}
.header-search input {
  width: 220px;
  height: 100%;
  border:none;
  border-bottom: 1px solid #e6e6e6;
  font-size: 15px;
  color: rgba(0,0,0,0.35);
  padding-right: 20px;
  font-style: italic;
  transform-origin: 100% 0%;
  background: transparent;
}
.header-search .icon {
  position: absolute;
  right: 0;
  top:0;
  bottom: 0;
  width: 20px;
  line-height: 40px;
  color: #2e2e2e;
  cursor: pointer;
}
.header-search.toggle-search input {transform: scale(0,1);}
.header-search.toggle-search.show input {transform: scale(1);}
.shrt-menu .main-header .cart-action-wrapper .dropdown-toggle {position: relative;margin: 0 50px 0 30px;}
.shrt-menu.shrt-menu-four .main-header .cart-action-wrapper .dropdown-toggle,
.shrt-menu.shrt-menu-five .main-header .cart-action-wrapper .dropdown-toggle {margin-right: 13px;}
.shrt-menu .main-header .cart-action-wrapper .dropdown-toggle:after {display: none;}
.shrt-menu .main-header .cart-action-wrapper .dropdown-toggle .item-count {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff3612;
  line-height: 15px;
  text-align: center;
  font-size: 9px;
  color: #fff;
  right: -14px;
  top:-9px;
}
.shrt-menu .main-header .cart-action-wrapper .dropdown-menu {
  padding: 40px 35px;
  min-width: 300px;
  box-shadow: 0px 25px 50px 0px rgba(213, 216, 223, 0.5);
  border: 1px solid #f8f8f8;
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .item-img {
  width: 70px;
  height: 80px;
  display: block;
  float: left;
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .item-info {
  width: calc(100% - 70px);
  float: left;
  padding-left: 25px;
  position: relative;
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .item-info .close {
  position: absolute;
  top:13px;
  right: 0;
  font-size: 12px;
  color: rgba(31,31,31,0.9);
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .item-info .close:hover {color: #ff3612;}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .item-info .name {
  font-size: 18px;
  color: #1f1f1f;
  margin: 5px 0;
  padding-right: 12px;
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .item-info .price {
  font-size: 20px;
  color: #1f1f1f;
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .item-info .price .quantity {
  font-size: 17px;
  color: rgba(31,31,31,0.3);
  margin-left: 15px;
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .selected-item {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.shrt-menu .main-header .cart-action-wrapper .cart-product-list .selected-item:last-child {margin-bottom: 14px;}
.shrt-menu .main-header .cart-action-wrapper .subtotal .title {font-size: 18px;color: #1f1f1f}
.shrt-menu .main-header .cart-action-wrapper .subtotal .total-price {font-size: 20px;color: #1f1f1f}
.shrt-menu .main-header .cart-action-wrapper .subtotal {padding-bottom: 7px;}
.shrt-menu .main-header .cart-action-wrapper .button-group a {
  font-family: 'CircularStdmed';
  display: block;
  line-height: 45px;
  border: 1px solid #dbdbdb;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #1f1f1f;
  margin-top: 15px;
}
.shrt-menu .main-header .cart-action-wrapper .button-group a:hover {color: #fff;}
.shrt-menu .menu-social-icon li {display: inline-block;margin: 0 5px;}
.shrt-menu .menu-social-icon li a {font-size: 22px;color: rgba(0,0,0,0.3);display: block;}
.shrt-menu .menu-social-icon li a:hover {color: rgba(0,0,0,0.8);}
.shrt-menu.shrt-menu-five .menu-social-icon li a {font-size: 20px;}
.shrt-menu.shrt-menu-five .menu-social-icon li {border-left: 1px solid #ececec;margin: 0;width: 45px;text-align: center;}
.shrt-menu .main-header .call-us {font-size: 20px;color: #A2ADBD;}
.shrt-menu .main-header .call-us a {font-size: 24px;margin-left: 15px;}
.shrt-menu-one .main-header {padding:0 40px;}
.shrt-menu-two .main-header {padding-right: 40px;}
.shrt-menu-three .main-header {padding:0 40px 0 10px;}
.shrt-menu.shrt-menu-five .top-header .infoList li {padding: 0 15px 0 0;border:none;}
.shrt-menu.text-light .top-header .infoList li a,
.shrt-menu.text-light .top-header .right-widget .language-switcher button,
.shrt-menu.text-light .top-header .right-widget .user-profile-action .dropdown-toggle span,
.shrt-menu.text-light .top-header .right-widget .user-profile-action .dropdown-toggle:after,
.dark-bg .header-search .icon,.shrt-menu.dark-bg .top-header .right-widget .user-profile-action .dropdown-menu ul li a,
.shrt-menu.dark-bg .top-header .right-widget .language-switcher .dropdown-menu ul li a,.shrt-menu.text-light .navbar .mega-menu-title {color: #fff;}
.shrt-menu.text-light .menu-social-icon li a {color: rgba(255,255,255,0.3);}
.shrt-menu.text-light .menu-social-icon li a:hover {color: rgba(255,255,255,1);}
.shrt-menu.text-light .navbar .dropdown-menu .dropdown-item,
.shrt-menu.text-light .navbar .mega-dropdown-list li a {color: rgba(255,255,255,0.7);}
.shrt-menu.dark-bg .top-header .infoList li {border-right-color:#383f50;}
.shrt-menu.shrt-menu-five.dark-bg .menu-social-icon li {border-left-color:#383f50;}
.shrt-menu.dark-bg .top-header,.shrt-menu.dark-bg .main-header .cart-action-wrapper .cart-product-list .selected-item {border-bottom-color:#383f50;}
.shrt-menu.dark-bg .top-header .right-widget .language-switcher button {border-right-color:#383f50; border-left-color:#383f50;}
.shrt-menu.dark-bg .navbar .dropdown-submenu.dropdown>.dropdown-item:before {border-top-color:#383f50; border-bottom-color:#383f50;}
.dark-bg .header-search input {background: transparent;border-bottom-color:#383f50; color: rgba(255,255,255,0.4);}
.dark-bg .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {border-top-color:#383f50;}
.shrt-menu.dark-bg .top-header .right-widget .language-switcher .dropdown-menu,
.shrt-menu.dark-bg .top-header .right-widget .user-profile-action .dropdown-menu,
.shrt-menu.dark-bg .navbar .dropdown-menu,.shrt-menu.dark-bg .main-header .cart-action-wrapper .dropdown-menu {
  background: #222836;
  box-shadow: none;
  border:1px solid #383f50;
}
.shrt-menu.text-light .main-header .cart-action-wrapper .cart-product-list .item-info .name,
.shrt-menu.text-light .main-header .cart-action-wrapper .cart-product-list .item-info .price,
.shrt-menu.text-light .main-header .cart-action-wrapper .cart-product-list .item-info .close,
.shrt-menu.text-light .main-header .cart-action-wrapper .button-group a,
.shrt-menu.text-light .main-header .cart-action-wrapper .subtotal .title,
.shrt-menu.text-light .main-header .cart-action-wrapper .subtotal .total-price {color: #fff;}
/*----------------------- Tabs -------------------*/
.z-tabs > ul.z-tabs-nav > li.z-disabled {
    filter: alpha(opacity=45);
    opacity: .45;
}
.z-tabs > ul.z-tabs-nav > li.z-disabled a {cursor: default;}
.theme-tab .z-container {border-top:1px solid rgba(0,0,0,0.07);}
.theme-tab .tabs-menu li a {position: relative; transition: all 0.3s ease-in-out;display: block;}
.theme-tab .tabs-menu li a:before {
  content:'' ;
  position: absolute;
  transition: all 0.3s ease-in-out;
}
.theme-tab-basic.theme-tab .tabs-menu li a {padding: 0 5px;line-height: 60px;margin:0 20px;}
.theme-tab-basic.theme-tab .tabs-menu li a {
  font-size: 18px;
  color: #777;
}
.theme-tab-basic.theme-tab .tabs-menu li:first-child a {padding-left: 0; margin-left: 0;}
.theme-tab-basic.theme-tab .tabs-menu li a:before {
  width: 100%;
  height: 3px;
  left: 0;
  bottom: -2px;
  opacity: 0;
  transform: scale(0,1);
}
.theme-tab-basic.theme-tab .tabs-menu li.z-active a:before {opacity: 1;transform: scale(1);}
.theme-tab-basic .z-content-inner {padding: 50px 0 40px;}
.theme-tab .z-content-inner h4,.theme-tab .z-content-inner h5 {padding-bottom: 20px;}
.theme-tab .tabs-menu li a .icon-font {font-size: 15px;}
.theme-tab-basic.theme-tab.vertical .tabs-menu li a {
  margin: 0 0 5px 0;
  padding: 0 15px 0 25px;
  background: #f9f9f9;
  line-height: 70px;
}
.theme-tab-basic.theme-tab.vertical .tabs-menu li a:before {
  width: 4px;
  height: 100%;
  bottom: 0;
}
.theme-tab-basic.theme-tab.vertical .z-content-inner {padding: 0 0 0 50px;}
.theme-tab.vertical .tab-container {border: none;}
.theme-tab .z-content-inner .list-item {margin-top: 30px;}
.theme-tab .z-content-inner .list-item li {
  padding-left: 20px;
  margin-bottom: 15px;
  position: relative;
}
.theme-tab .z-content-inner .list-item li:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left:0;
  top:10px;
}
.theme-tab .z-content-inner ol {margin: 10px 0 0;padding-left: 22px;}
.theme-tab .z-content-inner ol li a {
  line-height: 32px;
  color: #4c4c4c;
  margin-bottom: 12px;
  transition: all 0.3s ease-in-out;
}
.theme-tab .z-content-inner .img-gallery {margin-top: 20px;}
.theme-tab .z-content-inner .img-gallery img {width: 100%;margin-top: 10px;}
.theme-tab-solid.theme-tab .tabs-menu li a {
  font-size: 18px;
  background: #f9f9f9;
  line-height: 60px;
  padding: 0 30px;
  margin:0 3px 3px 0;
  border-radius: 3px 3px 0 0;
}
.theme-tab-solid.theme-tab .tabs-menu li.z-active a {color: #fff;}
.theme-tab-solid.theme-tab .z-container {border: none;background: #f9f9f9;}
.theme-tab-solid.theme-tab .z-container .z-content-inner {padding: 50px 30px 40px;}
.theme-tab-solid.theme-tab.vertical .tabs-menu li a {
  line-height: 75px;
  margin:0 5px 5px 0;
  border-radius: 5px 0 0 5px;
}
.theme-tab .nested-tab-menu li a {
  font-size: 16px;
  line-height: 40px;
  display: block;
  margin: 0 20px;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
.theme-tab .nested-tab-menu li:first-child a {margin-left: 0;}
.theme-tab .nested-tab-menu li a:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left:0;
  opacity: 0;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.theme-tab .nested-tab-menu li.z-active a {opacity: 1;}
.theme-tab .nested-tab-menu li.z-active a:before {opacity: 1;}
.theme-tab-solid.theme-tab .nested-tab .z-container {background: transparent;}
.theme-tab-solid.theme-tab .nested-tab .z-container .z-content-inner {padding: 20px 0 0;}
.theme-tab-solid.theme-tab.tab-dark .tabs-menu li a,.tab-dark.theme-tab.theme-tab-solid .z-container,
.theme-tab.tab-dark .z-container,.theme-tab.z-tabs.mobile.z-shadows.tab-dark > ul.z-tabs-mobile,
.z-tabs.mobile.tab-dark > ul.z-tabs-desktop,.tab-dark .z-tabs.mobile > ul.nested-tab-menu.z-tabs-nav {background: #202245;}

.theme-tab.tab-dark .tabs-menu li a,.theme-tab.tab-dark .z-content-inner h4, .theme-tab.tab-dark .z-content-inner h5,
.theme-tab.tab-dark .z-content-inner p,.theme-tab.tab-dark .z-content-inner .list-item li,
.theme-tab.tab-dark  .nested-tab-menu li a,.theme-tab.tab-dark .z-content-inner ol li a,
.theme-tab.tab-dark .z-content-inner ol li,.z-tabs.mobile.tab-dark > ul.z-tabs-nav > li > .z-link {color: #dcdcdc;}
.theme-tab.tab-dark .z-container {
  border-top:none;
  margin-top: 35px;
}
.theme-tab-basic.tab-dark .z-content-inner {padding: 30px 15px;}
.theme-tab.z-tabs.mobile.z-shadows > ul.z-tabs-mobile {border:1px solid rgba(0,0,0,0.06);}
.z-tabs.mobile > ul.z-tabs-nav > li > .z-link {font-size: 18px;padding: 13px 15px;}
.z-tabs.mobile > ul.z-tabs-mobile > li > a > span.drp-icon  {
    position:  absolute;
    top:  50%;
    font-size: 25px;
    transform: translateY(-50%);
    right: 15px;
}
.z-tabs.mobile > ul.z-tabs-desktop  {
    position:  absolute;
    top:  inherit;
    margin-top:  8px;
    left:  0;
    z-index:  1000;
    width:  100%;
    background: #fff;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0px 13px 35px -12px rgba(35,35,35,0.15);
}
.z-content-inner .z-tabs.mobile > ul.z-tabs-nav {background: #fff;}
.z-content-inner .z-tabs.mobile > ul.z-tabs-nav > li > .z-link {padding:10px 0;padding-left: 15px;}
/*------------------------------ Architecture Home ------------------------------*/
/*---------- Architecture Hero area ------------*/
.arch-hero-area {position: relative;margin: 40px 65px 0;}
.arch-hero-area .shrt-menu {
  padding: 18px 0 0;
  position: absolute;
  width: 100%;
}
.arch-hero-area .shrt-menu .logo {margin-right: 90px;}
.arch-hero-area .header-search input {border-bottom-color: #0b0b0b;}
.arch-hero-area .shrt-menu .main-header .cart-action-wrapper .dropdown-toggle {margin-right: 0;}
#arch-carousel {position: relative;height: calc(100vh - 80px);}
#arch-carousel .carousel-item,#arch-carousel .carousel-inner,#arch-carousel .inner-container {height: 100%;}
#arch-carousel .inner-container {position: relative;}
#arch-carousel .carousel-item {background-size: cover;background-repeat: no-repeat;background-position: top center;padding-top: 90px;}
#arch-carousel .carousel-item .inner-item-wrapper {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  top:50%;
  transform: translateY(-50%);
  z-index: 9;
}
#arch-carousel .inner-item-wrapper .upper-title {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}
#arch-carousel .inner-item-wrapper .main-title {
  font-family: 'CircularStdmed';
  font-size: 78px;
  line-height: 82px;
  color: #191919;
  padding-top: 25px;
}
#arch-carousel .inner-item-wrapper .read-more {font-size: 30px;color: #191919;animation-delay: 0.5s;}
#arch-carousel .inner-item-wrapper .shape {
  position: absolute;
  width: 350px;
  height: 450px;
  border:10px solid #ffffff;
  left: -55px;
  top:-40%;
  z-index: -1;
}
#arch-carousel .details-info-button {
  width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  color: #fff;
  font-weight: 300;
  font-size: 50px;
  position: absolute;
  bottom: 146px;
  right: 705px;
  opacity: 0.95;
  z-index: 9;
  animation-delay: 0.3s;
}
#arch-carousel .indicators-one {
  display: block;
  right: 0;
  margin: 0;
  left: auto;
  bottom: 50%;
  transform: translateY(50%);
}
#arch-carousel .indicators-one li {
  width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-indent: unset;
  text-align: right;
  border: none;
  opacity: 1;
}
#arch-carousel .indicators-one li:before {display: none;}
#arch-carousel .indicators-one li span {
  display: inline-block;
  width: 25px;
  height: 2px;
  background: #000000;
  vertical-align: middle;
}
#arch-carousel .indicators-one li i {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  opacity: 0;
}
#arch-carousel .indicators-one li.active span {width: 50px;}
#arch-carousel .indicators-one li.active i {opacity: 1;}
#arch-carousel .indicators-two {
  display: block;
  right: 0;
  margin: 0;
  left: auto;
  bottom: 0;
}
#arch-carousel .indicators-two li {
  width: 235px;
  height: auto;
  margin: 0;
  padding: 30px 5px 30px 40px;
  cursor: pointer;
  text-indent: unset;
  text-align: left;
  float: left;
  background: #fff;
  border: none;
  opacity: 1;
  border-left: 1px solid #eaeaea;
  box-sizing: border-box;
}
#arch-carousel .indicators-two li>div {
  font-size: 18px;
  color: rgba(36,36,36,0.18);
  font-style: italic;
}
#arch-carousel .indicators-two li .title {
  font-size: 20px;
  color: #242424;
  padding: 5px 0 2px;
}
#arch-carousel .indicators-two li p {color: rgba(36,36,36,0.5);}
/*-------------- MODAL ------------*/
.arch-modal .main-bg-wrapper {
  width: 100%;
  height: 100%;
  padding: 30px 40px 30px;
}
.arch-modal .close-button {
  position: fixed;
  top:20px;
  right: 30px;
  background: transparent;
  font-size: 35px;
  color: rgba(0,0,0,0.6);
  font-weight: normal;
}
.arch-modal .main-bg-wrapper .title {
  font-size: 40px;
  color: #19212b;
}
.arch-modal .main-bg-wrapper h5 {
  font-size: 15px;
  text-transform: uppercase;
  color: #212121;
  padding: 10px 0 55px;
}
.arch-modal .main-bg-wrapper p {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #9e9e9e;
}
.arch-modal .main-bg-wrapper .cart-button {
  line-height: 45px;
  padding: 0 30px;
  color: #fff;
  border-radius: 3px;
  margin-top: 35px;
  font-size: 16px;
}
.arch-modal .next,
.arch-modal .prev {
  position: absolute;
  font-size: 35px;
  color: rgba(0,0,0,0.4);
  background: transparent;
  top:50%;
}
.arch-modal .next {right: 10px;}
.arch-modal .prev {left:10px;}
.arch-modal .next:hover,
.arch-modal .prev:hover {color: #000;}
.iziModal-overlay {background: #fff !important;}
/*---------------------- About ARCH ----------------------*/
.about-arch {padding: 250px 0 300px;position: relative;}
.about-arch:before {
  content: url(./assets/images/home/18.jpg);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.about-arch .text-wrapper {padding: 50px 100px 30px;}
.about-arch .text-wrapper .mark-text {
  font-size: 24px;
  color: #000000;
  line-height: 35px;
  position: relative;
  margin-bottom: 30px;
}
.about-arch .text-wrapper p {font-size: 18px;}
.about-arch .text-wrapper .mark-text:before {
  content: '';
  position: absolute;
  width: 85px;
  height: 2px;
  left: -100px;
  top:17px;
}
.about-arch .text-wrapper .name {
  font-size: 20px;
  padding-left: 30px;
  position: relative;
  margin: 50px 0 40px;
}
.about-arch .text-wrapper .name:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background: #233D63;
  left: 0;
  top:10px;
}
.about-arch .img-box {position: relative;float: right;}
.about-arch .img-box .sq-box {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: -110px;
  left:-110px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  padding-top: 40px;
}
.about-arch .img-box .sq-box  span {
  font-size: 65px;
  font-weight: 300;
}
/*------------------------------ ARCH Project Gallery -----------------------*/
.arch-project-gallery {padding: 40px 0 190px;}
.arch-project-gallery .isotop-menu-wrapper li {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #bfbfbf;
  margin: 5px 25px;
  cursor: pointer;
}
.arch-project-gallery .inner-container {padding: 5px 50px 0;}
.arch-project-gallery #isotop-gallery-wrapper {margin: 0 -25px;}
.arch-project-gallery #isotop-gallery-wrapper .grid-sizer,
.arch-project-gallery #isotop-gallery-wrapper .isotop-item {padding: 0 25px;margin-top: 35px;}
/*--------------------- ARCH Service ----------------------*/
.arch-service .single-block {padding: 40px 50px 0 0;}
.arch-service .single-block .title a {
  font-size: 24px;
  color: #233D63;
  line-height: 30px;
  margin: 45px 0 10px;
}
.arch-service .single-block p {font-size: 18px;}
/*------------------- ARCH Testimonial ----------------*/
.arch-testimonial {
  background: url(./assets/images/home/19.jpg) no-repeat center;
  background-size: cover;
  padding: 125px 15px 100px;
  margin: 180px 65px 180px;
  position: relative;
}
.arch-testimonial:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(255,255,255,0.7);
}
.arch-testimonial .single-block {
  background: #fff;
  padding: 105px 45px 45px;
  position: relative;
  box-shadow: 0px 60px 100px 0px rgba(0, 0, 0, 0.02);
}
.arch-testimonial .single-block:before {
  content: url(./assets/images/icon/icon67.svg);
  position: absolute;
  top:55px;
  left:45px;
}
.arch-testimonial .single-block p {font-size: 20px;color: #000;line-height: 38px;}
.arch-testimonial .single-block .name {
  font-size: 20px;
  color: #000;
  padding-left: 40px;
  position: relative;
  margin: 40px 0 5px;
}
.arch-testimonial .single-block .name:before {
  content: '';
  width: 22px;
  height: 2px;
  position: absolute;
  left: 0;
  top:10px;
  background: #000;
}
.arch-testimonial .single-block span {color: #7e7e7e;padding-left: 40px;}
.arch-testimonial .owl-theme .owl-dots .owl-dot span {
  background: transparent;
  margin: 0 5px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
}
.arch-testimonial .owl-theme .owl-dots {margin-top: 75px;}
/*----------------------- ARCH Blog ------------------------*/
.arch-blog .blog-post {margin-top: 40px;}
.arch-blog .blog-post .img {overflow: hidden;margin-bottom: 32px;background: #000;}
.arch-blog .blog-post .img img {transition: all 0.6s ease-in-out;width: 100%;}
.arch-blog .blog-post:hover .img img {transform: scale3d(1.1,1.1,1); opacity: 0.9;}
.arch-blog .blog-post .date {
  font-size: 16px;
  color: #a2a2a2;
  letter-spacing: 1.3px;
}
.arch-blog .blog-post .title a {
  font-size: 26px;
  line-height: 32px;
  color: #000;
  margin: 15px 0 25px;
}
.arch-blog .blog-post .read-more {line-height: 43px;min-width: 150px;}
/*--------------------- ARCH Contact --------------------*/
.arch-contact {
  background: url(./assets/images/home/20.jpg) no-repeat center;
  background-size: cover;
  height: 650px;
  margin: 180px 65px 180px;
  position: relative;
}
.arch-contact .form-wrapper {
  position: absolute;
  width: 100%;
  left:0;
  bottom: -160px;
  z-index: 5;

}
.arch-contact .form-wrapper .inner-wrapper {
  background: #fff;
  padding: 70px 40px 60px 75px;
  border-bottom: 4px solid #357694;
  box-shadow: 0px 50px 100px 0px rgba(3, 3, 3, 0.02);
}
.arch-contact .form-wrapper form input,
.arch-contact .form-wrapper form textarea {
  width: 100%;
  border:none;
  border-bottom: 1px solid #357694;
  margin-bottom: 30px;
  color: #848484;
}
.arch-contact .form-wrapper form input {padding-bottom: 12px;}
.arch-contact .form-wrapper form textarea {height: 105px; max-height: 105px;resize:none;}
.arch-contact .form-wrapper form button {width: 190px;line-height: 55px;text-transform: uppercase;}
.arch-contact .form-wrapper .contact-text {padding-left: 50px;}
.arch-contact .form-wrapper .contact-text .main-title {font-family: 'CircularStdmed';}
.arch-contact .form-wrapper .contact-text p {
  font-size: 20px;
  color: #959595;
  font-weight: 300;
  padding: 15px 0 10px;
}
.arch-contact .form-wrapper .contact-text .call-us {font-size: 24px;}
/*------------------------- ARCH Footer -----------------------*/
.arch-footer {position: relative;padding-top: 180px;z-index: 1;}
.arch-footer:before {
  content: url(./assets/images/home/3.png);
  position: absolute;
  left:0;
  bottom: 0;
  z-index: -1;
}
.arch-footer .logo {margin-top: 20px;}
.arch-footer .top-footer h2 {margin-bottom: 80px;}
.theme-footer-one.arch-footer .top-footer .title {font-size: 24px;padding: 0 0 25px;}
.theme-footer-one.arch-footer .top-footer .footer-list ul li a {
  font-family: 'Lato', sans-serif;
  color: #9e9e9e;
}
.arch-footer .top-footer .email {color:#9e9e9e; font-size: 17px;}
.arch-footer .top-footer .phone {font-size: 19px;margin: 15px 0 0;}
.theme-footer-one.arch-footer .bottom-footer {
  font-family: 'Lato', sans-serif;
  border: none;
  padding-top: 0;
  margin-top: 55px;
}
.theme-footer-one.arch-footer .bottom-footer p,
.theme-footer-one.arch-footer .bottom-footer ul li a {color: #808080;}

/*-----------------------------------
            STUDIO PORTFOLIO ONE                 
--------------------------------------*/
/*-------------- Header/Menu ------------*/
.eCommerce-side-menu.op-page-menu-one {left: auto;right: 0;transform-origin: 100% 0%;}
.one-page-header-one {
  position: absolute;
  top:0;
  left: 0;
  padding: 60px 0 0 110px;
  z-index: 5;
}
.op-menu-one {
  position: absolute;
  right: 0;
  top:0;
  height: 100%;
  background: #fff;
  border-left:1px solid #E8E8E8;
  border-bottom:1px solid #E8E8E8;
  width: 140px;
  z-index: 99;
  text-align: center;
  padding-top: 55px;
}
.op-menu-one .op-menu-button {display: inline-block; }
.op-menu-one .ct-language__dropdown li {
  display: inline-block;
  font-family: 'K2D', sans-serif ;
  font-size: 24px;
}
.op-menu-one .ct-language__dropdown li a {color: #B3B3B3;}
html[lang="en"] .op-menu-one .ct-language__dropdown li a[data-lang="en"],
html[lang="ru"] .op-menu-one .ct-language__dropdown li a[data-lang="ru"] {color: #202020;}
.op-menu-one .ct-language__dropdown {
  transform: rotate(90deg) translateX(-50%);
  position: absolute;
  top:50%;
  left:0;
  width: 100%;
}
.op-menu-one .social-icon {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
}
.op-menu-one .social-icon li a {
  font-size: 20px;
  color: rgba(0,0,0,0.18);
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}
.op-menu-one .social-icon li a:hover {color: #3a3a3a;}
/*---------------- Banner ---------------------*/
.op-banner-one {
  position: relative;
  padding: 260px 12% 0;
}
.op-banner-one .main-title {
  font-size: 100px;
  font-weight: 500;
  line-height: 105px;
  color: #161616;
}
.op-banner-one .main-title span {position: relative;}
.op-banner-one .main-title span:before {
  content: '';
  width: 100%;
  height: 57%;
  position: absolute;
  top:39px;
  left:0;
  background: rgba(255,221,79,0.3);
  z-index: -1;
}
.op-banner-one .banner-form {
  width: 465px;
  background: #fff;
  position: relative;
  z-index: 1;
  margin-left: auto;
  box-shadow: 0px 10px 19px 12px rgba(35,35,35,0.03);
}
.op-banner-one .banner-form {margin-bottom: -109px;margin-top: 20px;}
.op-banner-one .banner-form .input-group-wrapper {padding: 75px 70px 25px;}
.op-banner-one .banner-form .input-group-wrapper input,
.op-banner-one .banner-form .input-group-wrapper textarea {
  width: 100%;
  border:none;
  border-bottom: 1px solid #3B3B3B;
  margin-bottom: 50px;
  font-size: 18px;
}
.op-banner-one .banner-form .input-group-wrapper input {height: 50px;}
.op-banner-one .banner-form .input-group-wrapper textarea {height: 130px;max-height: 130px;resize:none;}
.op-banner-one .banner-form form ::placeholder {color: #2C2C2C; opacity: 1; /* Firefox */}
.op-banner-one .banner-form form :-ms-input-placeholder {color: #2C2C2C;}
.op-banner-one .banner-form form ::-ms-input-placeholder {color: #2C2C2C;}
.op-banner-one .banner-form form button {
  width: 100%;
  height: 110px;
  font-size: 22px;
  color: #fff;
  background: #1C1C1C;
  text-transform: uppercase;
}
.op-banner-one .credit-tag {
  position: absolute;
  top:360px;
  left:-24px;
  text-align: right;
  transform: rotate(-90deg);
}
.op-banner-one .credit-tag:before {
  content: '';
  width: 2px;
  height: 36px;
  position: absolute;
  top:12px;
  right: -22px;
  background: #212121;
}
.op-banner-one .credit-tag span {text-transform: uppercase;color: #202020;}
/*---------------- One Page Hero Area ----------------*/
#op-hero-one {height: 940px;position: relative;overflow: hidden;}
#op-hero-one .carousel-item,#op-hero-one .carousel-inner {height: 100%;}
#op-hero-one .carousel-item {background-size: cover;}
#op-hero-one .carousel-nav {
  position: absolute;
  width: 140px;
  height: 100%;
  background: #fff;
  border-top:1px solid #E8E8E8;
  border-bottom:1px solid #E8E8E8;
  top:0;
  right: 0;
  z-index: 1;
}
#op-hero-one .carousel-control-next,
#op-hero-one .carousel-control-prev {
  position: static;
  display: block;
  font-size: 18px;
  color: rgba(61,63,66,0.28);
  text-transform: uppercase;
  opacity: 1;
  width: 100%;
  transform: rotate(-90deg);
  margin: 60px 0;
  transition: all 0.3s ease-in-out;
}
#op-hero-one .carousel-nav>div {
  position: absolute;
  width: 100%;
  left:0;
  top:50%;
  transform: translateY(-50%);
}
#op-hero-one .carousel-control-next:hover,
#op-hero-one .carousel-control-prev:hover {color: #3d3f42;}
#op-hero-one .carousel-inner h2 {
  font-family: 'Playfair Display', serif;
  font-size: 520px;
  color: #fff;
  position: absolute;
  left:0;
  bottom: -238px;
}
/*-------------------- About Us -------------------------*/
.op-about-us-one {padding-top:250px;position: relative;}
.op-about-us-one .upper-wrapper {overflow: visible;}
.op-about-us-one .top-text {position: relative;padding: 0 0 75px 60px;}
.op-about-us-one .top-text:before {
  content: '';
  width: 10000px;
  height: 1000px;
  background: linear-gradient( -132.59deg, rgba(255,255,255,0.13) 0%, rgba(216,216,216,0.13) 100%);
  position: absolute;
  z-index: -1;
  left: -60px;
  bottom:0;
}
.op-about-us-one .top-text p {
  font-size: 26px;
  line-height: 42px;
  color: #303030;
}
.op-about-us-one .top-text p:first-letter {
  -webkit-initial-letter: 4 1;
          initial-letter: 4 1;
  font-size: 136px; 
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
  float: left;
}
.op-about-us-one .img-box {display: inline-block;position: relative;}
.op-about-us-one .img-box .text {
  position: absolute;
  width: 100%;
  left: 0;
  top:100%;
  font-size: 24px;
  z-index: 9;
  background: #fff;
  padding: 50px 10px;
  text-align: center;
  color: #242424;
  box-shadow: 0px 30px 50px 0px rgba(138, 138, 138, 0.1);
}

.op-about-us-one .bottom-text {padding: 50px 0 0 60px;}
.op-about-us-one .bottom-text h4 {font-size: 32px;margin-bottom: 25px;}
.op-about-us-one .bottom-text p {padding-bottom: 30px;}
.op-about-us-one .bottom-text .name {
  font-size: 22px;
  margin: 30px 0 60px;
  padding-left: 40px;
  position: relative;
  color: #242424;
}
.op-about-us-one .bottom-text .name:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background: #242424;
  top:50%;
  left: 0;
}
.op-about-us-one .bottom-text .more-button {
  width: 280px;
  line-height: 65px;
  background: #fdf3cb;
  font-size: 18px;
  color: #857A47;
  padding: 0 20px 0 30px;
  margin-top: 60px;
  transition: all 0.3s ease-in-out;
}
.op-about-us-one .bottom-text .more-button i {float: right;font-size: 30px;}
.op-about-us-one .bottom-text .more-button:hover {background: #242424;color: #fff;}
/*------------------------ Project Gallery -----------------*/
.op-project-one {
  position: relative;
  z-index: 1;
  background: #fdfbf6;
  padding: 350px 0 0;
}
.op-project-one .upper-heading p {
  font-size: 24px;
  color:#202020;
  line-height: 36px;
  padding: 12px 85px 0 50px;
}
.op-project-one .inner-wrapper {
  position: relative;
  margin-top: 120px;
  overflow: hidden;
}
.op-project-one .inner-wrapper .work-text {
  position: absolute;
  top:-215px;
  right: 0;
  z-index: 1;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 380px;
  color: #161616;
}
.op-project-one .inner-wrapper .project-text {
  position: absolute;
  bottom: -190px;
  right: 0;
  width: 100%;
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 390px;
  color: #fdfbf6;
}
.op-project-one .inner-wrapper .bg-wrapper {
  background: #fff;
  border-radius: 100px 0 0 0;
  padding: 280px 0 240px;
  z-index: 1;
  max-width: 90vw;
  margin: 0 auto;
}
.op-project-one #isotop-gallery-wrapper {margin: 0 -35px;}
.op-project-one #isotop-gallery-wrapper .grid-sizer,
.op-project-one #isotop-gallery-wrapper .isotop-item {
  width: 50%;
  padding: 0 35px;
  margin-bottom: 70px;
}
.op-project-one #isotop-gallery-wrapper .item-content {position: relative;padding-bottom: 110px;}
.op-project-one #isotop-gallery-wrapper .item-content .img-box {border: 1px solid #ededed;overflow: hidden;}
.op-project-one #isotop-gallery-wrapper .item-content .img-box img {width: 100%;transition: all 0.5s ease-in-out;}
.op-project-one #isotop-gallery-wrapper .item-content:hover .img-box img {transform: scale3d(1.1,1.1,1);}
.op-project-one #isotop-gallery-wrapper .item-content .text {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 100px;
  padding: 50px 35px 42px 60px;
  background: #fff;
  box-shadow: -15.045px 19.966px 40px 0px rgba(240, 240, 240, 0.5);
  transition: all 0.5s ease-in-out;
}
.op-project-one #isotop-gallery-wrapper .item-content:hover .text {left: 15px;}
.op-project-one #isotop-gallery-wrapper .item-content .text h3 {
  font-size: 42px;
  color: #303030;
  margin-bottom: 15px;
}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box {
  width: 70px;
  height: 70px;
  text-align: center;
  color: #fff;
  font-size: 35px;
  line-height: 70px;
  border-radius: 50%;
  position: absolute;
  top:-35px;
  left: 60px;
  z-index: 1;
  box-shadow: 0px 15px 40px 0px rgba(189, 189, 189, 0.5);
}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-one {background: #2b78ff;}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-two {background: #ff4649;}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-three {background: #ff427a;}
.op-project-one #isotop-gallery-wrapper .item-content .text .icon-box.cl-four {background: #10c7ff;}
.op-project-one #isotop-gallery-wrapper .item-content .text .read-more {
    font-family: 'Lato', sans-serif;
    display: block;
    font-weight: 300;
    font-size: 55px;
    color: #202020;
    position: absolute;
    right: 0;
    top:50%;
    transform: translateY(-50%);
}
.op-project-one .more-text {
  max-width: 430px;
  margin-top: -140px;
  position: relative;
  z-index: 1;
}
.op-project-one .more-text span {
  text-transform: uppercase;
  font-size: 15px;
  color: #b5b5b5;
}
.op-project-one .more-text p {
  font-size: 20px;
  color: #000;
  padding: 15px 0 80px;
}
.op-project-one .more-text .more-button {
  display: block;
  background: #fff1b5;
  font-size: 24px;
  color: #292929;
  padding: 40px 20px 40px 30px;
  transition: all 0.3s ease-in-out;
}
.op-project-one .more-text .more-button i {float: right;font-size: 30px;}
.op-project-one .more-text .more-button:hover {background: #242424;color: #fff;}
/*--------------------------- Service -------------------------*/
.op-services-one {padding: 200px 0 180px;}
.op-services-one .title-text {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 290px;
  color: #2c2c2c;
  transform: rotate(90deg);
}
.op-services-one p {
  font-size: 24px;
  line-height: 35px;
  color: #2c2c2c;
  padding: 60px 0 125px;
}
.op-services-one .list-item {padding-bottom: 80px;}
.op-services-one .list-item h6 {
  font-size: 24px;
  font-weight: 700;
  color: #202020;
  padding-bottom: 25px;
}
.op-services-one .list-item ul li {
  font-size: 18px;
  line-height: 35px;
  color: #5e5e5e;
  position: relative;
  padding-left: 20px;
}
.op-services-one .list-item ul li:before {
  content: "+";
  position: absolute;
  left: 0;
  top:0;
  line-height: 35px;
}
/*-------------------------- Testimonial ----------------------*/
.op-testimonial-one {
  position: relative;
  z-index: 1;
  background: #fdfbf6;
  padding: 200px 0 100px;
}
.op-testimonial-one:before {
  content: url(./assets/images/shape/shape-69.svg);
  position: absolute;
  right: 7%;
  top:-100px;
  z-index: 5;
}
.op-testimonial-one .row {margin: 0 -25px;}
.op-testimonial-one .row [class*="col-"] {padding: 0 25px;}
.op-testimonial-one .theme-title-four {margin-bottom: 100px;}
.op-testimonial-one .client-text-box {
  background: #fff;
  padding: 50px 30px 60px 60px;
  box-shadow: 0px 20px 40px 0px rgba(162, 162, 162, 0.05);
  margin-bottom: 65px;
}
.op-testimonial-one .client-text-box img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.op-testimonial-one .client-text-box blockquote {
  font-size: 24px;
  line-height: 38px;
  color: #2c2c2c;
  padding: 30px 0 45px;
  margin: 0;
}
.op-testimonial-one .client-text-box h6 {
  font-size: 24px;
  color: #202020;
  font-weight: 600;
  padding-bottom: 4px;
}
.op-testimonial-one .client-text-box .desg {font-size: 20px;color:#b7b7b7; }
/*----------------- Partner Section -------------------*/
.op-partner-section-one {padding: 130px 0 150px;}
.op-partner-section-one .img-box a {display: block;height: 100%;}
.op-partner-section-one .img-box img {
  position: relative;
  top:50%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.op-partner-section-one .img-box {
  background: #fff;
    border-radius: 50%;
    box-shadow: 15.436px 30.294px 50px 0px rgba(233, 236, 241, 0.5);
    width: 175px;
    height: 175px;
    margin: 0 auto 25px;
    transition: all 0.4s ease-in-out;
}
.op-partner-section-one .img-box:hover {transform: scale(1.1);}
.op-partner-section-one .img-box.bx-b {width: 121px;height: 121px; margin-top: 45px;}
.op-partner-section-one .img-box.bx-d {width: 151px;height: 151px; margin-top: 25px;}
.op-partner-section-one .img-box.bx-f {width: 135px;height: 135px; margin-top: 20px;}
.op-partner-section-one .img-box.bx-g {width: 197px;height: 197px;}
.op-partner-section-one .img-box.bx-h {width: 138px;height: 138px; margin-top: 20px;}
/*-------------------- Footer --------------------*/
.op-footer-one h2 {
  font-size: 72px;
  font-weight: normal;
  line-height: 85px;
  color: #202020;
}
.op-footer-one h2 a {color: #202020;}
.op-footer-one .bottom-text {
  border-top: 1px solid rgba(0,0,0,0.06);
  margin-top: 120px;
  padding: 70px 0;
}
.op-footer-one .bottom-text ul {margin: 0 -40px;}
.op-footer-one .bottom-text ul li {float: left;padding: 0 40px;}
.op-footer-one .bottom-text ul li a {
  font-size: 20px;
  color: #202020;
  transition: all 0.3s ease-in-out;
}
.op-footer-one .bottom-text ul li a:hover {color: #ff4649;}
.op-footer-one .bottom-text  p {font-size: 18px;color: #c9c9c9;}
/*-----------------------------------
            STUDIO PORTFOLIO TWO                 
--------------------------------------*/
.studio-box-container {padding: 50px; background: #efefef; z-index: 5;}
.studio-box-container .main-page-wrapper {background: #fff; position: relative;}
.studio-menu-two {padding: 30px 100px 0;}
.studio-menu-two.fixed {padding: 0 100px;left: 50px;right: 50px;width: auto;}
/*-------------------- Theme Studio Banner two -------------------*/
#studio-banner-two {
  position: relative;
  padding: 200px 100px 200px;
  max-width: 1920px;
  margin: 0 auto; 
}
#studio-banner-two .main-text-wrapper {position: relative; max-width: 600px;z-index: 2;}
#studio-banner-two .main-text-wrapper h1 {
  font-weight: 500;
  font-size: 100px;
  line-height: 100px;
  color: #161616;
}
#studio-banner-two .main-text-wrapper .button-link {font-size: 18px; margin-top: 30px;}
#studio-banner-two .main-text-wrapper .button-link i {vertical-align: -1px;margin-left: 3px;}
#studio-banner-two .illustration {
  position: absolute;
  right: 100px;
  top:50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 50%;
}
/*---------------- Action Banner Three ---------------*/
.action-banner-three {
  padding: 70px 0 35px; 
  margin-top: 50px;
  position: relative;
  z-index: 5;
}
.action-banner-three .main-wrapper {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
.action-banner-three .main-wrapper h3 {
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  padding-bottom: 65px;
}
.action-banner-three .main-wrapper p {
  color: #fff;
   font-size: 25px;
   line-height: 35px;
   padding: 55px 0 20px;
 }
.action-banner-three .main-wrapper img {margin: 0 auto;}
/*-------------------- Our Feature -----------------*/
.studio-feature-area {
  background: url(./assets/images/home/4.png) no-repeat top center;
  background-size: cover;
  padding: 140px 0 180px;
}
.studio-feature-area .theme-title-one {margin-bottom: 40px;}
.studio-feature-area .feature-block {
  background: #fff;
  border: 1px solid #f6f6f6;
  overflow: hidden;
  position: relative;
  z-index: 5;
  padding: 180px 30px 25px 40px;
  margin-top: 45px;
}

.studio-feature-area .feature-block .icon-s {
  position: absolute;
  font-size: 65px;
  left: 40px;
  top: 52px;
}
.studio-feature-area .feature-block .title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
.studio-feature-area .feature-block .detail-button {font-size: 30px;margin-top: 20px;}
/*---------------- Our Project ---------------*/
.studio-project-area {padding: 90px 0 150px; position: relative;}
.studio-project-area:before {
  content: '';
  background-image: -moz-linear-gradient( -90deg, rgba(249,248,246,0.18039) 0%, rgb(255,255,255) 100%);
  background-image: -webkit-linear-gradient( -90deg, rgba(249,248,246,0.18039) 0%, rgb(255,255,255) 100%);
  background-image: -ms-linear-gradient( -90deg, rgba(249,248,246,0.18039) 0%, rgb(255,255,255) 100%);
  position: absolute;
  top:-200px;
  left:0;
  right: 0;
  height: 285px;
  z-index: 49;
}
.studio-project-area .studio-title {margin-bottom: 90px;}
.studio-project-holder {position: relative;overflow: hidden; border-radius: 5px; margin-bottom: 60px;}
.studio-project-holder a {display: block;}
.studio-project-holder img {width: 100%; transition: all 0.5s ease-in-out;}
.studio-project-holder:hover {box-shadow: 0px 13px 35px -12px rgba(35,35,35,0.15);}
.studio-project-holder:hover img {transform: scale3d(1.1,1.1,1);}
.studio-project-holder .text {position: absolute;top:50px;left: 50px;}
.studio-project-holder .text p {
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 10px;
}
.studio-project-holder .text h2 {
  font-size: 42px;
  color: #fff;
  font-weight: 500;
}
.studio-project-area .text-block h3 {
  font-size: 32px;
  line-height: 39px;
  font-weight: normal;
  color: #000000;
}
.studio-project-area .text-block p {padding: 45px 0 50px; color: rgba(0,0,0,0.45);}
.studio-project-area .text-block.scree-lg {padding-top: 60px;}
.studio-project-area .text-block.scree-mobile {display: none;}
/*------------------ Testimonial ---------------*/
.testimonial-studio-vr {
  margin: 100px auto 50px;
  padding: 135px 0 0;
  position: relative;
  z-index: 5;
}
.testimonial-studio-vr:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 590px;
  top:0;
  left: 0;
  background: #f7f9ff;
  z-index: -1;
}
.testimonial-studio-vr:before {
  content: url(./assets/images/icon/icon69.svg);
  position: absolute;
  left:50%;
  top:0;
  transform: translate(-50% , -50%);
  z-index: 1;
}
.studio-client-slider {max-width: 1920px;margin: 0 auto;}
.testimonial-studio-vr .studio-title {padding-bottom: 100px;}
.studio-client-slider .customer-block {
  background: #fff;
  position: relative;
  padding: 50px 50px 60px 50px;
  margin-bottom: 80px;
  box-shadow: 0px 10px 25px 0px rgba(123,147,171,0.15);
}
.studio-client-slider .customer-block:before {
  content: "\f111";
  font-family: "Flaticon";
  position: absolute;
  right: 50px;
  bottom: 60px;
  font-size: 28px;
  transform: rotate(180deg);
}
.studio-client-slider .customer-block .customer-img {width: 70px;height: 70px;border-radius: 50%;}
.studio-client-slider .customer-block p {
  font-size: 18px;
  line-height: 34px;
  color: #2c2c2c;
  padding: 30px 0 35px;
}
.studio-client-slider .customer-block .name {
  font-size: 24px;
  color: #212121;
}
.studio-client-slider .customer-block .customer-info span {color: #b7b7b7;}
.studio-client-slider .center .customer-block p,
.studio-client-slider .center .customer-block .name,
.studio-client-slider .center .customer-block .customer-info span,
.studio-client-slider .center .customer-block:before {color: #fff;}
.owl-theme.studio-client-slider .owl-nav [class*=owl-] {
  background: transparent;
  font-size: 25px;
  color: #d2d2d2;
  padding: 0;
  margin: 0 10px;
}
/*------------------------ Contact Us -------------------*/
.studio-contact-area {position: relative; padding: 100px 0 200px; z-index: 5;}
.studio-contact-area:before {
  content: url(./assets/images/shape/5.png);
  position: absolute;
  top:-100px;
  right: 0;
  z-index: -1;
}
.studio-contact-area .contact-text p {
  font-size: 20px;
  color: #888888;
  position: relative;
  padding: 65px 0 5px;
}
.studio-contact-area .contact-text .call-to-dial {font-size: 26px;}
.studio-contact-area .studio-contact-style {margin-left: 60px;}
.studio-contact-style {
  background: #fff;
  padding: 65px 60px;
  box-shadow: 19.799px 19.799px 100px 0px rgba(96, 96, 96, 0.08);
}
.studio-contact-style input,.studio-contact-style textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid #eaeaea;
}
.studio-contact-style input {height: 50px;margin-bottom: 55px;}
.studio-contact-style textarea {
  height: 120px;
  resize: none;
  margin-bottom: 50px;
}
.studio-contact-style input:focus,.studio-contact-style textarea:focus {border-bottom-color: #6a6a6a;}
/*-------------------- Footer -----------------*/
.studio-footer-one {
  background: #151515;
  position: relative;
  z-index: 5;
  padding: 140px 0 50px;
  overflow: hidden;
}
.studio-footer-one:before {
  content: url(./assets/images/home/5.png);
  position: absolute;
  width: 100%;
  bottom: -20px;
  left: 0;
  z-index: -1;
  opacity: 0.7;
}
.studio-footer-one .top-footer .about-widget {margin-top: 15px;}
.studio-footer-one .top-footer h2 {
  font-size: 48px;
  color: #fff;
  font-weight: normal;
  padding-bottom: 70px;
}
.studio-footer-one .top-footer .title {
  font-size: 24px;
  color: #fff;
  padding-bottom: 25px;
  font-weight: normal;
}
.studio-footer-one .top-footer .footer-list ul li a{
  line-height: 36px;
  display: block;
  color: #6d7175;
  font-size: 16px;
}
.studio-footer-one .top-footer .footer-list ul.soical-icon li {
  display: inline-block;
  margin: 10px 15px 0 0;
}
.studio-footer-one .top-footer .footer-list ul.soical-icon li a {font-size: 18px;}
.studio-footer-one .bottom-footer {padding-top: 100px;}
.studio-footer-one .bottom-footer p {color: rgba(255,255,255,0.5);}
.studio-footer-one .bottom-footer ul li {display: inline-block;}
.studio-footer-one .bottom-footer ul li a {color: rgba(255,255,255,0.5); margin: 0 5px;}
.studio-footer-one .bottom-footer ul li a:hover {color: #fff;}

@media only screen and (max-width: 480px)
{

  #theme-banner-one .main-wrapper {
    padding: 120px 0 30px;
}

.agn-about-us {
  padding: 50px 0 0;
}

.agn-about-us .img-box::before
{
  display: none;
}

.agn-about-us .img-box {
  padding-left: 0px;
}

#theme-banner-one .main-wrapper .main-title {
  font-family: 'CircularStdmed';
  font-size: 35px;
  line-height: 45px;
  padding: 25px 0 35px;
}

  .navbar-toggler {
    position: absolute;
    top: -60px;
    right: 10px;
}

#basic-navbar-nav {
  padding: 12px 5px;
}

.navbar .navbar-collapse .navbar-nav .nav-item .nav-link {

  padding: 0 15px !important;
  font-size: 20px !important;
border-top: 0 !important;
margin: 0px;

}

.navbar .navbar-toggler {
  font-size: 20px !important;
  padding: 5px 10px !important;

}

.static-map {
  width: 100%;
  height: auto;
}


.pt-150 {
  padding-top: 30px;
}

.pb-200 {
  padding-bottom: 0px;
}

}